import { useMemo } from 'react';
import type { ChartMeta } from '../../model/types';
import type { Option } from '../AxisSelect/AxisSelect';

interface Params {
  meta?: ChartMeta;
  defaultSplitDimension: string;
}

interface LineChartToolbarParams {
  splits: Option[];
  metrics: Option[];
}

export function useLineChartToolbarParams({
  meta,
  defaultSplitDimension,
}: Params): LineChartToolbarParams {
  return useMemo(() => {
    if (!meta) {
      return {
        metrics: [],
        splits: [],
      };
    }

    return {
      metrics: meta.metrics.map((metric) => {
        const config = meta.config[metric];

        return {
          name: config.name,
          value: metric,
          group: config.group,
          order: config.order,
        };
      }),
      splits: meta.dimensions.map((dimension) => {
        const config = meta.config[dimension];

        if (dimension === defaultSplitDimension) {
          return {
            name: 'None',
            value: dimension,
            group: '',
            order: -1,
          };
        }

        return {
          name: config.name,
          value: dimension,
          group: config.group,
          order: config.order,
        };
      }),
    };
  }, [meta, defaultSplitDimension]);
}

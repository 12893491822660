import type { ListItemButtonProps } from '@mui/material';
import {
  List as MUIList,
  ListItem,
  ListItemButton as MUIListItemButton,
  ListSubheader,
  Typography,
  Divider,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useMemo } from 'react';
import type { MouseEvent } from 'react';
import { Checkbox } from '@/shared/ui';
import type { Column } from '../../model/types';

const List = styled(MUIList)(() => ({
  overflow: 'auto',
  width: '320px',
  maxHeight: '384px',
  '& ul': {
    padding: 0,
  },
}));
const ListItemButton = styled((props: ListItemButtonProps) => {
  return (
    <MUIListItemButton component="label" {...props}>
      <Checkbox
        sx={{ '&.Mui-disabled': { color: 'var(--neutral-600)' } }}
        disabled
        checked={props.selected}
        tabIndex={-1}
        disableRipple
      />
      {props.children}
    </MUIListItemButton>
  );
})(({ theme, selected }) => ({
  display: 'flex',
  gap: theme.spacing(1.25),
  lineHeight: '22px',
  fontSize: '14px',
  padding: `${theme.spacing(1.25)} ${theme.spacing(2)}`,
  color: 'var(--neutral-700)',

  '&.Mui-selected': {
    backgroundColor: 'transparent',
  },
  ...(selected && {
    fontWeight: 500,
    color: theme.palette.text.primary,
  }),

  '&:hover, &:focus-visible': {
    backgroundColor: 'var(--accent-100)',

    '& .MuiCheckbox-root': {
      color: theme.palette.primary.light,
    },
  },
}));

interface Group {
  name: string;
  items: { name: string; value: string }[];
}

interface Props {
  columns: Column[];
  selectedColumns: string[];
  onSelectedColumnsChange: (v: string[]) => void;
}

export function ColumnsList({
  columns,
  selectedColumns,
  onSelectedColumnsChange,
}: Props) {
  const selectedColumnsSet = useMemo(() => {
    return new Set(selectedColumns);
  }, [selectedColumns]);
  const groups: Group[] = useMemo(() => {
    const groupsMap = new Map<string, Column[]>();

    columns.forEach((column) => {
      const group = groupsMap.get(column.group) || [];

      group.push(column);
      groupsMap.set(column.group, group);
    });
    groupsMap.forEach((group) => {
      group.sort((colA, colB) => {
        return colA.order - colB.order;
      });
    });

    return [...groupsMap.entries()]
      .sort((groupA, groupB) => {
        return groupA[1][0].order - groupB[1][0].order;
      })
      .map(([key, value]) => {
        return {
          name: key,
          items: value,
        };
      });
  }, [columns]);

  if (groups.length === 0) {
    return (
      <Typography
        variant="modalContent"
        sx={{ pt: 1.5, width: 320, textAlign: 'center' }}
      >
        Nothing found
      </Typography>
    );
  }

  const handleOptionToggle = (column: string) => (event: MouseEvent) => {
    event.stopPropagation();
    if (!selectedColumnsSet.has(column)) {
      selectedColumnsSet.add(column);
    } else {
      selectedColumnsSet.delete(column);
    }
    onSelectedColumnsChange([...selectedColumnsSet]);
  };

  return (
    <List sx={{ py: 0 }}>
      {groups.map((group, ind) => {
        const isLastGroup = ind === groups.length - 1;

        return (
          <li key={group.name}>
            <ul>
              <ListSubheader
                sx={{
                  py: 2,
                  pt: 1.25,
                  pb: 0.75,
                  lineHeight: '16px',
                  fontSize: '12px',
                  fontWeight: 400,
                }}
              >
                {group.name}
              </ListSubheader>
              {group.items.map((item) => {
                const selected = selectedColumnsSet.has(item.value);

                return (
                  <ListItem key={item.value} disablePadding>
                    <ListItemButton
                      selected={selected}
                      onClick={handleOptionToggle(item.value)}
                    >
                      {item.name}
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </ul>
            {!isLastGroup && <Divider />}
          </li>
        );
      })}
    </List>
  );
}

import CheckIcon from '@mui/icons-material/Check';
import CopyIcon from '@mui/icons-material/ContentCopy';
import { IconButton as MUIIconButton, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';

const IconButton = styled(MUIIconButton)(({ theme }) => ({
  borderRadius: 4,
  backgroundColor: theme.palette.grey[200],
}));

interface Props {
  disabled?: boolean;
  copyContent: string;
}

export function CopyButton({ disabled, copyContent }: Props) {
  const [successfullyCopied, setSuccessfullyCopied] = useState(false);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    return () => {
      if (timeoutId != null) {
        clearTimeout(timeoutId);
      }
    };
  }, []);

  const handleCopy = () => {
    navigator.clipboard.writeText(copyContent);

    setSuccessfullyCopied(true);
    if (timeoutId != null) {
      clearTimeout(timeoutId);
    }
    setTimeoutId(
      setTimeout(() => {
        setSuccessfullyCopied(false);
      }, 2000),
    );
  };

  return (
    <Tooltip title={getTooltipText(!!disabled, successfullyCopied)}>
      <div>
        <IconButton disabled={disabled} size="small" onClick={handleCopy}>
          {successfullyCopied ? (
            <CheckIcon fontSize="small" color="success" />
          ) : (
            <CopyIcon fontSize="small" />
          )}
        </IconButton>
      </div>
    </Tooltip>
  );
}

const getTooltipText = (
  disabled: boolean,
  successfullyCopied: boolean,
): string => {
  if (disabled) {
    return '';
  }

  return successfullyCopied ? 'Copied!' : 'Copy url to clipboard';
};

import { Chip } from '@mui/material';
import {
  DATASOURCE_STATUS_COLOR_MAP,
  DatasourceStatus,
} from '@/entities/datasources';

const LABELS: Record<DatasourceStatus, string> = {
  [DatasourceStatus.active]: 'Active',
  [DatasourceStatus.error]: 'Error',
  [DatasourceStatus.inactive]: 'Not connected',
};

interface Props {
  status: DatasourceStatus;
}

export function StatusIndicator({ status }: Props) {
  return (
    <Chip
      sx={{
        height: 20,
        color: 'var(--neutral-100)',
        backgroundColor: DATASOURCE_STATUS_COLOR_MAP[status],
      }}
      size="small"
      label={LABELS[status]}
    />
  );
}

import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export function SearchIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.3391 17.7978C14.21 18.4814 12.8845 18.875 11.4667 18.875C7.34294 18.875 4 15.5451 4 11.4375C4 7.32988 7.34294 4 11.4667 4C15.5904 4 18.9333 7.32988 18.9333 11.4375C18.9333 13.3827 18.1837 15.1534 16.9564 16.4788C16.9744 16.4945 16.9921 16.5108 17.0092 16.528L19.6896 19.1978C20.1035 19.6101 20.1035 20.2785 19.6896 20.6908C19.2757 21.1031 18.6047 21.1031 18.1908 20.6908L15.5104 18.0209C15.442 17.9528 15.3849 17.8776 15.3391 17.7978ZM16.8 11.4375C16.8 14.3715 14.4122 16.75 11.4667 16.75C8.52115 16.75 6.13333 14.3715 6.13333 11.4375C6.13333 8.50349 8.52115 6.125 11.4667 6.125C14.4122 6.125 16.8 8.50349 16.8 11.4375Z"
          fill="#817E7E"
        />
      </svg>
    </SvgIcon>
  );
}

import type { FilterConfig, FilterType } from '@/features/filters';
import { StringFilterType } from '@/features/filters';
import type { ColumnDataType } from '@/entities/columnsConfig';
import {
  DimensionColumnDataType,
  MetricColumnDataType,
} from '@/entities/columnsConfig';
import { DataType } from '@/shared/lib';
import type { ColumnsConfig } from '../../model/types';

const ALLOWED_FILTERS: Set<ColumnDataType> = new Set([
  MetricColumnDataType.CURRENCY,
  MetricColumnDataType.NUMBER,
  MetricColumnDataType.PERCENTAGE,
  DimensionColumnDataType.TEXT,
  DimensionColumnDataType.MEDIA,
]);

interface Params {
  columnsConfig: ColumnsConfig;
  allDimensions: string[];
  allMetrics: string[];
}

export const useFilterConfigs = ({
  columnsConfig,
  allDimensions,
  allMetrics,
}: Params): FilterConfig[] => {
  const filters = new Set([...allMetrics, ...allDimensions]);

  return [...filters]
    .filter((filterId) => {
      return ALLOWED_FILTERS.has(columnsConfig[filterId].type);
    })
    .map((filterId) => {
      const { group, name, type, dataType, order } = columnsConfig[filterId];

      return {
        id: filterId,
        name,
        group,
        type: transformColumnDataType(type),
        dataType,
        order,
      };
    });
};

const transformColumnDataType = (type: ColumnDataType): FilterType => {
  switch (type) {
    case MetricColumnDataType.CURRENCY:
      return DataType.CURRENCY;
    case MetricColumnDataType.NUMBER:
      return DataType.NUMBER;
    case MetricColumnDataType.PERCENTAGE:
      return DataType.PERCENTAGE;
    case DimensionColumnDataType.TEXT:
      return StringFilterType.TEXT;
    case DimensionColumnDataType.MEDIA:
      return StringFilterType.MEDIA;
    default:
      throw new Error(`Unknown filter type: ${type}`);
  }
};

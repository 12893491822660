import { useCallback, useState } from 'react';

interface Params<T> {
  options: T[];
  changeHandler: (addOrRemove: boolean, items: string[]) => void;
  getOptionValue: (v: T) => string;
}
// copied from
// https://github.com/stereobooster/useful-react-snippets/blob/main/useShiftSelected/useShiftSelected.ts
export const useShiftSelected = <T>({
  options,
  changeHandler,
  getOptionValue,
}: Params<T>) => {
  const [previousSelected, setPreviousSelected] = useState<string | null>(null);
  const [previousChecked, setPreviousChecked] = useState<boolean>(false);

  const onChange = useCallback(
    (item: string, checked: boolean, shiftPressed: boolean) => {
      if (shiftPressed) {
        const current = options.findIndex((x) => getOptionValue(x) === item);
        const previous = options.findIndex(
          (x) => getOptionValue(x) === previousSelected,
        );
        const start = Math.min(current, previous);
        const end = Math.max(current, previous);

        if (start > -1 && end > -1) {
          changeHandler(
            previousChecked,
            options.slice(start, end + 1).map(getOptionValue),
          );

          return;
        }
      } else {
        setPreviousSelected(item);
        setPreviousChecked(checked);
      }

      changeHandler(checked, [item]);
    },
    [
      changeHandler,
      options,
      getOptionValue,
      previousSelected,
      setPreviousSelected,
      previousChecked,
      setPreviousChecked,
    ],
  );

  return onChange;
};

import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { Autocomplete, TextField } from '@mui/material';
import type { SyntheticEvent } from 'react';
import { useEffect, useMemo, useState } from 'react';
import { Label } from '@/shared/ui';

export interface Option {
  name: string;
  value: string;
  group: string;
  order: number;
}

interface Props {
  label: string;
  options: Option[];
  disabledOptions?: string[];
  value: string;
  onChange: (v: string) => void;
}

export function AxisSelect({
  label,
  options,
  disabledOptions,
  value,
  onChange,
}: Props) {
  const [optionValue, setOptionValue] = useState(() => {
    return options.find((option) => {
      return option.value === value;
    });
  });
  const sortedOptions = useMemo(() => {
    const groupsMap = new Map<string, Option[]>();

    options.forEach((option) => {
      const group = groupsMap.get(option.group) || [];

      group.push(option);
      groupsMap.set(option.group, group);
    });
    groupsMap.forEach((group) => {
      group.sort((colA, colB) => {
        return colA.order - colB.order;
      });
    });

    return [...groupsMap.entries()]
      .sort((groupA, groupB) => {
        return groupA[1][0].order - groupB[1][0].order;
      })
      .map(([_, value]) => {
        return value;
      })
      .flat();
  }, [options]);
  const disabledOptionsSet = useMemo(() => {
    return new Set(disabledOptions || []);
  }, [disabledOptions]);

  useEffect(() => {
    setOptionValue(
      options.find((option) => {
        return option.value === value;
      }),
    );
  }, [value, options]);

  const handleChange = (_: SyntheticEvent, newOption: Option) => {
    setOptionValue(newOption);
    onChange(newOption.value);
  };

  return (
    <Label text={label}>
      <Autocomplete
        sx={{
          width: 300,
        }}
        getOptionDisabled={(option) => {
          return disabledOptionsSet.has(option.value);
        }}
        popupIcon={<KeyboardArrowDownRoundedIcon />}
        disableClearable
        options={sortedOptions}
        value={optionValue}
        groupBy={(option) => option.group}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => <TextField {...params} size="small" />}
        isOptionEqualToValue={(option, value) => {
          return option.value === value.value;
        }}
        onChange={handleChange}
      />
    </Label>
  );
}

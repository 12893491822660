import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export function TrashIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12.7763 7.96093L11.4459 7.87224L11.0293 14.1222L12.3596 14.2109L12.7763 7.96093Z"
          fill="currentColor"
        />
        <path
          d="M9.44298 7.87224L9.85965 14.1222L8.52927 14.2109L8.1126 7.96093L9.44298 7.87224Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.6945 3.33325H9.19446V4.99992H4.19446V6.49992H5.13493L5.7395 14.9639C5.80803 15.9233 6.60634 16.6666 7.56818 16.6666H13.3207C14.2826 16.6666 15.0809 15.9233 15.1494 14.9639L15.754 6.49992H16.6945V4.99992H11.6945V3.33325ZM6.63862 6.49992H14.2495L13.6532 14.857L13.6425 14.9204C13.604 15.0631 13.4738 15.1666 13.3207 15.1666H7.56818L7.50419 15.1604C7.35908 15.1322 7.24659 15.0096 7.23569 14.857L6.63862 6.49992Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
}

import isEqual from 'lodash.isequal';
import { useSnackbar } from 'notistack';
import type { ReactNode } from 'react';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import type { SavedViewStorage } from '../../api/types';
import {
  createView,
  deleteView,
  selectViews,
  setupViews,
} from '../../model/slice';
import type { SavedViewRecord } from '../../model/types';

interface ContextState {
  appliedViewUuid: string;
  appliedView: SavedViewRecord | undefined;
  isAppliedViewModified: boolean;
  views: SavedViewRecord[];
  applyView: (uuid: string) => void;
  createView: (name: string) => void;
  deleteView: (uuid: string) => void;
}

const Context = createContext<ContextState | null>(null);
const NO_VIEW_APPLIED = '';

interface Props<T> {
  children: ReactNode;
  storage: SavedViewStorage;
  snapshotValidator: (
    v: unknown,
  ) => { success: true; data: T } | { success: false; error: unknown };
  currentViewState: unknown;
  onApply: (snapshot: unknown) => void;
}

export function SavedViewsProvider<T>({
  children,
  currentViewState,
  snapshotValidator,
  storage,
  onApply,
}: Props<T>) {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const allViews = useAppSelector(selectViews);
  const views = allViews
    .filter((view) => {
      return view.saved_view.storage === storage;
    })
    .map((view) => {
      const parseResult = snapshotValidator(view.saved_view.snapshot);
      const snapshot = parseResult.success ? parseResult.data : null;

      return {
        ...view,
        saved_view: {
          ...view.saved_view,
          snapshot,
        },
      };
    })
    .filter((view) => {
      return !!view.saved_view.snapshot;
    });
  const [appliedViewUuid, setAppliedViewUuid] = useState(NO_VIEW_APPLIED);
  const viewsMap = useMemo(() => {
    return Object.fromEntries(
      views.map((view) => {
        return [view.uuid, view];
      }),
    );
  }, [views]);

  useEffect(() => {
    dispatch(setupViews());
  }, [dispatch]);

  return (
    <Context.Provider
      value={{
        views,
        appliedViewUuid,
        appliedView: viewsMap[appliedViewUuid],
        get isAppliedViewModified() {
          if (appliedViewUuid === NO_VIEW_APPLIED) {
            return false;
          }

          const view = viewsMap[appliedViewUuid];

          if (!view) {
            return false;
          }

          return !isEqual(view.saved_view.snapshot, currentViewState);
        },
        applyView: (uuid) => {
          setAppliedViewUuid(uuid);
          onApply(viewsMap[uuid].saved_view.snapshot);
        },
        createView: (name) => {
          dispatch(
            createView({
              storage,
              name,
              snapshot: currentViewState,
            }),
          )
            .unwrap()
            .then((newView) => {
              setAppliedViewUuid(newView.uuid);
            });
        },
        deleteView: (uuid) => {
          dispatch(deleteView(uuid))
            .unwrap()
            .then(() => {
              if (uuid === appliedViewUuid) {
                setAppliedViewUuid(NO_VIEW_APPLIED);
              }
            })
            .catch(() => {
              enqueueSnackbar('Unable to delete view. Please, try again.', {
                variant: 'error',
                autoHideDuration: 4000,
              });
            });
        },
      }}
    >
      {children}
    </Context.Provider>
  );
}

export const useSavedViewsContext = () => {
  const context = useContext(Context);

  if (!context) {
    throw new Error(
      'useSavedViewsContext hook used outside <SavedViewsProvider />',
    );
  }

  return context;
};

import type { Action } from '@reduxjs/toolkit';
import { combineSlices, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { calendarInsightsSlice } from '@/widgets/calendarInsights';
import { chartsViewSlice } from '@/widgets/chartsView';
import { cohortMarketingPerformanceSlice } from '@/widgets/cohortMarketingPerformance/model/slice';
import { sharedViewsAPI, sharedViewsMiddleware } from '@/features/ShareView';
import { savedViewsSlice } from '@/entities/savedViews';
import { logout, sessionSlice } from '@/entities/session';
// `combineSlices` automatically combines the reducers using
// their `reducerPath`s, therefore we no longer need to call `combineReducers`.
const appReducer = combineSlices(
  cohortMarketingPerformanceSlice,
  calendarInsightsSlice,
  chartsViewSlice,
  sessionSlice,
  savedViewsSlice,
  sharedViewsAPI,
);
// Infer the `RootState` type from the root reducer
export type RootState = ReturnType<typeof appReducer>;

const rootReducer = (
  state: Parameters<typeof appReducer>[0],
  action: Action,
): RootState => {
  if (action.type === logout.fulfilled.type) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

// The store setup is wrapped in `makeStore` to allow reuse
// when setting up tests that need the same store config
export const makeStore = (preloadedState?: Partial<RootState>) => {
  const store = configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(sharedViewsMiddleware),
  });
  // configure listeners using the provided defaults
  // optional, but required for `refetchOnFocus`/`refetchOnReconnect` behaviors
  setupListeners(store.dispatch);
  return store;
};

export const store = makeStore();

// Infer the type of `store`
export type AppStore = typeof store;
// Infer the `AppDispatch` type from the store itself
export type AppDispatch = AppStore['dispatch'];

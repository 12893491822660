import { Tooltip } from '@mui/material';
import { useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import { LabeledSwitcher } from '@/shared/ui';
import { NO_AXIS_SELECTED_VALUE } from '../../model/constants';
import {
  selectChart,
  selectChartSyncedYAxes,
  selectMetaRegistry,
  updateChartSyncedYAxes,
} from '../../model/slice';
import { getChartMeta } from '../../model/utils';

export function SyncedYAxesSwitcher() {
  const dispatch = useAppDispatch();
  const syncedYAxes = useAppSelector(selectChartSyncedYAxes);
  const chart = useAppSelector(selectChart);
  const metaRegistry = useAppSelector(selectMetaRegistry);
  const isMetricsSameType = useMemo(() => {
    if (!metaRegistry || !chart) {
      return false;
    }

    const chartMeta = getChartMeta(metaRegistry, chart.type);

    return (
      chart.secondaryMetric !== NO_AXIS_SELECTED_VALUE &&
      chartMeta.config[chart.primaryMetric].type ===
        chartMeta.config[chart.secondaryMetric].type
    );
  }, [metaRegistry, chart]);

  const handleChange = (newValue: boolean) => {
    dispatch(updateChartSyncedYAxes(newValue));
  };

  return (
    <Tooltip
      title={
        !isMetricsSameType ? 'Only available for metrics with same units' : ''
      }
    >
      <div>
        <LabeledSwitcher
          disabled={!isMetricsSameType}
          label="Sync Y Axes"
          value={!isMetricsSameType ? false : syncedYAxes}
          onChange={handleChange}
        />
      </div>
    </Tooltip>
  );
}

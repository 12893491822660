import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const Image = styled('img')(() => ({
  width: 58,
  height: 58,
}));

export function NoViews() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 0.5,
      }}
    >
      <Image alt="No views icon." src="images/empty-state.png" />
      <Typography variant="body2">
        <b>No views saved</b>
      </Typography>
      <Typography align="center" variant="body2">
        Save a custom view for quick access to your preferred filters.
      </Typography>
    </Box>
  );
}

import type { AlertProps } from '@mui/material';
import { Alert as MUIAlert } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledAlert = styled(MUIAlert)(({ theme }) => ({
  marginTop: theme.spacing(2.75),
  marginBottom: theme.spacing(3),
  backgroundColor: '#FEEFEF',
  color: theme.palette.error.main,

  '& .MuiAlert-message': {
    color: '#666666',
  },
}));

export function ErrorAlert({ children, ...props }: AlertProps) {
  if (!children) {
    return null;
  }

  return (
    <StyledAlert variant="outlined" severity="error" {...props}>
      {children}
    </StyledAlert>
  );
}

import styles from './Spinner.module.css';

export function Spinner() {
  return (
    <svg
      className={styles.root}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_21_24967)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.7739 0C23.1234 0 21.7174 0.5595 20.5623 1.6785C19.4057 2.799 18.8281 4.1595 18.8281 5.76C18.8281 7.359 19.4057 8.718 20.5623 9.84C21.7174 10.959 23.1234 11.52 24.7739 11.52C26.4261 11.52 27.8304 10.9605 28.9855 9.84C30.1422 8.7195 30.7197 7.359 30.7197 5.76C30.7197 4.1595 30.1422 2.799 28.9855 1.6785C27.8304 0.5595 26.4261 0 24.7739 0Z"
          fill="#363636"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.7725 40.3184C23.6793 40.3184 22.7441 40.6949 21.9683 41.4449C21.1957 42.1949 20.8086 43.0979 20.8086 44.1584C20.8086 45.2189 21.1941 46.1234 21.9683 46.8734C22.7441 47.6234 23.6778 47.9999 24.7725 47.9999C25.8672 47.9999 26.8024 47.6234 27.5766 46.8734C28.3508 46.1234 28.7379 45.2189 28.7379 44.1584C28.7379 43.0979 28.3508 42.1949 27.5766 41.4449C26.8024 40.6949 25.8672 40.3184 24.7725 40.3184Z"
          fill="#DFDFDF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M38.6461 35.0381C37.697 35.0381 36.8794 35.3681 36.1997 36.0296C35.5184 36.6881 35.1777 37.4786 35.1777 38.3996C35.1777 39.3191 35.5184 40.1081 36.1997 40.7696C36.8794 41.4296 37.697 41.7596 38.6461 41.7596C39.5953 41.7596 40.4113 41.4281 41.0926 40.7696C41.7739 40.1096 42.1145 39.3191 42.1145 38.3996C42.1145 37.4786 41.7739 36.6896 41.0926 36.0296C40.4113 35.3681 39.5953 35.0381 38.6461 35.0381Z"
          fill="#DFDFDF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M46.6983 22.9186C46.1207 22.3591 45.4177 22.0801 44.5924 22.0801C43.7672 22.0801 43.0642 22.3591 42.4866 22.9186C41.9091 23.4781 41.6211 24.1591 41.6211 24.9601C41.6211 25.7596 41.9091 26.4406 42.4866 26.9986C43.0642 27.5596 43.7672 27.8401 44.5924 27.8401C45.4177 27.8401 46.1207 27.5596 46.6983 26.9986C47.2758 26.4391 47.5654 25.7596 47.5654 24.9601C47.5654 24.1591 47.2758 23.4796 46.6983 22.9186Z"
          fill="#EFEFEF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.8995 6.24023C9.39141 6.24023 8.10625 6.75473 7.04406 7.78373C5.97877 8.81423 5.44922 10.0592 5.44922 11.5202C5.44922 12.9797 5.97877 14.2232 7.04406 15.2552C8.10625 16.2842 9.39141 16.8002 10.8995 16.8002C12.4061 16.8002 13.6913 16.2842 14.7535 15.2552C15.8172 14.2232 16.3499 12.9797 16.3499 11.5202C16.3499 10.0592 15.8172 8.81423 14.7535 7.78373C13.6913 6.75473 12.4061 6.24023 10.8995 6.24023Z"
          fill="#363636"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M38.6454 13.9197C39.3251 13.9197 39.9104 13.6842 40.3951 13.2147C40.8797 12.7437 41.1244 12.1782 41.1244 11.5197C41.1244 10.8582 40.8797 10.2942 40.3951 9.82466C39.9089 9.35366 39.3251 9.11816 38.6454 9.11816C37.9641 9.11816 37.3819 9.35366 36.8957 9.82466C36.4111 10.2942 36.168 10.8582 36.168 11.5197C36.168 12.1797 36.4111 12.7437 36.8957 13.2147C37.3819 13.6842 37.9641 13.9197 38.6454 13.9197Z"
          fill="#EFEFEF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.90813 24.9597C9.90813 23.6397 9.42348 22.5102 8.45419 21.5682C7.48335 20.6292 6.31587 20.1582 4.95329 20.1582C3.59071 20.1582 2.42477 20.6292 1.45394 21.5682C0.483097 22.5087 0 23.6382 0 24.9597C0 26.2797 0.483097 27.4077 1.45394 28.3482C2.42477 29.2887 3.59071 29.7582 4.95329 29.7582C6.31587 29.7582 7.48335 29.2887 8.45419 28.3482C9.42348 27.4077 9.90813 26.2797 9.90813 24.9597Z"
          fill="#A1A1A1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.042 35.3381C14.92 36.1796 15.3582 37.1996 15.3582 38.3996C15.3582 39.5981 14.92 40.6196 14.042 41.4596C13.1656 42.2996 12.1174 42.7196 10.8988 42.7196C9.6601 42.7196 8.60874 42.2996 7.7401 41.4596C6.873 40.6196 6.43945 39.5981 6.43945 38.3996C6.43945 37.1996 6.873 36.1781 7.7401 35.3381C8.60874 34.4981 9.6601 34.0781 10.8988 34.0781C12.1174 34.0781 13.1656 34.4996 14.042 35.3381Z"
          fill="#A1A1A1"
        />
      </g>
      <defs>
        <clipPath id="clip0_21_24967">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

import type { CardProps } from '@mui/material';
import { Badge, Card, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import '@fontsource/roboto/500.css';

const Wrapper = styled((props: CardProps) => {
  return <Card component="li" elevation={3} {...props} />;
})(({ theme }) => ({
  backgroundColor: 'white',
  borderRadius: 12,
  width: 300,
  height: 300,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  overflow: 'visible',
}));
const Title = styled('h6')(() => ({
  fontSize: '1.25rem',
  fontWeight: 500,
  lineHeight: 1.4,
  margin: 0,
}));
const Icon = styled('img')(({ theme }) => ({
  height: 100,
  width: 150,
  padding: '13px 19px',
  backgroundColor: '#3B3B3C',
  borderRadius: theme.shape.borderRadius,
  objectFit: 'contain',
  objectPosition: 'middle top',
}));
const Description = styled('p')(() => ({
  margin: 0,
  fontSize: '1rem',
  lineHeight: 1.5,
  color: '#42526B',
  overflow: 'hidden',
  display: '-webkit-box',
  WebkitLineClamp: 4,
  WebkitBoxOrient: 'vertical',
}));
const Link = styled(RouterLink)(({ theme }) => ({
  textDecoration: 'none',
  color: theme.palette.text.primary,
}));

const containerSx = {
  px: 4,
  py: 3,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: 1,
};

export interface Product {
  name: string;
  description: string;
  image: string;
  url?: string;
}

interface Props {
  product: Product;
}

export function ProductCard({ product }: Props) {
  const descriptionClampHack =
    product.name.length > 23 ? { WebkitLineClamp: 3 } : {};
  const content = (
    <>
      <Icon src={product.image} />
      <Title>{product.name}</Title>
      <Tooltip arrow title={product.description}>
        <Description sx={descriptionClampHack}>
          {product.description}
        </Description>
      </Tooltip>
    </>
  );

  return (
    <Wrapper>
      {product.url ? (
        <Link sx={containerSx} to={product.url}>
          {content}
        </Link>
      ) : (
        <Badge
          sx={{
            ...containerSx,
            opacity: 0.7,
          }}
          badgeContent="Soon"
          color="secondary"
        >
          {content}
        </Badge>
      )}
    </Wrapper>
  );
}

import { Box, Divider, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { ReactNode } from 'react';
import { useState } from 'react';
import { Button as SharedButton } from '@/shared/ui';
import type {
  MediaFilterOption,
  MediaFilterParams,
  MediaFilterValue,
} from '../../model/types';
import {
  FilterSelectionControlsProvider,
  useFilterSelectionControlsContext,
} from '../FilterSelectionControlsContext/FilterSelectionControlsContext';
import { useFiltersParamsContext } from '../FiltersParamsContext/FiltersParamsContext';
import { MediaFilter } from '../MediaFilter/MediaFilter';

const Button = styled(SharedButton)(({ theme }) => ({
  padding: `${theme.spacing(0.5)} ${theme.spacing(1.25)}`,
}));

const getSelectedCountLabel = (
  optionsCount: number,
  valueCount: number,
): ReactNode => {
  if (valueCount === 0) {
    return 'Selected none';
  }

  if (valueCount === optionsCount) {
    return 'Selected All';
  }

  return (
    <>
      Selected <b>{valueCount}</b> of {optionsCount}
    </>
  );
};

interface ContentProps {
  id: string;
  value: MediaFilterValue;
  onChange: (v: MediaFilterValue) => void;
  onApply: () => void;
}

function Content({ id, value, onApply, onChange }: ContentProps) {
  const {
    getSelectAllValue,
    getClearAllValue,
    isClearAllDisabled,
    isSelectAllDisabled,
  } = useFilterSelectionControlsContext();
  const { configsMap, paramsData } = useFiltersParamsContext();

  const options =
    (paramsData[id]?.params as MediaFilterParams)?.options?.map(({ value }) => {
      return value;
    }) || [];
  const { name } = configsMap[id] || {};

  const handleClearAll = () => {
    onChange(getClearAllValue());
  };
  const handleSelectAll = () => {
    onChange(getSelectAllValue());
  };

  return (
    <>
      <Box
        sx={{
          pt: 3,
          px: 3,
          pb: 1.25,
          display: 'flex',
          flexDirection: 'column',
          width: 384,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            mb: 2,
            gap: 2,
            color: 'var(--neutral-700)',
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              lineHeight: '22px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {name}
          </Typography>
          <Typography
            sx={{ flexShrink: 0, whiteSpace: 'nowrap', fontWeight: 400 }}
            variant="label"
          >
            {getSelectedCountLabel(options.length, value.length)}
          </Typography>
        </Box>
        <MediaFilter id={id} value={value} onChange={onChange} />
      </Box>
      <Divider />
      <Box
        sx={{
          py: 2,
          px: 3,
          display: 'flex',
          justifyContent: 'flex-end',
          gap: 2,
        }}
      >
        <Button
          size="small"
          color="secondary"
          variant="text"
          onClick={handleClearAll}
          disabled={isClearAllDisabled(value)}
        >
          Clear All
        </Button>
        <Button
          size="small"
          color="secondary"
          variant="outlined"
          onClick={handleSelectAll}
          disabled={isSelectAllDisabled(value)}
        >
          Select all
        </Button>
        <Button size="small" variant="contained" onClick={onApply}>
          Apply
        </Button>
      </Box>
    </>
  );
}

interface Props {
  id: string;
  value: MediaFilterValue;
  onChange: (v: MediaFilterValue) => void;
}

export function QuickMediaFilter({ id, value, onChange }: Props) {
  const [internalValue, setInternalValue] = useState<MediaFilterValue>(
    value || [],
  );

  const handleChange = (newValue: MediaFilterValue) => {
    setInternalValue(newValue);
  };
  const handleApply = () => {
    onChange(internalValue);
  };

  return (
    <FilterSelectionControlsProvider
      id={id}
      value={internalValue}
      getOptionValue={(option) => (option as MediaFilterOption).value}
    >
      <Content
        id={id}
        value={internalValue}
        onApply={handleApply}
        onChange={handleChange}
      />
    </FilterSelectionControlsProvider>
  );
}

import type { SVGProps } from 'react';

export function LogoIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 420 420"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g>
        <ellipse
          ry="206"
          rx="206"
          cy="210"
          cx="210"
          strokeWidth="0"
          stroke="null"
          fill="#ffffff"
        />
        <path
          fill="#063FFE"
          d="m419.602,221c-5.721,110.867 -97.426,199 -209.716,199c-112.2906,0 -203.99612,-88.133 -209.71732,-199l24.71682,0c75.1395,0 101.1545,28.855 119.4425,99.274c0.53,2.043 3.525,2.027 4.034,-0.02c17.304,-69.625 41.703,-99.254 124.024,-99.254l147.216,0zm0.169,-18l-147.385,0c-75.335,0 -109.308,-31.286 -124.22,-99.239c-0.457,-2.085 -3.54,-2.186 -4.118,-0.131c-19.886,70.666 -47.5116,99.37 -119.1625,99.37l-17.99997,0l-6.88553,0c3.69241,-112.7394 96.2482,-203 209.886,-203c113.637,0 206.193,90.2606 209.885,203z"
          clipRule="evenodd"
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
}

import { Box, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useMemo } from 'react';
import { Switch } from '@/shared/ui';
import { LINE_COLORS } from '../LineChart/constants';
import type { Props } from './types';

const List = styled('ul')(({ theme }) => ({
  margin: 0,
  padding: 0,
  listStyleType: 'none',
  overflow: 'auto',
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(0.5),
}));
const Item = styled('li')(({ theme }) => ({
  padding: theme.spacing(1.5),
  backgroundColor: 'white',
  border: '1px solid #d7d7d7',
  borderRadius: theme.shape.borderRadius,
  lineHeight: 1.5,
  fontSize: '0.75rem',
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
}));

export function SplitLegendList({
  shrinked,
  filterQuery,
  options,
  onOptionToggle,
  inactiveOptionsSet,
}: Props) {
  const preparedOptions = useMemo(() => {
    const preparedQuery = filterQuery.toLowerCase();

    return options.filter((option) => {
      return option.toLowerCase().includes(preparedQuery);
    });
  }, [options, filterQuery]);

  if (preparedOptions.length === 0) {
    return (
      <Typography
        color="text.secondary"
        variant="body2"
        sx={{ my: 2, textAlign: 'center' }}
      >
        Nothing found.
      </Typography>
    );
  }

  const handleOptionToggle = (option: string) => () => {
    onOptionToggle(option);
  };

  return (
    <List sx={{ gap: shrinked ? 2 : 0.5 }}>
      {preparedOptions.map((option, index) => {
        if (shrinked) {
          return (
            <Tooltip key={option} placement="right" title={option}>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Switch
                  customColor={LINE_COLORS[index % LINE_COLORS.length]}
                  checked={!inactiveOptionsSet.has(option)}
                  onChange={handleOptionToggle(option)}
                />
              </Box>
            </Tooltip>
          );
        }

        return (
          <Item key={option}>
            <Switch
              customColor={LINE_COLORS[index % LINE_COLORS.length]}
              checked={!inactiveOptionsSet.has(option)}
              onChange={handleOptionToggle(option)}
            />
            <Tooltip title={option}>
              <Box
                component="span"
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                {option}
              </Box>
            </Tooltip>
          </Item>
        );
      })}
    </List>
  );
}

import type { ColumnsConfig } from '@/entities/columnsConfig';
import type { Column } from '../../model/types';

interface Params {
  columnsConfig: ColumnsConfig;
  allMetrics: string[];
  allDimensions: string[];
}

export function useColumnsInfo({
  columnsConfig,
  allDimensions,
  allMetrics,
}: Params): {
  dimensionsColumns: Column[];
  metricsColumns: Column[];
} {
  return {
    dimensionsColumns: allDimensions.map((dimension) => {
      const { name, group, order } = columnsConfig[dimension];

      return {
        name,
        value: dimension,
        order,
        group,
      };
    }),
    metricsColumns: allMetrics.map((metric) => {
      const { name, group, order } = columnsConfig[metric];

      return {
        name,
        value: metric,
        order,
        group,
      };
    }),
  };
}

import DensityLowIcon from '@mui/icons-material/DensityMedium';
import DensityMediumIcon from '@mui/icons-material/DensitySmall';
import DensityHighIcon from '@mui/icons-material/FormatAlignJustify';
import {
  ToggleButton as MUIToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import type { MouseEvent } from 'react';
import { RowHeights } from '@/shared/constants';

const ToggleButton = styled(MUIToggleButton)(({ theme }) => ({
  border: 'none',
  padding: 0,
  height: 24,
  borderRadius: theme.shape.borderRadius,
  color: 'var(--neutral-600)',

  '&.Mui-selected': {
    backgroundColor: 'var(--neutral-300)',
  },

  '&.MuiToggleButtonGroup-firstButton, &.MuiToggleButtonGroup-lastButton': {
    borderRadius: theme.shape.borderRadius,
  },
}));

interface Props {
  value: number;
  onChange: (v: number) => void;
}

export function DensitySwitcher({
  value = RowHeights.normal,
  onChange,
}: Props) {
  const handleChange = (_event: MouseEvent, value: number) => {
    onChange(value);
  };

  return (
    <ToggleButtonGroup
      sx={{ gap: 2, alignItems: 'center' }}
      exclusive
      value={value}
      onChange={handleChange}
    >
      <ToggleButton value={RowHeights.sparse}>
        <DensityLowIcon />
      </ToggleButton>
      <ToggleButton value={RowHeights.normal}>
        <DensityMediumIcon />
      </ToggleButton>
      <ToggleButton value={RowHeights.dense}>
        <DensityHighIcon />
      </ToggleButton>
    </ToggleButtonGroup>
  );
}

import type { TypographyProps } from '@mui/material';
import {
  List,
  ListItem as MUIListItem,
  ListItemButton as MUIListItemButton,
  ListItemIcon as MUIListItemIcon,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material';
import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import { Dropdown } from '@/shared/ui';
import { selectChart, updateChartType } from '../../model/slice';
import { ChartType } from '../../model/types';
import { CalendarIcon } from './CalendarIcon';
import { CohortIcon } from './CohortIcon';

const ListItem = styled(MUIListItem)(() => ({
  padding: 0,
}));
const ListItemButton = styled(MUIListItemButton)(({ theme }) => ({
  gap: theme.spacing(1),
}));
const ListItemIcon = styled(MUIListItemIcon)(() => ({
  minWidth: 0,
}));
const ListItemText = styled((props: TypographyProps) => {
  return <Typography component="span" {...props} />;
})(() => ({
  fontSize: '0.875rem',
}));

export function ChartTypeSelect() {
  const dispatch = useAppDispatch();
  const chart = useAppSelector(selectChart)!;
  const [open, setOpen] = useState(false);

  const handleTypeChange = (newType: ChartType) => () => {
    if (newType === chart.type) {
      return;
    }

    dispatch(updateChartType(newType));
    setOpen(false);
  };

  return (
    <Dropdown
      open={open}
      label={getLabel(chart.type)}
      labelProps={{
        sx: {
          fontSize: '24px',
          fontWeight: 500,
          color: 'white',
        },
        variant: 'text',
      }}
      paperProps={{ sx: { mt: 0 } }}
      popperProps={{
        disablePortal: true,
        placement: 'bottom-start',
      }}
      onOpenChange={setOpen}
    >
      <List>
        <ListItem>
          <ListItemButton
            selected={chart.type === ChartType.cohort}
            onClick={handleTypeChange(ChartType.cohort)}
          >
            <ListItemIcon>{getIcon(ChartType.cohort)}</ListItemIcon>
            <ListItemText>{getLabel(ChartType.cohort)}</ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton
            selected={chart.type === ChartType.calendar}
            onClick={handleTypeChange(ChartType.calendar)}
          >
            <ListItemIcon>{getIcon(ChartType.calendar)}</ListItemIcon>
            <ListItemText>{getLabel(ChartType.calendar)}</ListItemText>
          </ListItemButton>
        </ListItem>
      </List>
    </Dropdown>
  );
}

const getLabel = (type: ChartType) => {
  if (type === ChartType.calendar) {
    return 'Calendar chart';
  } else {
    return 'Cohort chart';
  }
};

const getIcon = (type: ChartType) => {
  if (type === ChartType.calendar) {
    return <CalendarIcon />;
  } else {
    return <CohortIcon />;
  }
};

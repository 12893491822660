import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { IconButton, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { ChangeEvent } from 'react';
import { useState } from 'react';
import { RequiredAsterisk } from '@/shared/ui';

const Wrapper = styled('label')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(0.5),
}));
const LabelWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(0.25),
}));
const Input = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-input': {
    padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
    fontSize: '14px',
  },
  '& .MuiFormHelperText-root': {
    marginLeft: 0,
  },
}));
const VisibilityButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

interface Props {
  label: string;
  value: string;
  error: string;
  onChange: (v: string) => void;
  onBlur: () => void;
}

export function PasswordInput({
  label,
  error,
  value,
  onBlur,
  onChange,
}: Props) {
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword((oldValue) => !oldValue);
  };
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <Wrapper>
      <LabelWrapper>
        <RequiredAsterisk />
        <Typography variant="label">{label}</Typography>
      </LabelWrapper>
      <Input
        error={!!error}
        helperText={error}
        value={value}
        type={showPassword ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <VisibilityButton size="small" onClick={toggleShowPassword}>
              {showPassword ? (
                <VisibilityOutlinedIcon />
              ) : (
                <VisibilityOffOutlinedIcon />
              )}
            </VisibilityButton>
          ),
        }}
        onBlur={onBlur}
        onChange={handleChange}
      />
    </Wrapper>
  );
}

import { Box, Typography } from '@mui/material';
import type { Datasource } from '../../model/types';
import { DATASOURCE_TYPE_LABEL_MAP } from '../datasourceTypeLabelMap';
import { StatusIndicator } from '../StatusIndicator/StatusIndicator';

interface Props {
  datasource: Datasource;
}

export function DatasourceCard({ datasource }: Props) {
  return (
    <Box
      sx={{
        borderRadius: 3,
        padding: 2,
        height: 200,
        backgroundColor: 'var(--neutral-100)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box
          component="img"
          alt={`${datasource.name} logo`}
          src={datasource.iconUrl}
          sx={{
            width: 100,
            height: 100,
            borderRadius: 2,
          }}
        />
        <StatusIndicator status={datasource.status} />
      </Box>
      <Box>
        <Typography
          sx={{
            fontSize: '1.25rem',
            fontWeight: 500,
            color: 'text.primary',
          }}
        >
          {datasource.name}
        </Typography>
        <Typography
          sx={{
            color: 'var(--neutral-600)',
            fontSize: '0.875rem',
            lineHeight: 1.15,
          }}
        >
          {DATASOURCE_TYPE_LABEL_MAP[datasource.type]}
        </Typography>
      </Box>
    </Box>
  );
}

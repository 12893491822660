import { useState } from 'react';

export const useRefresher = () => {
  const [refresher, setRefresher] = useState(0);

  return {
    refresher,
    onRefresh: () => {
      setRefresher(refresher + 1);
    },
  };
};

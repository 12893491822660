import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/ErrorRounded';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton as MUIIconButton,
  SvgIcon,
  Tooltip,
  Typography,
  OutlinedInput,
  InputAdornment,
  Box,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { Analytics } from '@/shared/lib';
import { Loader } from '@/shared/ui';
import { useCreateSharedViewMutation } from '../../api/shareAPI';
import type { SharedView } from '../../model/types';
import { CopyButton } from '../CopyButton/CopyButton';
import ShareIcon from './share.svg?react';

const IconButton = styled(MUIIconButton)(({ theme }) => ({
  color: 'currentColor',
  border: '1px solid currentColor',
  borderRadius: theme.shape.borderRadius,

  '&:hover': {
    backgroundColor: theme.palette.primary.main,
  },
}));

interface Props {
  linkPrefix: string;
  view: SharedView;
}

export function ShareView({ linkPrefix, view }: Props) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [createSharedView, { data: shareId, isLoading, isError }] =
    useCreateSharedViewMutation();

  const handleOpenDialog = () => {
    Analytics.sendFeatureUsage('share_view', 'create', { page: linkPrefix });
    setDialogOpen(true);
    createSharedView(view);
  };
  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const shareLink = `${window.location.origin}${linkPrefix}/share/${shareId}`;

  return (
    <>
      <Tooltip title="Share view">
        <IconButton onClick={handleOpenDialog}>
          <SvgIcon sx={{ zIndex: 1 }}>
            <ShareIcon />
          </SvgIcon>
        </IconButton>
      </Tooltip>
      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Share the view</DialogTitle>
        <MUIIconButton
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
          aria-label="close"
          onClick={handleCloseDialog}
        >
          <CloseIcon />
        </MUIIconButton>
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            position: 'relative',
            width: 450,
          }}
        >
          {isError ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <ErrorIcon color="error" />
              <Typography color="error">
                Unable to share the view. Please try again later
              </Typography>
            </Box>
          ) : (
            <>
              <Typography color="secondary">
                To share this view, send the link below
              </Typography>
              <OutlinedInput
                sx={{
                  pr: 0.66,
                }}
                disabled={!shareId}
                size="small"
                endAdornment={
                  <InputAdornment position="end">
                    <CopyButton disabled={!shareId} copyContent={shareLink} />
                  </InputAdornment>
                }
                inputProps={{
                  disabled: true,
                }}
                value={shareLink}
              />
            </>
          )}
          <Loader active={isLoading} />
        </DialogContent>
      </Dialog>
    </>
  );
}

import {
  ToggleButton as MUIToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import type { MouseEvent } from 'react';
import { Analytics } from '@/shared/lib';
import { DataGridViewType } from '../../model/types';

const ToggleButton = styled(MUIToggleButton)(() => ({
  textTransform: 'none',
  width: 50,
  height: '100%',
  paddingTop: 0,
  paddingBottom: 0,
}));

interface Props {
  isTreeViewAvailable: boolean;
  value?: DataGridViewType;
  onChange: (v: DataGridViewType) => void;
}

export function DataGridTypeSwitcher({
  isTreeViewAvailable,
  value = DataGridViewType.dataTable,
  onChange,
}: Props) {
  const handleChange = (
    _event: MouseEvent,
    newValue: DataGridViewType | null,
  ) => {
    if (!newValue) {
      return;
    }

    onChange(newValue);
    Analytics.sendFeatureUsage('grid_type_switch', newValue, {
      page: window.location.pathname,
    });
  };

  return (
    <ToggleButtonGroup
      exclusive
      size="small"
      value={value}
      onChange={handleChange}
    >
      <ToggleButton value={DataGridViewType.dataTable}>Table</ToggleButton>
      <Tooltip
        title={
          !isTreeViewAvailable
            ? 'Select 2 or more dimensions to enable this view.'
            : ''
        }
      >
        <span>
          <ToggleButton
            value={DataGridViewType.treeDataGrid}
            disabled={!isTreeViewAvailable}
          >
            Tree
          </ToggleButton>
        </span>
      </Tooltip>
    </ToggleButtonGroup>
  );
}

import { AppBar, Toolbar } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { ReactNode } from 'react';
import { APP_NAME } from '@/shared/constants';
import { LogoIcon } from '@/shared/ui';
import { NavigationDrawer } from '../NavigationDrawer/NavigationDrawer';

const LogoWrapper = styled('div')(({ theme }) => ({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  fontSize: '24px',
  fontWeight: 500,
  gap: theme.spacing(1),
  marginLeft: theme.spacing(4),
}));

interface Props {
  title?: ReactNode;
  rightContentSlot?: ReactNode;
}

export function Header({ title = APP_NAME, rightContentSlot }: Props) {
  return (
    <AppBar position="static" elevation={0} sx={{ bgcolor: '#212429' }}>
      <Toolbar disableGutters>
        <NavigationDrawer />
        <LogoWrapper>
          <LogoIcon />
          <span>{title}</span>
        </LogoWrapper>
        {rightContentSlot}
      </Toolbar>
    </AppBar>
  );
}

import { Box, Divider } from '@mui/material';
import { lazy, Suspense, useMemo } from 'react';
import type { Filters } from '@/features/filters';
import {
  FiltersDrawer,
  FiltersParamsProvider,
  FiltersProvider,
  QuickFilters,
  QuickFiltersProvider,
  ResetAllButton,
} from '@/features/filters';
import { LifetimeSelect } from '@/features/LifetimeSelect';
import { ProbabilisticAttributionSwitcher } from '@/features/ProbabilisticAttribution';
import { DimensionColumnDataType } from '@/entities/columnsConfig';
import { isError, isLoading } from '@/shared/lib';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import type {
  DateScale,
  Lifetime,
  SerializedDateRangeValueObject,
} from '@/shared/types';
import { DateRangeSelect, DateScaleSelect, Loader } from '@/shared/ui';
import { COHORT_CHART_BASE_DIMENSION } from '../../model/constants';
import {
  getCohortChartNumberFilterParams,
  getCohortChartStringFilterParams,
  resetChartFilters,
  selectChartData,
  selectCohortMeta,
  updateChartFilters,
  updateChartPrimaryMetric,
  updateChartProbabilisticAttribution,
  updateChartSecondaryMetric,
  updateChartSplitDimension,
  updateCohortChartDateRange,
  updateCohortChartDateScale,
  updateCohortChartLifetime,
} from '../../model/slice';
import type { CohortChartState } from '../../model/types';
import { useChartData } from '../../model/useChartData';
import { LabeledSwitcherWrapper } from '../LabeledSwitchWrapper/LabeledSwitcherWrapper';
import type { DataPoint } from '../LineChart/types';
import { useLineChartParams } from '../LineChart/useLineChartParams';
import { LineChartToolbar } from '../LineChartToolbar/LineChartToolbar';
import { useLineChartToolbarParams } from '../LineChartToolbar/useLineChartToolbarParams';
import { LineChartWrapper } from '../LineChartWrapper/LineChartWrapper';
import { SplitLegend } from '../SplitLegend/SplitLegend';

const LineChart = lazy(() =>
  import('../LineChart/LineChart').then(({ LineChart }) => {
    return {
      default: LineChart<typeof COHORT_CHART_BASE_DIMENSION>,
    };
  }),
);

interface Props {
  chart: CohortChartState;
}

export function CohortChart({ chart }: Props) {
  const dispatch = useAppDispatch();
  const cohortMeta = useAppSelector(selectCohortMeta);
  const chartData = useAppSelector((state) => selectChartData(state, chart.id));
  const selectedDimensions = useMemo(() => {
    return [COHORT_CHART_BASE_DIMENSION, chart.splitDimension];
  }, [chart]);
  const numberFilterParamsDependencies = useMemo(() => {
    return [
      selectedDimensions,
      chart.filters,
      cohortMeta?.metrics,
      cohortMeta?.dimensions,
      chart.dateRange,
      chart.dateScale,
      chart.probabilisticAttribution,
      chart.lifetime,
    ];
  }, [chart, cohortMeta, selectedDimensions]);
  const stringFilterParamsDependencies = useMemo(() => {
    return [
      chart.filters,
      cohortMeta?.metrics,
      cohortMeta?.dimensions,
      chart.dateRange,
    ];
  }, [chart, cohortMeta]);
  const { metrics, splits } = useLineChartToolbarParams({
    meta: cohortMeta,
    defaultSplitDimension: COHORT_CHART_BASE_DIMENSION,
  });
  const isMediaContent = useMemo(() => {
    if (!cohortMeta) {
      return false;
    }

    return (
      cohortMeta.config[chart.splitDimension].type ===
      DimensionColumnDataType.MEDIA
    );
  }, [cohortMeta, chart]);
  const splitOptions =
    chartData?.data.splitOptions?.[chart.splitDimension] || [];
  const { noData, lines, leftY, rightY, data } = useLineChartParams({
    meta: cohortMeta,
    chart,
    data: (chartData?.data?.data || []) as DataPoint<
      typeof COHORT_CHART_BASE_DIMENSION
    >[],
    splitOptions,
    defaultSplitOption: COHORT_CHART_BASE_DIMENSION,
    excludedSplitOptions: chart.excludedSplitOptions,
  });
  const isDataLoading = chartData && isLoading(chartData.status);

  useChartData();

  const handleDateScaleChange = (newDateScale: DateScale) => {
    dispatch(
      updateCohortChartDateScale({
        id: chart.id,
        dateScale: newDateScale,
      }),
    );
  };
  const handleDateRangeChange = (
    newDateRange: SerializedDateRangeValueObject,
  ) => {
    dispatch(
      updateCohortChartDateRange({
        id: chart.id,
        dateRange: newDateRange,
      }),
    );
  };
  const handleLifetimeChange = (newLifetime: Lifetime) => {
    dispatch(
      updateCohortChartLifetime({
        id: chart.id,
        lifetime: newLifetime,
      }),
    );
  };
  const handleProbabilisticAttributionChange = (
    newProbabilisticAttribution: boolean,
  ) => {
    dispatch(
      updateChartProbabilisticAttribution({
        id: chart.id,
        probabilisticAttribution: newProbabilisticAttribution,
      }),
    );
  };
  const handleGetNumberFilterParams = (id: string) => {
    return dispatch(
      getCohortChartNumberFilterParams({
        filterName: id,
        selectedDimensions,
        filters: chart.filters,
        allMetrics: cohortMeta!.metrics,
        allDimensions: cohortMeta!.dimensions,
        dateRange: chart.dateRange,
        dateScale: chart.dateScale,
        probabilisticAttribution: chart.probabilisticAttribution,
        lifetime: chart.lifetime,
      }),
    );
  };
  const handleGetStringFilterParams = (id: string) => {
    return dispatch(
      getCohortChartStringFilterParams({
        filterName: id,
        filters: chart.filters,
        allMetrics: cohortMeta!.metrics,
        allDimensions: cohortMeta!.dimensions,
        dateRange: chart.dateRange,
      }),
    );
  };
  const handleFiltersChange = (newFilters: Filters) => {
    dispatch(
      updateChartFilters({
        id: chart.id,
        filters: newFilters,
      }),
    );
  };
  const handleResetAllClick = () => {
    dispatch(resetChartFilters());
  };
  const handleLeftYMetricChange = (newMetric: string) => {
    dispatch(
      updateChartPrimaryMetric({
        id: chart.id,
        value: newMetric,
      }),
    );
  };
  const handleRightYMetricChange = (newMetric: string) => {
    dispatch(
      updateChartSecondaryMetric({
        id: chart.id,
        value: newMetric,
      }),
    );
  };
  const handleSplitChange = (newSplit: string) => {
    dispatch(
      updateChartSplitDimension({
        id: chart.id,
        value: newSplit,
      }),
    );
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'space-between',
          gap: 1,
        }}
      >
        <DateScaleSelect
          value={chart.dateScale}
          onChange={handleDateScaleChange}
        />
        <DateRangeSelect
          value={chart.dateRange}
          onChange={handleDateRangeChange}
        />
        <Divider flexItem orientation="vertical" />
        <LifetimeSelect
          value={chart.lifetime}
          onChange={handleLifetimeChange}
        />
        <LabeledSwitcherWrapper>
          <ProbabilisticAttributionSwitcher
            value={chart.probabilisticAttribution}
            onChange={handleProbabilisticAttributionChange}
          />
        </LabeledSwitcherWrapper>
        <Box flexGrow={1} />
        <FiltersParamsProvider
          allDimensions={cohortMeta!.dimensions}
          allMetrics={cohortMeta!.metrics}
          columnsConfig={cohortMeta!.config}
          numberFilterParamsDeps={numberFilterParamsDependencies}
          stringFilterParamsDeps={stringFilterParamsDependencies}
          getNumberFilterParams={handleGetNumberFilterParams}
          getStringFilterParams={handleGetStringFilterParams}
        >
          <QuickFiltersProvider
            activeFilters={chart.filters}
            onChange={handleFiltersChange}
          >
            <QuickFilters />
          </QuickFiltersProvider>
          <Divider flexItem orientation="vertical" />
          <FiltersProvider
            activeFilters={chart.filters}
            onChange={handleFiltersChange}
          >
            <FiltersDrawer withBadge />
          </FiltersProvider>
          <ResetAllButton
            hasFilters={chart.filters.length > 0}
            onClick={handleResetAllClick}
          />
        </FiltersParamsProvider>
      </Box>
      <LineChartToolbar
        metrics={metrics}
        splits={splits}
        leftYMetric={chart.primaryMetric}
        rightYMetric={chart.secondaryMetric}
        activeSplit={chart.splitDimension}
        onLeftYMetricChange={handleLeftYMetricChange}
        onRightYMetricChange={handleRightYMetricChange}
        onSplitChange={handleSplitChange}
      />
      <Box sx={{ position: 'relative', flexGrow: 1, display: 'flex', gap: 3 }}>
        {!noData && chart.splitDimension !== COHORT_CHART_BASE_DIMENSION && (
          <SplitLegend
            isMediaContent={isMediaContent}
            name={cohortMeta?.config[chart.splitDimension]?.name!}
            options={splitOptions}
          />
        )}
        <LineChartWrapper
          loading={isDataLoading}
          noData={noData}
          error={!chartData || isError(chartData?.status)}
        >
          <Suspense fallback={<Loader active />}>
            <LineChart
              syncYAxesDomains={chart.syncedYAxes}
              excludedLines={chart.excludedSplitOptions}
              isMediaContent={isMediaContent}
              xAxisDataKey={COHORT_CHART_BASE_DIMENSION}
              leftY={leftY}
              rightY={rightY}
              data={data}
              lines={lines}
            />
          </Suspense>
        </LineChartWrapper>
      </Box>
    </>
  );
}

import { Navigate, Outlet } from 'react-router-dom';
import type { Features } from '@/features/featureFlags';
import { useFeatureFlags } from '@/features/featureFlags';
import { ROUTES } from '@/shared/constants';

interface Props {
  feature: Features;
}

export function FeatureGuard({ feature }: Props) {
  const { isFeatureAvailable } = useFeatureFlags();

  if (!isFeatureAvailable(feature)) {
    return <Navigate to={ROUTES.home} />;
  }

  return <Outlet />;
}

import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import type { SavedViewRecord } from '../../model/types';
import { ViewItem } from '../ViewItem/ViewItem';

const List = styled('ul')(() => ({
  padding: 0,
  margin: 0,
  listStyle: 'none',
}));

interface Props {
  views: SavedViewRecord[];
}

export function ViewsList({ views }: Props) {
  if (views.length === 0) {
    return (
      <Box
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Typography variant="label">Nothing found.</Typography>
      </Box>
    );
  }

  return (
    <List>
      {views.map((view) => {
        return <ViewItem key={view.uuid} view={view} />;
      })}
    </List>
  );
}

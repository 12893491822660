import { DataType } from '@/shared/lib';
import type { FilterType } from './types';
import { StringFilterType } from './types';

export const isStringFilter = (type: FilterType): boolean => {
  return type === StringFilterType.TEXT;
};

const NUMBER_TYPES = new Set<FilterType>([
  DataType.CURRENCY,
  DataType.NUMBER,
  DataType.PERCENTAGE,
]);

export const isNumberFilter = (type: FilterType): boolean => {
  return NUMBER_TYPES.has(type);
};

export const isMediaFilter = (type: FilterType): boolean => {
  return type === StringFilterType.MEDIA;
};

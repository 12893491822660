import ClearIcon from '@mui/icons-material/Clear';
import {
  Box,
  IconButton,
  OutlinedInput as MUIOutlinedInput,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import type { ChangeEventHandler } from 'react';
import { SearchIcon } from '@/shared/ui';

const OutlinedInput = styled(MUIOutlinedInput)(({ theme }) => ({
  padding: theme.spacing(1),

  '& .MuiSvgIcon-root': {
    width: 16,
    height: 16,
  },

  '& .MuiInputBase-input': {
    padding: 0,
    fontSize: '12px',
    height: 22,
    margin: `0 ${theme.spacing(0.5)}`,
  },

  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.primary.light,
  },

  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderWidth: 1,
    borderColor: theme.palette.primary.light,
  },
}));
interface Props {
  value: string;
  onChange: (v: string) => void;
}

export function SearchPanel({ value, onChange }: Props) {
  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    onChange(event.target.value);
  };
  const handleClear = () => {
    onChange('');
  };

  return (
    <Box sx={{ px: 2, pt: 3, pb: 1 }}>
      <OutlinedInput
        startAdornment={<SearchIcon />}
        endAdornment={
          value && (
            <IconButton sx={{ p: 0.5, m: -0.5 }} onClick={handleClear}>
              <ClearIcon />
            </IconButton>
          )
        }
        size="small"
        fullWidth
        value={value}
        onChange={handleChange}
      />
    </Box>
  );
}

import type { BoxProps } from '@mui/material';
import { Box } from '@mui/material';
import { QuickFilter } from '../QuickFilter/QuickFilter';
import { useQuickFiltersContext } from '../QuickFiltersContext/QuickFiltersContext';

interface Props {
  sx?: BoxProps['sx'];
}

export function QuickFilters({ sx }: Props) {
  const { quickFiltersIds } = useQuickFiltersContext();

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-end',
        gap: 2,
        maxWidth: 'calc(100% - 642px)',
        height: 40,
        overflow: 'hidden',
        ...sx,
      }}
    >
      {quickFiltersIds.map((id) => {
        return <QuickFilter key={id} id={id} />;
      })}
    </Box>
  );
}

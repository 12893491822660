import type { TypographyProps } from '@mui/material';
import { Typography, styled } from '@mui/material';

type LabelProps = TypographyProps & {
  text: string;
};

export const Label = styled(
  ({ text, children, ...typographyProps }: LabelProps) => {
    return (
      <Typography component="label" {...typographyProps}>
        {text}
        {children}
      </Typography>
    );
  },
)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(0.5),
  fontWeight: 500,
  fontSize: '0.875rem',
  lineHeight: 1.43,
}));

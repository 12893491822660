import { useState } from 'react';
import { DatasourcesFilters } from '@/features/DatasourcesFilters';
import type { DatasourceType } from '@/entities/datasources';
import { Marketplace } from '../Marketplace/Marketplace';

export function DatasourcesMarketplace() {
  const [searchQuery, setSearchQuery] = useState('');
  const [datasourceTypes, setDatasourceTypes] = useState<DatasourceType[]>([]);

  return (
    <>
      <DatasourcesFilters
        datasourceTypes={datasourceTypes}
        searchQuery={searchQuery}
        onDatasourceTypeChange={setDatasourceTypes}
        onSearchQueryChange={setSearchQuery}
      />
      <Marketplace
        datasourceTypes={datasourceTypes}
        searchQuery={searchQuery}
      />
    </>
  );
}

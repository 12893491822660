import { Box } from '@mui/material';
import { useMemo } from 'react';
import { NO_AXIS_SELECTED_VALUE } from '../../model/constants';
import type { Option } from '../AxisSelect/AxisSelect';
import { AxisSelect } from '../AxisSelect/AxisSelect';
import { LabeledSwitcherWrapper } from '../LabeledSwitchWrapper/LabeledSwitcherWrapper';
import { SyncedYAxesSwitcher } from '../SyncedYAxesSwitcher/SyncedYAxesSwitcher';

interface Props {
  splits: Option[];
  metrics: Option[];
  leftYMetric: string;
  rightYMetric: string;
  activeSplit: string;
  onLeftYMetricChange: (v: string) => void;
  onRightYMetricChange: (v: string) => void;
  onSplitChange: (v: string) => void;
}

export function LineChartToolbar({
  splits,
  metrics,
  leftYMetric,
  rightYMetric,
  activeSplit,
  onLeftYMetricChange,
  onRightYMetricChange,
  onSplitChange,
}: Props) {
  const rightYOptions: Option[] = useMemo(() => {
    return [
      {
        name: 'None',
        value: NO_AXIS_SELECTED_VALUE,
        group: '',
        order: 0,
      },
      ...metrics,
    ];
  }, [metrics]);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        gap: 1,
      }}
    >
      <AxisSelect
        label="Split"
        options={splits}
        value={activeSplit}
        onChange={onSplitChange}
      />
      <AxisSelect
        label="Left Y Metric"
        options={metrics}
        disabledOptions={[rightYMetric]}
        value={leftYMetric}
        onChange={onLeftYMetricChange}
      />
      <AxisSelect
        label="Right Y Metric"
        options={rightYOptions}
        disabledOptions={[leftYMetric]}
        value={rightYMetric}
        onChange={onRightYMetricChange}
      />
      <LabeledSwitcherWrapper>
        <SyncedYAxesSwitcher />
      </LabeledSwitcherWrapper>
    </Box>
  );
}

import { useEffect, useRef, useState } from 'react';

export const useDataFetcher = <T>(fetcher: () => Promise<T>): T | undefined => {
  const [data, setData] = useState<T | undefined>(undefined);
  const latestFetcher = useRef<() => Promise<T>>();

  useEffect(() => {
    latestFetcher.current = fetcher;
    fetcher().then((response) => {
      if (fetcher !== latestFetcher.current) {
        return;
      }

      setData(response);
    });

    return () => {
      setData(undefined);
    };
  }, [fetcher]);

  return data;
};

import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export function DatasourcesIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12 14.25C15.1066 14.25 17.625 11.7316 17.625 8.625C17.625 5.5184 15.1066 3 12 3C8.8934 3 6.375 5.5184 6.375 8.625C6.375 11.7316 8.8934 14.25 12 14.25Z"
          stroke="#666666"
          strokeWidth="2"
          strokeLinejoin="round"
        />
        <path
          d="M16.125 21C19.2316 21 21.75 18.4816 21.75 15.375C21.75 12.2684 19.2316 9.75 16.125 9.75C13.0184 9.75 10.5 12.2684 10.5 15.375C10.5 18.4816 13.0184 21 16.125 21Z"
          stroke="#666666"
          strokeWidth="2"
          strokeLinejoin="round"
        />
        <path
          d="M7.875 21C10.9816 21 13.5 18.4816 13.5 15.375C13.5 12.2684 10.9816 9.75 7.875 9.75C4.7684 9.75 2.25 12.2684 2.25 15.375C2.25 18.4816 4.7684 21 7.875 21Z"
          stroke="#666666"
          strokeWidth="2"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
}

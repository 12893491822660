import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Button as SharedButton } from '@/shared/ui';

const Button = styled(SharedButton)(({ theme }) => ({
  padding: `${theme.spacing(0.75)} ${theme.spacing(1.5)}`,
}));

interface Props {
  clearAllDisabled: boolean;
  onApply: () => void;
  onClearAll: () => void;
}

export function ActionsPanel({ clearAllDisabled, onApply, onClearAll }: Props) {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
      gap={0.5}
      py={3}
      px={2}
    >
      <Button
        size="small"
        color="secondary"
        variant="outlined"
        onClick={onClearAll}
        disabled={clearAllDisabled}
      >
        Clear All
      </Button>
      <Button onClick={onApply} size="small">
        Apply
      </Button>
    </Box>
  );
}

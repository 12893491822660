import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { ColorLevel } from '../../model/types';

const Label = styled('span')(() => ({
  fontSize: '0.66rem',
  color: 'var(--neutral-500)',
}));

interface Props {
  min?: number;
  max?: number;
  levels: ColorLevel[];
  formatter: (v: number) => string;
}

export function LevelsDisplay({ min, max, levels, formatter }: Props) {
  if (min == null || max == null) {
    return null;
  }

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Label>Min</Label>
        <Label>Max</Label>
      </Box>
      <Box sx={{ height: 4, display: 'flex', overflow: 'hidden' }}>
        {levels.map((level, index) => {
          return (
            <Box
              key={index}
              sx={{
                flexShrink: 0,
                height: '100%',
                width: `${getLevelWidth({
                  prevLevelValue: levels[index - 1]?.value,
                  currentLevelValue:
                    index === levels.length - 1 ? max : level.value,
                  min,
                  max,
                })}%`,
                backgroundColor: level.color,
              }}
            />
          );
        })}
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Label>{min != null ? formatter(min) : ''}</Label>
        <Label>{max != null ? formatter(max) : ''}</Label>
      </Box>
    </Box>
  );
}

interface GetLevelWidthParams {
  prevLevelValue?: number;
  currentLevelValue: number;
  min: number;
  max: number;
}

const getLevelWidth = ({
  prevLevelValue,
  currentLevelValue,
  min,
  max,
}: GetLevelWidthParams) => {
  return (
    ((Math.min(currentLevelValue, max) - (prevLevelValue ?? min)) * 100) /
    (max - min)
  );
};

import type { Datasource } from './types';
import { DatasourceStatus, DatasourceType } from './types';

export enum DatasourceIds {
  metaAds = 'meta-ads',
  googleAds = 'google-ads',
}

export const DATASOURCES: Datasource[] = [
  {
    id: DatasourceIds.metaAds,
    name: 'Meta Ads',
    type: DatasourceType.monetization,
    iconUrl: '/images/datasourceIcons/meta.png',
    status: DatasourceStatus.inactive,
  },
  {
    id: DatasourceIds.googleAds,
    name: 'Google Ads',
    type: DatasourceType.monetization,
    iconUrl: '/images/datasourceIcons/google-ads.png',
    status: DatasourceStatus.inactive,
  },
];

export const DATASOURCES_MAP = Object.fromEntries(
  DATASOURCES.map((datasource) => {
    return [datasource.id, datasource];
  }),
);

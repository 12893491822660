import { Box } from '@mui/material';
import type { FunctionComponent } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { Header } from '@/widgets/Header';
import { ProfileMenu } from '@/widgets/ProfileMenu';
import { DatasourceHeader } from '@/features/DatasourceHeader';
import {
  DatasourceIds,
  DATASOURCES_MAP,
  MetaAdsDatasource,
} from '@/entities/datasources';
import { GoogleAdsDatasource } from '@/entities/datasources/ui/GoogleAdsDatasource/GoogleAdsDatasource';
import { Layout } from '@/shared/ui';

const DatasourceComponents: Record<DatasourceIds, FunctionComponent> = {
  [DatasourceIds.metaAds]: MetaAdsDatasource,
  [DatasourceIds.googleAds]: GoogleAdsDatasource,
};

export function DatasourcePage() {
  const { datasourceId } = useParams<{ datasourceId: DatasourceIds }>();

  if (!datasourceId) {
    return <Navigate to=".." />;
  }

  const Component = DatasourceComponents[datasourceId];

  return (
    <Layout
      headerSlot={
        <Header
          rightContentSlot={
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                height: '100%',
              }}
            >
              <ProfileMenu />
            </Box>
          }
        />
      }
      slotProps={{
        content: {
          sx: {
            display: 'flex',
            flexDirection: 'column',
          },
        },
      }}
    >
      <DatasourceHeader datasource={DATASOURCES_MAP[datasourceId]} />
      <Component />
    </Layout>
  );
}

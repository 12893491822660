import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Page, Title } from '@/features/auth';
import {
  PasswordInput,
  validateConfirmation,
  validatePassword,
} from '@/features/changePassword';
import { changePassword } from '@/entities/session';
import { ROUTES } from '@/shared/constants';
import { Button, ErrorAlert, Loader } from '@/shared/ui';

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

export function PasswordRestorePage() {
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmation, setConfirmation] = useState('');
  const [confirmationError, setConfirmationError] = useState('');
  const [loading, setLoading] = useState(false);
  const [requestError, setRequestError] = useState('');

  const handlePasswordChange = (value: string) => {
    setPasswordError('');
    setPassword(value);
  };
  const handleConfirmationChange = (value: string) => {
    setConfirmationError('');
    setConfirmation(value);
  };
  const validatePasswordInput = (): boolean => {
    const error = validatePassword(password);

    if (error) {
      setPasswordError(error);
    }

    return !!error;
  };
  const validateConfirmationInput = (): boolean => {
    const error = validateConfirmation(password, confirmation);

    if (error) {
      setConfirmationError(error);
    }

    return !!error;
  };
  const handleRestore = () => {
    const passwordError = validatePasswordInput();
    const confirmationError = validateConfirmationInput();

    if (passwordError || confirmationError) {
      return;
    }

    setRequestError('');
    setLoading(true);
    changePassword(password)
      .then(() => {
        navigate(ROUTES.home);
      })
      .catch((error: Error) => {
        setRequestError(
          error.message || 'Something went wrong. Please, try again',
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleErrorClose = () => {
    setRequestError('');
  };

  return (
    <Page>
      <Title>Restore password</Title>
      <ErrorAlert onClose={handleErrorClose}>{requestError}</ErrorAlert>
      <Typography sx={{ mb: 3 }} variant="modalContent">
        Enter and confirm new password. The password should be at least 8
        characters long
      </Typography>
      <Container>
        <PasswordInput
          label="New password"
          error={passwordError}
          value={password}
          onBlur={validatePasswordInput}
          onChange={handlePasswordChange}
        />
        <PasswordInput
          label="Confirm password"
          error={confirmationError}
          value={confirmation}
          onBlur={validateConfirmationInput}
          onChange={handleConfirmationChange}
        />
        <Button sx={{ mt: 3 }} onClick={handleRestore}>
          Restore
        </Button>
      </Container>
      <Loader active={loading} />
    </Page>
  );
}

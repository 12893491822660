import { AdvancedSortFunctions, SortTypes, type Sorting } from '../types';

const FUNCTIONS_MAP: Record<AdvancedSortFunctions, string> = {
  [AdvancedSortFunctions.SUM]: 'sum',
};

export const prepareSorting = (sorting: Sorting): string => {
  const result: string[] = [];

  sorting.forEach((item, index) => {
    if (item.type === SortTypes.ADVANCED) {
      const applicableFilters: string[] = [];

      for (let i = 0; i < index; i++) {
        applicableFilters.push(sorting[i].name);
      }

      // to ensure consistent order in group
      applicableFilters.push(item.name);

      result.push(
        `${FUNCTIONS_MAP[item.function]}(${item.field}) over ` +
          `(partition by ${applicableFilters.join(', ')}) ${item.value}`,
      );
    }

    result.push(`${item.name} ${item.value}`);
  });

  return result.join(',');
};

import { Box } from '@mui/material';
import type { DatasourceStatus } from '../../model/types';
import { DATASOURCE_STATUS_COLOR_MAP } from '../datasourceStatusColorMap';

interface Props {
  status: DatasourceStatus;
}

export function StatusIndicator({ status }: Props) {
  return (
    <Box
      sx={{
        width: 12,
        height: 12,
        borderRadius: '50%',
        backgroundColor: DATASOURCE_STATUS_COLOR_MAP[status],
      }}
    />
  );
}

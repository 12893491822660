import {
  Badge,
  Box,
  Divider,
  Drawer,
  IconButton,
  SvgIcon,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { DrawerHeader, Button as SharedButton } from '@/shared/ui';
import { useFiltersContext } from '../FiltersContext/FiltersContext';
import { FiltersPanel } from '../FiltersPanel/FiltersPanel';
import FilterIcon from './filter.svg?react';

const Button = styled(SharedButton)(({ theme }) => ({
  padding: `${theme.spacing(0.5)} ${theme.spacing(2)}`,
}));

interface Props {
  withBadge?: boolean;
}

export function FiltersDrawer({ withBadge }: Props) {
  const { filters, submitFilters, resetToInitialFilters } = useFiltersContext();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    resetToInitialFilters();
    setOpen(false);
  };
  const handleApply = () => {
    submitFilters();
    handleClose();
  };

  const shouldShowBadge = withBadge && filters.length > 0;

  return (
    <>
      <IconButton onClick={handleOpen}>
        {shouldShowBadge ? (
          <Badge badgeContent={filters.length} color="primary">
            <SvgIcon>
              <FilterIcon />
            </SvgIcon>
          </Badge>
        ) : (
          <SvgIcon>
            <FilterIcon />
          </SvgIcon>
        )}
      </IconButton>
      <Drawer anchor="right" open={open} onClose={handleClose}>
        <DrawerHeader onClose={handleClose}>
          <Typography variant="h5">Filters</Typography>
        </DrawerHeader>
        <Box
          sx={{
            flexGrow: 1,
            width: 632,
            p: 4,
            overflowY: 'auto',
            maxHeight: 'calc(100vh - 128px)',
          }}
        >
          <FiltersPanel />
        </Box>
        <Divider />
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          gap={4}
          px={3}
          py={2}
        >
          <Button color="secondary" variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={handleApply}>Apply</Button>
        </Box>
      </Drawer>
    </>
  );
}

import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { IconButton, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import '@fontsource/roboto/500.css';
import { ROUTES } from '@/shared/constants';
import { RequiredAsterisk } from '@/shared/ui';

const Wrapper = styled('label')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(0.5),
}));
const LabelWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(0.25),
}));
const Input = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-input': {
    padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
    fontSize: '14px',
  },
}));
const ForgotPasswordLink = styled(Link)(({ theme }) => ({
  marginLeft: 'auto',
  fontSize: '12px',
  lineHeight: '20px',
  color: theme.palette.primary.main,
  textDecoration: 'none',
}));
const VisibilityButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

export function PasswordInput() {
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword((oldValue) => !oldValue);
  };

  return (
    <Wrapper>
      <LabelWrapper>
        <RequiredAsterisk />
        <Typography variant="label">Password</Typography>
        <ForgotPasswordLink to={ROUTES.passwordReset}>
          Forgot password
        </ForgotPasswordLink>
      </LabelWrapper>
      <Input
        name="password"
        required
        type={showPassword ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <VisibilityButton size="small" onClick={toggleShowPassword}>
              {showPassword ? (
                <VisibilityOutlinedIcon />
              ) : (
                <VisibilityOffOutlinedIcon />
              )}
            </VisibilityButton>
          ),
        }}
      />
    </Wrapper>
  );
}

import { DateRangePresets, DateScale, Lifetime } from '@/shared/types';
import type {
  CalendarChartState,
  ChartMeta,
  CohortChartState,
  MetaRegistry,
} from './types';
import { ChartType } from './types';

interface DefaultAxesParam {
  dimension: string;
  primaryMetric: string;
  secondaryMetric: string;
}

export const getCalendarChartDefaultProps = (
  defaultAxes: DefaultAxesParam,
): Omit<CalendarChartState, 'id'> => {
  return {
    type: ChartType.calendar,
    excludedSplitOptions: [],
    syncedYAxes: false,
    filters: [],
    cohortDateScale: DateScale.DAY,
    cohortDateRange: { preset: DateRangePresets.ALL_TIME },
    revenueDateScale: DateScale.DAY,
    revenueDateRange: { preset: DateRangePresets.LAST_90_DAYS },
    probabilisticAttribution: true,
    splitDimension: defaultAxes.dimension,
    primaryMetric: defaultAxes.primaryMetric,
    secondaryMetric: defaultAxes.secondaryMetric,
  };
};

export const getCohortChartDefaultProps = (
  defaultAxes: DefaultAxesParam,
): Omit<CohortChartState, 'id'> => {
  return {
    type: ChartType.cohort,
    excludedSplitOptions: [],
    syncedYAxes: false,
    filters: [],
    dateScale: DateScale.DAY,
    dateRange: { preset: DateRangePresets.LAST_90_DAYS },
    lifetime: Lifetime.YEAR,
    probabilisticAttribution: true,
    splitDimension: defaultAxes.dimension,
    primaryMetric: defaultAxes.primaryMetric,
    secondaryMetric: defaultAxes.secondaryMetric,
  };
};

export const getChartMeta = (
  meta: MetaRegistry,
  chartType: ChartType,
): ChartMeta => {
  if (chartType === ChartType.cohort) {
    return meta.cohortMarketingPerformanceMeta;
  } else if (chartType === ChartType.calendar) {
    return meta.calendarInsightsMeta;
  }

  throw new Error(`Unknow chart type "${chartType}"`);
};

import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export function ErrorIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.7953 4.78633C14.7638 3.06455 17.2428 3.06455 18.2113 4.78634L29.4315 24.7334C30.3814 26.4221 29.1611 28.5087 27.2235 28.5087H4.78311C2.84555 28.5087 1.62521 26.4221 2.57512 24.7334L13.7953 4.78633ZM15.9348 5.91153C15.9216 5.91893 15.9047 5.93173 15.8871 5.96296L4.66691 25.91C4.64995 25.9402 4.64748 25.961 4.64788 25.9764C4.64836 25.9954 4.65412 26.0189 4.66802 26.0427C4.68192 26.0665 4.69965 26.083 4.71593 26.0928C4.72915 26.1006 4.74854 26.1087 4.78311 26.1087H27.2235C27.2581 26.1087 27.2775 26.1006 27.2907 26.0928C27.307 26.083 27.3247 26.0665 27.3386 26.0427C27.3525 26.0189 27.3583 25.9954 27.3588 25.9764C27.3592 25.961 27.3567 25.9402 27.3397 25.91L16.1195 5.96296C16.102 5.93173 16.0851 5.91893 16.0719 5.91153C16.0553 5.90223 16.0316 5.895 16.0033 5.895C15.975 5.895 15.9513 5.90223 15.9348 5.91153Z"
          fill="#FF9494"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.6627 12.6833C14.6438 11.9298 15.2496 11.3087 16.0033 11.3087C16.7571 11.3087 17.3628 11.9298 17.344 12.6833L17.2013 18.3903C17.185 19.0403 16.6535 19.5587 16.0033 19.5587C15.3532 19.5587 14.8216 19.0403 14.8054 18.3903L14.6627 12.6833Z"
          fill="#FF9494"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.6283 20.9337H17.3783V23.6837H14.6283V20.9337Z"
          fill="#FF9494"
        />
      </svg>
    </SvgIcon>
  );
}

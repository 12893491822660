import { Button as MUIButton, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSavedViewsContext } from '../SavedViewsContext/SavedViewsContext';

const Button = styled(MUIButton)(() => ({
  height: 40,
  maxWidth: 150,
  textTransform: 'none',
}));
const NameLabel = styled('span')(() => ({
  fontWeight: 300,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
}));

interface Props {
  onClick: () => void;
}

export function ViewStatus({ onClick }: Props) {
  const { isAppliedViewModified, appliedView } = useSavedViewsContext();

  const name = appliedView?.saved_view_name || 'Unsaved';

  return (
    <Button variant="contained" color="primary" onClick={onClick}>
      View:&nbsp;
      <Tooltip title={name}>
        <NameLabel>
          {isAppliedViewModified && '*'}
          {name}
        </NameLabel>
      </Tooltip>
    </Button>
  );
}

// colors taken from https://medium.com/carbondesign/color-palettes-and-accessibility-features-for-data-visualization-7869f4874fca
export const LINE_COLORS = [
  '#6929c5',
  '#1392e9',
  '#025d5e',
  '#9f1953',
  '#fa4f56',
  '#520308',
  '#1a7f39',
  '#002d9d',
  '#ee5498',
  '#b28500',
  '#039d9b',
  '#01274b',
  '#8a3900',
  '#a76eff',
];

import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import { Box, Typography } from '@mui/material';

export function SomethingWrongPanel() {
  return (
    <Box
      sx={{
        p: 3,
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
        alignItems: 'center',
      }}
    >
      <ErrorRoundedIcon color="error" />
      <Typography textAlign="center">
        Something happened and we could not retrieve any data. Please try again
        later.
      </Typography>
    </Box>
  );
}

import type { SelectChangeEvent } from '@mui/material';
import { MenuItem, Select } from '@mui/material';
import { DateScale } from '@/shared/types';

const ITEMS: Record<DateScale, string> = {
  [DateScale.DAY]: 'Daily',
  [DateScale.WEEK]: 'Weekly',
  [DateScale.MONTH]: 'Monthly',
  [DateScale.THREE_DAYS]: '3 days',
  [DateScale.SEVEN_DAYS]: '7 days',
  [DateScale.FOURTEEN_DAYS]: '14 days',
  [DateScale.THIRTY_DAYS]: '30 days',
};

interface Props {
  value: DateScale;
  onChange: (v: DateScale) => void;
}

export function DateScaleSelect({ value, onChange }: Props) {
  const handleChange = (event: SelectChangeEvent) => {
    onChange(event.target.value as DateScale);
  };

  return (
    <Select sx={{ width: 105 }} value={value} onChange={handleChange}>
      {Object.entries(ITEMS).map(([value, label]) => {
        return (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        );
      })}
    </Select>
  );
}

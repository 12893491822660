import { styled } from '@mui/material/styles';
import type { ComponentProps, ReactNode } from 'react';

const Wrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
}));
const Content = styled('div')(() => ({
  flexGrow: 1,
}));

interface Props {
  headerSlot?: ReactNode;
  children?: ReactNode;
  slotProps?: {
    content?: ComponentProps<typeof Content>;
  };
}

export function Layout({ headerSlot, children, slotProps }: Props) {
  return (
    <Wrapper>
      {headerSlot}
      <Content {...(slotProps?.content || {})}>{children}</Content>
    </Wrapper>
  );
}

import { startOfToday, startOfYesterday, subDays, format } from 'date-fns';
import type {
  DateRange,
  FilterDateRangeDTO,
  SerializedDateRange,
  SerializedDateRangeValueObject,
} from '../types/dateRange';
import { CustomDateRangePreset, DateRangePresets } from '../types/dateRange';

const formatDate = (date: Date): string => {
  return format(date, 'yyyy-MM-dd');
};

export const toSerialized = (range: DateRange): SerializedDateRange => {
  return {
    from: formatDate(range.from),
    to: formatDate(range.to),
  };
};

export const fromSerialized = (range: SerializedDateRange): DateRange => {
  return {
    from: new Date(range.from),
    to: new Date(range.to),
  };
};

export const prepareDateRangeValueObject = (
  value: SerializedDateRangeValueObject,
): FilterDateRangeDTO => {
  let range: DateRange;

  if (value.preset === CustomDateRangePreset) {
    range = {
      from: new Date(value.dateRange.from),
      to: new Date(value.dateRange.to),
    };
  } else {
    range = getRangeByPreset(value.preset);
  }

  return [formatDate(range.from), formatDate(range.to)];
};

export const getRangeByPreset = (preset: DateRangePresets): DateRange => {
  const today = startOfToday();

  switch (preset) {
    case DateRangePresets.ALL_TIME:
      return {
        from: new Date(0),
        to: today,
      };
    case DateRangePresets.YESTERDAY:
      return {
        from: startOfYesterday(),
        to: startOfYesterday(),
      };
    case DateRangePresets.LAST_7_DAYS:
      return {
        from: subDays(today, 7),
        to: today,
      };
    case DateRangePresets.LAST_14_DAYS:
      return {
        from: subDays(today, 14),
        to: today,
      };
    case DateRangePresets.LAST_30_DAYS:
      return {
        from: subDays(today, 30),
        to: today,
      };
    case DateRangePresets.LAST_60_DAYS:
      return {
        from: subDays(today, 60),
        to: today,
      };
    case DateRangePresets.TODAY:
      return {
        from: today,
        to: today,
      };
    case DateRangePresets.LAST_90_DAYS:
    default:
      return {
        from: subDays(today, 90),
        to: today,
      };
  }
};

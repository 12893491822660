import { Backdrop as MUIBackdrop } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Spinner } from '@/shared/ui';

const Backdrop = styled(MUIBackdrop)(({ theme }) => ({
  position: 'absolute',
  backgroundColor: 'rgba(255, 255, 255, 0.5)',
  borderRadius: theme.shape.borderRadius,
}));

interface Props {
  active: boolean;
}

export function Loader({ active }: Props) {
  if (!active) {
    return null;
  }

  return (
    <Backdrop open>
      <Spinner />
    </Backdrop>
  );
}

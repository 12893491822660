import * as Sentry from '@sentry/react';
import React from 'react';
import {
  matchRoutes,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  createBrowserRouter,
} from 'react-router-dom';
import { isDevelopment } from '../utils';

class AbstractMonitoring {
  static get createBrowserRouter(): typeof createBrowserRouter {
    throw new Error('No implementation');
  }

  static init(): void {
    throw new Error('No implementation');
  }

  static setUser(_user: Sentry.User): void {
    throw new Error('No implementation');
  }

  static captureError(..._args: unknown[]) {
    throw new Error('No implementation');
  }
}

class NoMonitoring extends AbstractMonitoring {
  static get createBrowserRouter() {
    return createBrowserRouter;
  }

  static init() {}

  static captureError(): void {}

  static setUser(_user: Sentry.User): void {}
}

class SentryMonitoring extends AbstractMonitoring {
  private static _createBrowserRouter = createBrowserRouter;

  static init() {
    Sentry.init({
      dsn: 'https://87ac28832cdf535d7b93566cf53e7d34@o44050.ingest.us.sentry.io/4507327644172288',
      integrations: [
        Sentry.replayIntegration(),
        Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect: React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
      ],
      // Session Replay
      replaysSessionSampleRate: 0.01,
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
    SentryMonitoring._createBrowserRouter =
      Sentry.wrapCreateBrowserRouter(createBrowserRouter);
  }

  static get createBrowserRouter() {
    return SentryMonitoring._createBrowserRouter;
  }

  static setUser(user: Sentry.User): void {
    Sentry.setUser(user);
  }

  static captureError(error: unknown) {
    Sentry.captureException(error);
  }
}

export const Monitoring = isDevelopment() ? NoMonitoring : SentryMonitoring;

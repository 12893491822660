import { LicenseInfo } from '@mui/x-license';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { Monitoring } from '@/shared/lib';
import { appRouter } from './routing';
import { store } from './store/store';
import './main.css';

LicenseInfo.setLicenseKey(import.meta.env.VITE_MUI_LICENSE_KEY);
Monitoring.init();

const container = document.getElementById('root');

if (container) {
  const root = createRoot(container);

  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <RouterProvider router={appRouter(Monitoring.createBrowserRouter)} />
      </Provider>
    </React.StrictMode>,
  );
} else {
  throw new Error(
    "Root element with ID 'root' was not found in the document. Ensure there is a corresponding HTML element with the ID 'root' in your HTML file.",
  );
}

import { IconButton as MUIIconButton, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { ReactNode } from 'react';
import { TrashIcon } from '@/shared/ui';

const Wrapper = styled('div')(({ theme }) => ({
  flexGrow: 1,
  display: 'flex',
  gap: theme.spacing(1),
  alignItems: 'center',
  width: '100%',

  '& .deleteColumn': {
    display: 'none',
  },

  '&:hover': {
    '& .deleteColumn': {
      display: 'inherit',
    },
  },
}));
const LabelWrapper = styled('div')(() => ({
  flexGrow: 1,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  fontWeight: 500,
}));
const IconButton = styled(MUIIconButton)(({ theme }) => ({
  borderRadius: theme.spacing(0.5),
  padding: 0,
}));

interface Props {
  title: string;
  description?: string;
  sortButton: ReactNode;
  onDelete: () => void;
}

export function HeaderCell({
  title,
  description,
  sortButton,
  onDelete,
}: Props) {
  return (
    <Wrapper>
      <Tooltip
        arrow
        title={
          <>
            <p>{title}</p>
            {description && <p>{description}</p>}
          </>
        }
      >
        <LabelWrapper>{title}</LabelWrapper>
      </Tooltip>
      <IconButton className="deleteColumn" onClick={onDelete}>
        <TrashIcon />
      </IconButton>
      {sortButton}
    </Wrapper>
  );
}

import ErrorIcon from '@mui/icons-material/ErrorRounded';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { ReactNode } from 'react';
import { useState } from 'react';
import { Button as SharedButton, Loader } from '@/shared/ui';

const Button = styled(SharedButton)(({ theme }) => ({
  padding: `${theme.spacing(0.5)} ${theme.spacing(2)}`,
  justifyContent: 'space-between',
}));

interface Props {
  active: boolean;
  loading: boolean;
  error?: boolean;
  label: string;
  children: ReactNode;
  onErrorRetry: () => void;
}

export function Filter({
  active,
  loading,
  error,
  label,
  children,
  onErrorRetry,
}: Props) {
  const [open, setOpen] = useState(false);

  const showLoader = open && loading;

  const toggleOpen = () => {
    setOpen(!open);
  };

  return (
    <Box display="flex" flexDirection="column" gap={1} position="relative">
      <Button
        color={active ? 'primary' : 'secondary'}
        variant="outlined"
        endIcon={open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        onClick={toggleOpen}
      >
        {label}
      </Button>
      {open && (
        <>
          {error ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 1,
                py: 2,
                px: 3,
                backgroundColor: 'var(--accent-50)',
              }}
            >
              <ErrorIcon color="error" fontSize="large" />
              <Typography
                color="secondary"
                textAlign="center"
                variant="modalContent"
              >
                Unable to get filter options. Please try again.
              </Typography>
              <Button
                size="small"
                variant="outlined"
                color="inherit"
                onClick={onErrorRetry}
              >
                Retry
              </Button>
            </Box>
          ) : (
            <>
              <Box
                sx={{
                  py: 2,
                  px: 3,
                  backgroundColor: 'var(--accent-50)',
                }}
              >
                {children}
              </Box>
              <Loader active={showLoader} />
            </>
          )}
        </>
      )}
    </Box>
  );
}

import { z } from 'zod';
import type {
  ColumnDataFormatType,
  ColumnDataType,
} from '@/entities/columnsConfig';
import type { DataStatus, DataType } from '@/shared/lib';

export enum StringFilterType {
  TEXT = 'text',
  MEDIA = 'media',
}

export type FilterType = DataType | StringFilterType;

export interface FilterConfig {
  id: string;
  name: string;
  group: string;
  type: FilterType;
  dataType: ColumnDataFormatType;
  order: number;
}

const numberFilterValueValidator = z.tuple([z.number(), z.number()]);

export type NumberFilterValue = z.infer<typeof numberFilterValueValidator>;

const stringFilterValueValidator = z.array(z.string());

export type StringFilterValue = z.infer<typeof stringFilterValueValidator>;

const mediaFilterValueValidator = z.array(z.string());

export type MediaFilterValue = z.infer<typeof mediaFilterValueValidator>;

const filterValueValidator = stringFilterValueValidator
  .or(numberFilterValueValidator)
  .or(mediaFilterValueValidator);

export type FilterValue = z.infer<typeof filterValueValidator>;

export const filtersValidator = z.array(
  z.object({
    id: z.string(),
    value: filterValueValidator,
  }),
);

export type Filters = z.infer<typeof filtersValidator>;

export interface StringFilterParams {
  options: string[];
}

export interface MediaFilterOption {
  value: string;
  type: 'image';
  url: string;
}

export interface MediaFilterParams {
  options: MediaFilterOption[];
}

export interface NumberFilterParams {
  min: number;
  max: number;
}

export type FilterParams =
  | StringFilterParams
  | NumberFilterParams
  | MediaFilterParams;

export type FiltersParamsData = Record<
  string,
  {
    status: DataStatus;
    params?: FilterParams;
    type?: FilterType;
    error?: string;
  }
>;

export interface MetricFilter {
  id: string;
  values: NumberFilterValue;
}

export interface DimensionFilter {
  id: string;
  values: FilterValue;
}

interface ColumnConfig {
  group: string;
  name: string;
  type: ColumnDataType;
  dataType: ColumnDataFormatType;
  order: number;
}

export type ColumnsConfig = Record<string, ColumnConfig>;

import type { DistributiveOmit } from 'react-redux';
import { z } from 'zod';
import { filtersValidator } from '@/features/filters';
import type {
  MetricColumnDataType,
  DimensionColumnDataType,
  DimensionDataFormatType,
  MetricDataFormatType,
} from '@/entities/columnsConfig';
import type { DataStatus } from '@/shared/lib';
import {
  DateScale,
  Lifetime,
  serializedDateRangeValueObjectValidator,
} from '@/shared/types';
import type { DataResponse } from '../api/types';

export interface ChartData {
  status: DataStatus;
  requestedConfig: DataRequestedConfig;
  data: DataResponse;
}

export enum ChartType {
  cohort = 'cohort',
  calendar = 'calendar',
}

interface ColumnBaseConfig {
  id: string;
  group: string;
  name: string;
  description: string;
  order: number;
}
type MetricColumn = ColumnBaseConfig & {
  type: MetricColumnDataType;
  dataType: MetricDataFormatType;
};

type DimensionColumn = ColumnBaseConfig & {
  type: DimensionColumnDataType;
  dataType: DimensionDataFormatType;
};

type ColumnsConfig = Record<string, MetricColumn | DimensionColumn>;

export interface ChartMeta {
  defaultAxis: {
    dimension: string;
    primaryMetric: string;
    secondaryMetric: string;
  };
  config: ColumnsConfig;
  dimensions: string[];
  metrics: string[];
}

export interface MetaRegistry {
  calendarInsightsMeta: ChartMeta;
  cohortMarketingPerformanceMeta: ChartMeta;
}

const calendarChartStateValidator = z.object({
  id: z.string(),
  type: z.literal(ChartType.calendar),
  cohortDateScale: z.nativeEnum(DateScale),
  cohortDateRange: serializedDateRangeValueObjectValidator,
  revenueDateScale: z.nativeEnum(DateScale),
  revenueDateRange: serializedDateRangeValueObjectValidator,
  probabilisticAttribution: z.boolean(),
  splitDimension: z.string(),
  primaryMetric: z.string(),
  secondaryMetric: z.string(),
  excludedSplitOptions: z.array(z.string()),
  syncedYAxes: z.boolean(),
  filters: filtersValidator,
});

const cohortChartStateValidator = z.object({
  id: z.string(),
  type: z.literal(ChartType.cohort),
  dateScale: z.nativeEnum(DateScale),
  dateRange: serializedDateRangeValueObjectValidator,
  lifetime: z.nativeEnum(Lifetime),
  probabilisticAttribution: z.boolean(),
  splitDimension: z.string(),
  primaryMetric: z.string(),
  secondaryMetric: z.string(),
  excludedSplitOptions: z.array(z.string()),
  syncedYAxes: z.boolean(),
  filters: filtersValidator,
});

export const dataConfigValidator = cohortChartStateValidator.or(
  calendarChartStateValidator,
);

export type CohortChartState = z.infer<typeof cohortChartStateValidator>;

export type CalendarChartState = z.infer<typeof calendarChartStateValidator>;

export type Chart = CohortChartState | CalendarChartState;

export type DataRequestedConfig = DistributiveOmit<
  Chart,
  'excludedSplitOptions' | 'syncedYAxes'
>;

import { useEffect, useState } from 'react';

export const useMetricsStabilizedValue = (array: string[]) => {
  const [stabilizedValue, setStabilizedValue] = useState(array);
  const [latestValue, setLatestValue] = useState(array);

  useEffect(() => {
    const latestValueSet = new Set(latestValue);

    if (
      !array.every((item) => {
        return latestValueSet.has(item);
      })
    ) {
      setStabilizedValue(array);
    }

    setLatestValue(array);
  }, [array, latestValue]);

  return stabilizedValue;
};

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { Button } from '@/shared/ui';
import { GoogleIcon } from '../GoogleIcon/GoogleIcon';

export function GoogleAdsDatasource() {
  return (
    <Box
      sx={{
        py: 3,
        px: 5,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: 2,
      }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Account</TableCell>
            <TableCell>Last sync time</TableCell>
            <TableCell>Status</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell colSpan={4} align="center">
              No accounts added
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Button
        startIcon={<GoogleIcon />}
        component={Link}
        variant="outlined"
        color="inherit"
        target="_blank"
        to="https://accounts.google.com/o/oauth2/auth?response_type=code&client_id=203540375479-3ahnv3u4a7vvth8omabpsev8rje6dijq.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Fcampaignswell.com%2F&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fadwords&state=UJun9VPs7DtIZ8xAuK2rdFLQVFDWqR&access_type=offline&include_granted_scopes=true"
      >
        Add account
      </Button>
    </Box>
  );
}

import CloseIcon from '@mui/icons-material/Close';
import { Box, Divider, IconButton } from '@mui/material';
import type { ReactNode } from 'react';

interface Props {
  children: ReactNode;
  onClose: () => void;
}

export function DrawerHeader({ children, onClose }: Props) {
  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        gap={2}
        py={2}
        px={3}
      >
        {children}
        <IconButton sx={{ p: 0.5 }} size="small" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider />
    </>
  );
}

import type {
  DimensionFilter,
  Filters,
  MetricFilter,
  NumberFilterValue,
} from '../model/types';

interface PreparedFilters {
  metricsFilters: MetricFilter[];
  dimensionsFilters: DimensionFilter[];
}

export const prepareFilters = (
  filters: Filters,
  metrics: string[],
  dimensions: string[],
): PreparedFilters => {
  const metricsSet = new Set(metrics);
  const dimensionsSet = new Set(dimensions);
  const backendFilters: PreparedFilters = {
    metricsFilters: [],
    dimensionsFilters: [],
  };

  filters.forEach((filter) => {
    if (metricsSet.has(filter.id)) {
      backendFilters.metricsFilters.push({
        id: filter.id,
        values: filter.value as NumberFilterValue,
      });
    } else if (dimensionsSet.has(filter.id)) {
      backendFilters.dimensionsFilters.push({
        id: filter.id,
        values: filter.value,
      });
    }
  });

  return backendFilters;
};

import { isDevelopment } from '../utils';

const noop = () => {};

export class Analytics {
  private static _userId: string = '';

  static get userId() {
    return Analytics._userId;
  }

  static get dispatcher() {
    return !isDevelopment() && window.gtag ? window.gtag : noop;
  }

  static setUserId(id: string) {
    Analytics.dispatcher('set', 'user_id', id);
    Analytics._userId = id;
  }

  static sendFeatureUsage(
    feature: string,
    action: string,
    details: { [key: string]: string } = {},
  ) {
    Analytics.dispatcher('event', 'feature_usage', {
      user: Analytics.userId,
      page: window.location.pathname,
      feature,
      action,
      ...details,
    });
  }
}

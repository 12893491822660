import { Box } from '@mui/material';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import type { DatasourceType } from '@/entities/datasources';
import {
  DatasourceCard,
  DatasourceStatus,
  DATASOURCES,
} from '@/entities/datasources';

const STATUS_MAP: Record<DatasourceStatus, number> = {
  [DatasourceStatus.error]: 0,
  [DatasourceStatus.active]: 1,
  [DatasourceStatus.inactive]: 2,
};

interface Props {
  datasourceTypes: DatasourceType[];
  searchQuery: string;
}

export function Marketplace({ datasourceTypes, searchQuery }: Props) {
  const filteredDatasources = useMemo(() => {
    const typesSet = new Set(datasourceTypes);
    const preparedQuery = searchQuery.toLowerCase();

    return DATASOURCES.filter((datasource) => {
      return (
        datasource.name.toLocaleLowerCase().includes(preparedQuery) &&
        (typesSet.size === 0 || typesSet.has(datasource.type))
      );
    }).sort((a, b) => {
      if (a.status !== b.status) {
        const aStatus = STATUS_MAP[a.status];
        const bStatus = STATUS_MAP[b.status];

        return aStatus - bStatus;
      }

      return a.name.localeCompare(b.name);
    });
  }, [searchQuery, datasourceTypes]);

  return (
    <Box
      component="ul"
      sx={{
        m: 0,
        listStyleType: 'none',
        px: 5,
        pb: 5,
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, 200px)',
        gridTemplateRows: 'repeat(auto-fit, 200px)',
        gap: 4,
      }}
    >
      {filteredDatasources.map((datasource) => {
        return (
          <li key={datasource.id}>
            <Link style={{ textDecoration: 'none' }} to={`./${datasource.id}`}>
              <DatasourceCard datasource={datasource} />
            </Link>
          </li>
        );
      })}
    </Box>
  );
}

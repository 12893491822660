import { styled } from '@mui/material/styles';

export interface ColorLevel {
  value: number;
  color: string;
}

interface Props {
  value: number;
  colorLevels: ColorLevel[];
}

export const ColorCell = styled('div')<Props>(
  ({ value, colorLevels, theme }) => ({
    padding: `0 ${theme.spacing(1.25)}`,
    position: 'relative',

    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      backgroundColor: getColor(value, colorLevels),
      zIndex: -1,
    },
  }),
);

const getColor = (value: number, levels: ColorLevel[]) => {
  let color = 'transparent';

  levels.forEach((level, index) => {
    const prevLevelValue = index === 0 ? -Infinity : levels[index - 1].value;
    const currentLevelValue =
      index === levels.length - 1 ? Infinity : level.value;

    if (value > prevLevelValue && value <= currentLevelValue) {
      color = level.color;
    }
  });

  return color;
};

import type { SelectChangeEvent } from '@mui/material';
import { MenuItem, Select } from '@mui/material';
import type { DatasourceType } from '@/entities/datasources';
import { DATASOURCE_TYPE_LABEL_MAP } from '@/entities/datasources';

const TYPES_COUNT = Object.keys(DATASOURCE_TYPE_LABEL_MAP).length;

interface Props {
  value: DatasourceType[];

  onChange: (v: DatasourceType[]) => void;
}

export function SourceTypeSelect({ value, onChange }: Props) {
  const handleDatasourceTypesChange = (
    event: SelectChangeEvent<DatasourceType[]>,
  ) => {
    const {
      target: { value },
    } = event;

    onChange(value as DatasourceType[]);
  };
  return (
    <Select
      sx={{ width: 145, backgroundColor: 'secondary.dark', fontWeight: 500 }}
      displayEmpty
      multiple
      renderValue={(value) => {
        return `Source type ${
          value.length > 0 && value.length < TYPES_COUNT
            ? `(${value.length})`
            : ''
        }`;
      }}
      value={value}
      onChange={handleDatasourceTypesChange}
    >
      {Object.entries(DATASOURCE_TYPE_LABEL_MAP).map(([value, label]) => {
        return (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        );
      })}
    </Select>
  );
}

import { baseApi, getErrorMessage } from '@/shared/api';
import type { AnalyticsDTO } from './types';

export const getAnalyticsId = async (id: string) => {
  const { data, error } = await baseApi
    .from('analytics')
    .select('*')
    .eq('user_id', id)
    .returns<[AnalyticsDTO]>();

  if (!data) {
    const errorMessage = getErrorMessage(error);

    throw new Error(errorMessage);
  }

  return data[0].id;
};

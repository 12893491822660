import { useEffect } from 'react';
import type { Chart } from '@/widgets/chartsView';
import {
  updateChart,
  selectMetaIsLoaded,
  dataConfigValidator,
  initMeta,
} from '@/widgets/chartsView';
import { ImportSharedView } from '@/features/ShareView';
import { ROUTES } from '@/shared/constants';
import { Analytics } from '@/shared/lib';
import { useAppDispatch, useAppSelector } from '@/shared/model';

export function ImportSharedViewDialog() {
  const dispatch = useAppDispatch();
  const isColumnsMetaLoaded = useAppSelector(selectMetaIsLoaded);

  useEffect(() => {
    dispatch(initMeta());
  }, [dispatch]);

  const handleApplyView = (view: unknown) => {
    dispatch(updateChart(view as Chart));
    Analytics.sendFeatureUsage('share_view', 'apply', {
      page: ROUTES.chartsView,
    });
  };
  const isViewValid = (view: unknown) => {
    const parseResult = dataConfigValidator.safeParse(view);

    return parseResult.success;
  };

  if (!isColumnsMetaLoaded) {
    return null;
  }

  return (
    <ImportSharedView isViewValid={isViewValid} applyView={handleApplyView} />
  );
}

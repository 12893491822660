import type { SelectChangeEvent } from '@mui/material';
import { MenuItem, Select } from '@mui/material';
import { Label } from '@/shared/ui';

interface Props {
  value: number;
  onChange: (v: number) => void;
}

export function LevelsNumberSelect({ value, onChange }: Props) {
  const handleLevelCountChange = (event: SelectChangeEvent<number>) => {
    onChange(event.target.value as number);
  };

  return (
    <Label
      text="Number of levels: "
      sx={{
        flexDirection: 'row',
        alignItems: 'center',
        gap: 1,
      }}
    >
      <Select value={value} onChange={handleLevelCountChange}>
        {Array(6)
          .fill(0)
          .map((_, ind) => {
            const value = ind + 2;

            return (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            );
          })}
      </Select>
    </Label>
  );
}

import type { PaperProps } from '@mui/material';
import { Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { ReactNode } from 'react';
import { Logo } from '@/shared/ui';

const Wrapper = styled('main')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
}));
const Background = styled('figure')(() => ({
  position: 'fixed',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  zIndex: -1,
  margin: 0,
  backgroundImage: 'url("/images/gridTemplate.png")',
  backgroundPosition: '96px 0, 0 0',
  backgroundSize: '192px, 192px',
}));
const FormWrapper = styled((props: PaperProps) => {
  return <Paper elevation={8} {...props} />;
})(({ theme }) => ({
  position: 'relative',
  padding: `${theme.spacing(5)} ${theme.spacing(3)} ${theme.spacing(5.25)}`,
  width: '392px',
}));

interface Props {
  children: ReactNode;
}

export function Page({ children }: Props) {
  return (
    <Wrapper>
      <Background />
      <FormWrapper>
        <Logo logoHeight={40} sx={{ mb: 3 }} />
        {children}
      </FormWrapper>
    </Wrapper>
  );
}

import isEqual from 'lodash.isequal';
import { useEffect, useState } from 'react';
import { isFinished } from '@/shared/lib';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import { getDataRequestedConfig } from './getDataRequestedConfig';
import {
  getChartData,
  selectChart,
  selectChartData,
  setupDefaultExcludedOptions,
} from './slice';

export const useChartData = () => {
  const dispatch = useAppDispatch();
  const chart = useAppSelector(selectChart)!;
  const chartData = useAppSelector((state) => selectChartData(state, chart.id));
  const [previousSplitDimension, setPreviousSplitDimension] = useState(
    chart.splitDimension,
  );

  useEffect(() => {
    if (
      chartData &&
      isEqual(chartData.requestedConfig, getDataRequestedConfig(chart))
    ) {
      return;
    }

    dispatch(getChartData(chart));
  }, [chart, chartData, dispatch]);
  useEffect(() => {
    if (!chartData) {
      return;
    }

    const { splitDimension } = chartData.requestedConfig;

    if (
      previousSplitDimension !== splitDimension &&
      isFinished(chartData.status)
    ) {
      dispatch(setupDefaultExcludedOptions());
      setPreviousSplitDimension(splitDimension);
    }
  }, [dispatch, previousSplitDimension, chartData]);
};

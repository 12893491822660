import type { SliderProps } from '@mui/material';
import { Slider as MUISlider } from '@mui/material';
import { styled } from '@mui/material/styles';

const CustomSlider = styled(MUISlider)(({ theme }) => ({
  margin: `${theme.spacing(2)} ${theme.spacing(1.5)} ${theme.spacing(5)}`,
  width: `calc(100% - ${theme.spacing(3)})`,
  height: '8px',

  '& .MuiSlider-track, .MuiSlider-rail': {
    background: 'linear-gradient(to left, #8FE8FD, #0091B4)',
    border: 'none',
  },

  '& .MuiSlider-thumb': {
    border: '4px solid #3794D7',
    backgroundColor: 'white',
    height: '24px',
    width: '24px',
  },

  '& .MuiSlider-mark': {
    display: 'none',
  },

  '& .MuiSlider-markLabel': {
    fontWeight: 500,
    color: '#BFBEBE',

    '&[data-index="0"]': {
      transform: `translateX(-${theme.spacing(1.5)})`,
    },
    '&[data-index="1"]': {
      transform: `translateX(calc(-100% + ${theme.spacing(1.5)}))`,
    },
  },
}));

interface Props {
  step: number;
  min: number;
  max: number;
  value: [number, number];
  formatter: (v: number) => string;
  onChange: (v: [number, number]) => void;
}

export function Slider({ step, min, max, value, formatter, onChange }: Props) {
  // styling built on assumption of 2 marks
  const marks = [
    {
      value: min,
      label: formatter(min),
    },
    {
      value: max,
      label: formatter(max),
    },
  ];
  const handleChange: SliderProps['onChange'] = (_e, value) => {
    if (typeof value !== 'number') {
      onChange([value[0], value[1]]);
    }
  };

  return (
    <CustomSlider
      marks={marks}
      step={step}
      min={min}
      max={max}
      value={value}
      valueLabelDisplay="auto"
      valueLabelFormat={formatter}
      onChange={handleChange}
    />
  );
}

import {
  Box,
  IconButton as MUIIconButton,
  SvgIcon,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { SortOptions } from '@/shared/types';
import SortAscIcon from './sortAsc.svg?react';
import SortDescIcon from './sortDesc.svg?react';

interface Props {
  order?: number;
  value?: SortOptions;
  onChange: (v?: SortOptions) => void;
}

const IconButton = styled(MUIIconButton)(({ theme }) => ({
  borderRadius: theme.spacing(0.5),
  padding: 0,
}));

export function SortButton({ order, value, onChange }: Props) {
  const isAsc = value === SortOptions.ASC;
  const isDesc = value === SortOptions.DESC;
  const isActive = value && order != null;

  const handleChange = () => {
    if (!value) {
      onChange(SortOptions.ASC);
    } else if (value === SortOptions.ASC) {
      onChange(SortOptions.DESC);
    } else {
      onChange();
    }
  };

  return (
    <IconButton onClick={handleChange}>
      {isActive && (
        <Typography color="primary" fontSize="0.5rem" pl={0.5} mr={-0.5}>
          {order}
        </Typography>
      )}
      <Box display="flex">
        <SvgIcon sx={{ mr: -3 }} color={isDesc ? 'primary' : 'inherit'}>
          <SortDescIcon />
        </SvgIcon>
        <SvgIcon color={isAsc ? 'primary' : 'inherit'}>
          <SortAscIcon />
        </SvgIcon>
      </Box>
    </IconButton>
  );
}

import { z } from 'zod';

export enum SortOptions {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum AdvancedSortFunctions {
  SUM = 'SUM',
}

export enum SortTypes {
  SIMPLE = 'simple',
  ADVANCED = 'advanced',
}

const sortingItemValidator = z
  .object({
    type: z.literal(SortTypes.SIMPLE).optional(),
    name: z.string(),
    value: z.nativeEnum(SortOptions),
  })
  .or(
    z.object({
      type: z.literal(SortTypes.ADVANCED),
      name: z.string(),
      value: z.nativeEnum(SortOptions),
      field: z.string(),
      function: z.nativeEnum(AdvancedSortFunctions),
    }),
  );

export const sortingValidator = z.array(sortingItemValidator);

export type SortingItem = z.infer<typeof sortingItemValidator>;
export type Sorting = z.infer<typeof sortingValidator>;

import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export function InfoIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.0008 4.70005C9.48382 4.70005 4.20078 9.98309 4.20078 16.5C4.20078 23.017 9.48382 28.3 16.0008 28.3C22.5177 28.3 27.8008 23.017 27.8008 16.5C27.8008 9.98309 22.5177 4.70005 16.0008 4.70005ZM1.80078 16.5C1.80078 8.65761 8.15834 2.30005 16.0008 2.30005C23.8432 2.30005 30.2008 8.65761 30.2008 16.5C30.2008 24.3425 23.8432 30.7001 16.0008 30.7001C8.15834 30.7001 1.80078 24.3425 1.80078 16.5Z"
          fill="#A9E9F1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.6009 23.1001C14.6008 23.8732 15.2276 24.5001 16.0008 24.5001C16.7739 24.5001 17.4007 23.8733 17.4006 23.1001L17.3998 14.899C17.3998 14.1264 16.7734 13.5001 16.0008 13.5001C15.2282 13.5001 14.6018 14.1264 14.6017 14.899L14.6009 23.1001Z"
          fill="#A9E9F1"
        />
        <path d="M14.5008 11.5H17.5008V8.50005H14.5008V11.5Z" fill="#A9E9F1" />
      </svg>
    </SvgIcon>
  );
}

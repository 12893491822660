import type { ButtonProps } from '@mui/material';
import { Button as MUIButton } from '@mui/material';
import { styled } from '@mui/material/styles';

// customized here instead of theme due to incorrect color selection based on color proprety

export const Button = styled((props: ButtonProps) => {
  return <MUIButton variant="contained" {...props} />;
})(({ theme, color }) => ({
  paddingTop: theme.spacing(1.5),
  paddingBottom: theme.spacing(1.5),
  textTransform: 'inherit',
  ...(color === 'secondary' && {
    borderColor: 'var(--neutral-500)',
    color: 'var(--neutral-700)',

    '&:hover': {
      borderColor: 'var(--neutral-500)',
      backgroundColor: 'var(--neutral-200)',
    },
  }),
})) as typeof MUIButton;

import type { createBrowserRouter } from 'react-router-dom';
import {
  CalendarInsightsPage,
  ImportSharedViewDialog as CalendarInsightsImportSharedViewDialog,
} from '@/pages/CalendarInsights';
import { ImportSharedViewDialog as ChartsViewImportSharedViewDialog } from '@/pages/ChartsView';
import {
  CohortMarketingPreformancePage,
  ImportSharedViewDialog as CohortMarketingPerformanceImportSharedViewDialog,
} from '@/pages/CohortMarketingPerformance';
import { DatasourcePage } from '@/pages/Datasource';
import { DatasourcesPage } from '@/pages/Datasources';
import { HomePage } from '@/pages/Home';
import { PasswordResetPage } from '@/pages/PasswordReset';
import { PasswordRestorePage } from '@/pages/PasswordRestore';
import { SignInPage } from '@/pages/SignIn';
import { Features } from '@/features/featureFlags';
import { ROUTES } from '@/shared/constants';
import { AuthGuard } from '../ui/AuthGuard/AuthGuard';
import { Baseline } from '../ui/Baseline/Baseline';
import { FeatureGuard } from '../ui/FeatureGuard/FeatureGuard';
import { GlobalErrorBoundary } from '../ui/GlobalErrorBoundary/GlobalErrorBoundary';
import { GuestGuard } from '../ui/GuestGuard/GuestGuard';

export const appRouter = (
  createBrowserRouterFunction: typeof createBrowserRouter,
) => {
  return createBrowserRouterFunction([
    {
      path: ROUTES.home,
      element: <Baseline />,
      errorElement: <GlobalErrorBoundary />,
      children: [
        {
          element: <AuthGuard />,
          children: [
            { index: true, element: <HomePage /> },
            {
              path: ROUTES.cohortMarketingPerformance,
              element: <CohortMarketingPreformancePage />,
              children: [
                {
                  path: 'share/:id',
                  element: <CohortMarketingPerformanceImportSharedViewDialog />,
                },
              ],
            },
            {
              path: ROUTES.calendarInsights,
              element: <CalendarInsightsPage />,
              children: [
                {
                  path: 'share/:id',
                  element: <CalendarInsightsImportSharedViewDialog />,
                },
              ],
            },
            {
              path: ROUTES.chartsView,
              lazy: () => {
                return import('@/pages/ChartsView').then(
                  ({ ChartsViewPage }) => {
                    return {
                      Component: ChartsViewPage,
                    };
                  },
                );
              },
              children: [
                {
                  path: 'share/:id',
                  element: <ChartsViewImportSharedViewDialog />,
                },
              ],
            },
            { path: ROUTES.passwordRestore, element: <PasswordRestorePage /> },
            {
              path: ROUTES.datasources,
              element: <FeatureGuard feature={Features.datasources} />,
              children: [
                { index: true, element: <DatasourcesPage /> },
                { path: ':datasourceId', element: <DatasourcePage /> },
              ],
            },
          ],
        },
        {
          element: <GuestGuard />,
          children: [
            { path: ROUTES.signin, element: <SignInPage /> },
            { path: ROUTES.passwordReset, element: <PasswordResetPage /> },
          ],
        },
      ],
    },
  ]);
};

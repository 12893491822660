import { z } from 'zod';

const colorLevelValidator = z.object({
  value: z.number(),
  color: z.string(),
});

export type ColorLevel = z.infer<typeof colorLevelValidator>;

export const metricColoringValidator = z.object({
  metric: z.string(),
  levels: z.array(colorLevelValidator),
});

export type MetricColoring = z.infer<typeof metricColoringValidator>;

import { Box, IconButton as MUIIconButton, SvgIcon } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Analytics } from '@/shared/lib';
import { Direction } from '../../model/types';
import { useTimeMachine } from '../../model/useTimeMachine';
import RedoIcon from './redo.svg?react';
import ResetIcon from './reset.svg?react';
import UndoIcon from './undo.svg?react';

const IconButton = styled(MUIIconButton)(({ theme }) => ({
  color: 'currentColor',
  borderRadius: theme.shape.borderRadius,

  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },

  '&:disabled': {
    color: 'white',
    opacity: 0.3,
  },
}));

interface Props {
  snapshot: unknown;
  onReset: () => void;
  onTravel: (snapshot: unknown) => void;
}

export function TimeMachine({ snapshot, onReset, onTravel }: Props) {
  const { hasNext, hasPrevious, travel } = useTimeMachine({
    snapshot,
    onTravel,
  });

  const handleUndo = () => {
    travel(Direction.previous);
    Analytics.sendFeatureUsage('time_machine', 'undo', {
      page: window.location.pathname,
    });
  };
  const handleRedo = () => {
    travel(Direction.next);
    Analytics.sendFeatureUsage('time_machine', 'redo', {
      page: window.location.pathname,
    });
  };
  const handleReset = () => {
    onReset();
    Analytics.sendFeatureUsage('time_machine', 'reset', {
      page: window.location.pathname,
    });
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
      <IconButton disabled={!hasPrevious} onClick={handleUndo}>
        <SvgIcon>
          <UndoIcon />
        </SvgIcon>
      </IconButton>
      <IconButton disabled={!hasNext} onClick={handleRedo}>
        <SvgIcon>
          <RedoIcon />
        </SvgIcon>
      </IconButton>
      <IconButton onClick={handleReset}>
        <SvgIcon>
          <ResetIcon />
        </SvgIcon>
      </IconButton>
    </Box>
  );
}

import type { User } from '@supabase/supabase-js';
import { DataStatus } from '@/shared/lib/dataFetching';
import { createAppSlice } from '@/shared/model';
import * as SessionAPI from '../api/sessionAPI';
import type { LoginParams } from '../api/types';

interface State {
  user?: User;
  status: DataStatus;
}

const initialState: State = {
  status: DataStatus.idle,
};

export const sessionSlice = createAppSlice({
  name: 'session',
  initialState,
  reducers: (create) => ({
    setupUser: create.asyncThunk(
      (_: void) => {
        return SessionAPI.getUserData();
      },
      {
        pending: (state) => {
          state.status = DataStatus.loading;
        },
        fulfilled: (state, action) => {
          state.user = action.payload;
        },
        settled: (state) => {
          state.status = DataStatus.finished;
        },
      },
    ),
    login: create.asyncThunk(
      (params: LoginParams) => {
        if (params.shouldRemember) {
          SessionAPI.updateRememberMe(
            (params.credentials as { email: string }).email,
          );
        }

        return SessionAPI.login(params.credentials);
      },
      {
        pending: (state) => {
          state.status = DataStatus.loading;
        },
        fulfilled: (state, action) => {
          state.user = action.payload;
        },
        settled: (state) => {
          state.status = DataStatus.finished;
        },
      },
    ),
    logout: create.asyncThunk(
      (_: void) => {
        SessionAPI.deleteRememberMe();

        return SessionAPI.logout();
      },
      {
        pending: (state) => {
          state.status = DataStatus.loading;
        },
        fulfilled: (state) => {
          state.user = undefined;
        },
        settled: (state) => {
          state.status = DataStatus.idle;
        },
      },
    ),
  }),
  selectors: {
    selectIsSessionRequested: (session) => {
      return session.status !== DataStatus.idle;
    },
    selectIsSessionLoading: (session) => {
      return session.status === DataStatus.loading;
    },
    selectIsAuthorized: (session) => {
      return session.status === DataStatus.finished && !!session.user;
    },
    selectEmail: (session) => {
      return session.user?.email;
    },
  },
});

export const { login, logout, setupUser } = sessionSlice.actions;

export const {
  selectIsSessionRequested,
  selectIsSessionLoading,
  selectIsAuthorized,
  selectEmail,
} = sessionSlice.selectors;

import type { ReactNode } from 'react';
import { createContext, useContext, useMemo } from 'react';
import { prepareUIFilters } from '../../model/prepareUIFilters';
import type { FilterValue, Filters } from '../../model/types';
import { useFiltersParamsContext } from '../FiltersParamsContext/FiltersParamsContext';

const DEFAULT_QUICK_FILTER_IDS = [
  'application_name',
  'platform',
  'country_code',
  'media_source',
];

interface ContextState {
  quickFiltersIds: string[];
  filtersMap: Record<string, Filters[number]['value']>;
  updateFilter: (id: string, value: FilterValue) => void;
}

const Context = createContext<ContextState | null>(null);

interface Props {
  activeFilters: Filters;
  children: ReactNode;
  onChange: (v: Filters) => void;
}

export const QuickFiltersProvider = ({
  activeFilters,
  children,
  onChange,
}: Props) => {
  const { configsMap, paramsData, resetParamsData } = useFiltersParamsContext();
  const { filtersMap, quickFiltersIds } = useMemo(() => {
    const filtersIds = activeFilters.map((filter) => {
      return filter.id;
    });

    return {
      filtersMap: Object.fromEntries(
        activeFilters.map((filter) => {
          return [filter.id, filter.value];
        }),
      ),
      quickFiltersIds: [
        ...filtersIds,
        ...DEFAULT_QUICK_FILTER_IDS.filter((id) => {
          return !filtersIds.includes(id) && !!configsMap[id];
        }),
      ],
    };
  }, [activeFilters, configsMap]);

  return (
    <Context.Provider
      value={{
        filtersMap,
        quickFiltersIds,
        updateFilter: (id, value) => {
          const updatedFilters = [
            { id, value },
            ...activeFilters.filter((filter) => {
              return filter.id !== id;
            }),
          ];
          const newFilters = prepareUIFilters({
            filters: updatedFilters,
            paramsData,
            configsMap,
          });

          resetParamsData(newFilters);
          onChange(newFilters);
        },
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useQuickFiltersContext = () => {
  const context = useContext(Context);

  if (!context) {
    throw new Error(
      'useQuickFiltersContext hook used outside <QuickFiltersProvider />',
    );
  }

  return context;
};

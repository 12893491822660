import { Box, Divider } from '@mui/material';
import type { DatasourceType } from '@/entities/datasources';
import { SearchInput } from '../SearchInput/SearchInput';
import { SourceTypeSelect } from '../SourceTypeSelect/SourceTypeSelect';

interface Props {
  datasourceTypes: DatasourceType[];
  searchQuery: string;
  onDatasourceTypeChange: (v: DatasourceType[]) => void;
  onSearchQueryChange: (v: string) => void;
}

export function DatasourcesFilters({
  datasourceTypes,
  searchQuery,
  onDatasourceTypeChange,
  onSearchQueryChange,
}: Props) {
  return (
    <Box
      sx={{
        pt: 2,
        px: 5,
        pb: 3,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        gap: 2,
      }}
    >
      <SearchInput value={searchQuery} onChange={onSearchQueryChange} />
      <Divider flexItem orientation="vertical" />
      <SourceTypeSelect
        value={datasourceTypes}
        onChange={onDatasourceTypeChange}
      />
    </Box>
  );
}

import {
  FunctionsFetchError,
  FunctionsHttpError,
  FunctionsRelayError,
} from '@supabase/supabase-js';

export const getErrorMessage = (error: any): string => {
  if (!error) {
    return '';
  }

  let errorMessage = 'Something went wrong';

  if (error instanceof FunctionsHttpError) {
    errorMessage = error.message;
  } else if (error instanceof FunctionsRelayError) {
    errorMessage = `Relay error ${error.message}`;
  } else if (error instanceof FunctionsFetchError) {
    errorMessage = `Fetch error ${error.message}`;
  }

  return errorMessage;
};

import MoreVertIcon from '@mui/icons-material/MoreVert';
import type { ButtonProps } from '@mui/material';
import {
  Button as MUIButton,
  IconButton,
  Menu,
  MenuItem as MUIMenuItem,
  Tooltip,
  Box,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import type { MouseEvent } from 'react';
import { useState } from 'react';
import { Analytics } from '@/shared/lib';
import { TrashIcon } from '@/shared/ui';
import type { SavedViewRecord } from '../../model/types';
import { useSavedViewsContext } from '../SavedViewsContext/SavedViewsContext';

const Item = styled((props: ButtonProps) => {
  return <MUIButton fullWidth component="li" {...props} />;
})(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: 'inherit',
  textTransform: 'none',
  padding: `${theme.spacing(2)} 0 ${theme.spacing(2)} ${theme.spacing(0.5)}`,
  display: 'flex',
  gap: theme.spacing(2),
  alignItems: 'start',
  borderRadius: 0,
  borderBottom: `1px solid ${theme.palette.divider}`,
}));
const MenuItem = styled(MUIMenuItem)(({ theme }) => ({
  gap: theme.spacing(1),
  fontSize: '14px',
}));
const ActiveDot = styled('span')(({ theme }) => ({
  display: 'inline-block',
  width: 6,
  height: 6,
  backgroundColor: theme.palette.info.main,
  borderRadius: '50%',
}));
const ActiveLabel = styled('span')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  alignItems: 'center',
  fontSize: '14px',
  lineHeight: '22px',
}));

interface Props {
  view: SavedViewRecord;
}

export function ViewItem({ view }: Props) {
  const { appliedViewUuid, applyView, deleteView } = useSavedViewsContext();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event: MouseEvent) => {
    event.stopPropagation();
    setAnchorEl(null);
  };
  const handleDeleteView = () => {
    deleteView(view.uuid);
    Analytics.sendFeatureUsage('saved_views', 'delete_view', {
      page: window.location.pathname,
    });
  };
  const handleViewApply = () => {
    applyView(view.uuid);
    Analytics.sendFeatureUsage('saved_views', 'apply_view', {
      page: window.location.pathname,
    });
  };
  const modifiedDate = new Date(view.updated_at);
  const dateLabel = modifiedDate.toLocaleDateString();
  const timeLabel = modifiedDate.toLocaleTimeString().slice(0, -3);
  const active = view.uuid === appliedViewUuid;
  const name = view.saved_view_name;

  return (
    <Item key={view.uuid} onClick={handleViewApply}>
      <Box sx={{ flexGrow: 1, display: 'flex', gap: 2, alignItems: 'start' }}>
        <Tooltip title={name}>
          <Typography
            variant="subtitle2"
            sx={{
              flexGrow: 1,
              fontWeight: 400,
              lineHeight: '24px',
              maxWidth: 170,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {name}
          </Typography>
        </Tooltip>
        <Box
          sx={{
            flexShrink: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            gap: 0.5,
            fontSize: '12px',
            lineHeight: '16px',
          }}
        >
          {active && (
            <ActiveLabel>
              <ActiveDot /> Current View
            </ActiveLabel>
          )}
          <span>Last Modified Date/Time</span>
          <span>
            {dateLabel} / {timeLabel}
          </span>
        </Box>
      </Box>
      <IconButton size="small" onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={handleDeleteView}>
          <TrashIcon /> Delete
        </MenuItem>
      </Menu>
    </Item>
  );
}

import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import { Box, Typography } from '@mui/material';

export function FatalError() {
  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 1,
      }}
    >
      <BrokenImageIcon sx={{ fontSize: '150px' }} />
      <Typography variant="h1">Something went wrong...</Typography>
      <Typography variant="body1">
        Please, try to reload the page. We will try to resolve this as soon as
        possible
      </Typography>
    </Box>
  );
}

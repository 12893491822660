import { baseApi, getErrorMessage } from '@/shared/api';
import type { SavedViewDTO, SavedViewStorage } from './types';

export const getSavedViews = async () => {
  const { data, error } = await baseApi
    .from('saved_view')
    .select('*')
    .order('updated_at', { ascending: false })
    .returns<SavedViewDTO[]>();

  if (!data) {
    const errorMessage = getErrorMessage(error);

    throw new Error(errorMessage);
  }

  return data;
};

export const deleteSavedView = async (uuid: string) => {
  const { error } = await baseApi.from('saved_view').delete().eq('uuid', uuid);

  if (error) {
    const errorMessage = getErrorMessage(error);

    throw new Error(errorMessage);
  }
};

export const createSavedView = async (
  storage: SavedViewStorage,
  name: string,
  snapshot: unknown,
) => {
  const { data, error } = await baseApi
    .from('saved_view')
    .insert([
      {
        saved_view_name: name,
        saved_view: {
          storage,
          snapshot,
        },
      },
    ])
    .select()
    .returns<[SavedViewDTO]>();

  if (!data) {
    const errorMessage = getErrorMessage(error);

    throw new Error(errorMessage);
  }

  return data[0];
};

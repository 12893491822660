import AddIcon from '@mui/icons-material/Add';
import { Menu, MenuItem } from '@mui/material';
import type { MouseEvent } from 'react';
import { useState } from 'react';
import type { Option } from '@/shared/types';
import { Button } from '@/shared/ui';

interface Props {
  disabled?: boolean;
  availableMetrics: Option[];
  onClick: (v: string) => void;
}

export function AddMetricColoring({
  disabled,
  availableMetrics,
  onClick,
}: Props) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleAddClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMetricSelect = (metric: string) => () => {
    onClick(metric);
    handleClose();
  };

  return (
    <>
      <Button
        sx={{
          alignSelf: 'flex-start',
          textTransform: 'none',
          py: 0.5,
          px: 2,
        }}
        variant="outlined"
        disabled={disabled}
        onClick={handleAddClick}
      >
        <AddIcon sx={{ mr: 1 }} />
        Add color
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {availableMetrics.map((metric) => {
          return (
            <MenuItem
              key={metric.value}
              onClick={handleMetricSelect(metric.value)}
            >
              {metric.name}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
}

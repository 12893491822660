import PaletteIcon from '@mui/icons-material/Palette';
import { Alert, Box, Drawer, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useMemo, useState } from 'react';
import type {
  ColumnsConfig,
  MetricDataFormatType,
} from '@/entities/columnsConfig';
import type { DataType } from '@/shared/lib';
import type { Option } from '@/shared/types';
import type { MinMaxData } from '@/shared/ui';
import { Button, DrawerHeader } from '@/shared/ui';
import type { MetricColoring } from '../../model/types';
import { AddMetricColoring } from '../AddMetricColoring/AddMetricColoring';
import { PRESET_COLORS } from '../ColorPicker/presetColors';
import { MetricColoringEditor } from '../MetricColoringEditor/MetricColoringEditor';

const MetricsColoringList = styled('ul')(({ theme }) => ({
  padding: 0,
  margin: 0,
  listStyle: 'none',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

interface Props {
  columnsConfig: ColumnsConfig;
  minMax?: MinMaxData;
  availableMetrics: string[];
  metricsColoring: MetricColoring[];
  onMetricsColoringChange: (v: MetricColoring[]) => void;
}

export function DataGridColoring({
  columnsConfig,
  minMax,
  availableMetrics,
  metricsColoring,
  onMetricsColoringChange,
}: Props) {
  const [open, setOpen] = useState(false);
  const metricsToAdd: Option[] = useMemo(() => {
    const activeMetricsColoring = new Set(
      metricsColoring.map((metricColoring) => {
        return metricColoring.metric;
      }),
    );

    return availableMetrics
      .filter((metric) => {
        return !activeMetricsColoring.has(metric);
      })
      .map((metric) => {
        return {
          name: columnsConfig[metric].name,
          value: metric,
        };
      });
  }, [availableMetrics, metricsColoring, columnsConfig]);
  const metricConfigMap = useMemo(() => {
    return Object.fromEntries(
      availableMetrics.map((metric) => {
        return [
          metric,
          {
            name: columnsConfig[metric].name,
            type: columnsConfig[metric].type as unknown as DataType,
            dataType: columnsConfig[metric].dataType as MetricDataFormatType,
          },
        ];
      }),
    );
  }, [availableMetrics, columnsConfig]);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleMetricColoringUpdate = (
    updatedMetricColoring: MetricColoring,
  ) => {
    onMetricsColoringChange(
      metricsColoring.map((metricColoring) => {
        if (metricColoring.metric === updatedMetricColoring.metric) {
          return updatedMetricColoring;
        }

        return metricColoring;
      }),
    );
  };
  const handleMetricColoringDelete = (metric: string) => {
    onMetricsColoringChange(
      metricsColoring.filter((metricColoring) => {
        return metricColoring.metric !== metric;
      }),
    );
  };
  const handleAddMetricColoring = (newMetric: string) => {
    const { min = 0, max = 1 } = minMax?.[newMetric] || {};

    onMetricsColoringChange([
      {
        metric: newMetric,
        levels: [
          { value: max - (max - min) / 2, color: PRESET_COLORS[1] },
          { value: max, color: PRESET_COLORS.at(-1)! },
        ],
      },
      ...metricsColoring,
    ]);
  };

  return (
    <>
      <Button
        color="secondary"
        size="small"
        variant="outlined"
        startIcon={<PaletteIcon />}
        sx={{ py: 0 }}
        onClick={handleOpen}
      >
        Add color
      </Button>
      <Drawer anchor="right" open={open} onClose={handleClose}>
        <DrawerHeader onClose={handleClose}>
          <Typography variant="h5">Color</Typography>
        </DrawerHeader>
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            width: 450,
            p: 4,
            overflowY: 'auto',
            maxHeight: 'calc(100vh - 128px)',
          }}
        >
          <Alert variant="outlined" severity="info">
            Adding color will remove min/max filling from the cell.
          </Alert>
          <AddMetricColoring
            disabled={metricsToAdd.length === 0}
            availableMetrics={metricsToAdd}
            onClick={handleAddMetricColoring}
          />
          <MetricsColoringList>
            {metricsColoring.map((metricColoring) => {
              return (
                <MetricColoringEditor
                  key={metricColoring.metric}
                  metricConfig={metricConfigMap[metricColoring.metric]}
                  min={minMax?.[metricColoring.metric].min}
                  max={minMax?.[metricColoring.metric].max}
                  value={metricColoring}
                  onChange={handleMetricColoringUpdate}
                  onDelete={handleMetricColoringDelete}
                />
              );
            })}
          </MetricsColoringList>
        </Box>
      </Drawer>
    </>
  );
}

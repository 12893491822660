import type { FocusEventHandler, ReactNode } from 'react';
import type { FormatType } from '@/shared/lib';
import { DataType } from '@/shared/lib';
import { getStep } from '../../model/getStep';
import { CurrencyInput } from '../CurrencyInput/CurrencyInput';
import { NumberInput } from '../NumberInput/NumberInput';
import { PercentageInput } from '../PercentageInput/PercentageInput';

interface Props {
  label?: ReactNode;
  disabled?: boolean;
  type: DataType;
  formatType: FormatType;
  min?: number;
  max?: number;
  value: number;
  onChange?: (v: number) => void;
  onBlur?: () => void;
}

export function TypedNumberInput({
  disabled,
  label,
  type,
  formatType,
  min,
  max,
  value,
  onChange,
  onBlur,
}: Props) {
  const step = getStep(formatType);

  const handleBlur: FocusEventHandler<HTMLInputElement> = (event) => {
    const inputValue = +event.target.value;
    const clampedValue =
      min != null && max != null ? Math.max(min, Math.min(value, max)) : value;

    if (inputValue !== clampedValue) {
      onChange?.(clampedValue);
    }

    onBlur?.();
  };

  if (type === DataType.CURRENCY) {
    return (
      <CurrencyInput
        disabled={disabled}
        label={label}
        step={step}
        min={min}
        max={max}
        value={value}
        onChange={onChange}
        onBlur={handleBlur}
      />
    );
  }

  if (type === DataType.PERCENTAGE) {
    return (
      <PercentageInput
        disabled={disabled}
        label={label}
        step={step}
        min={min}
        max={max}
        value={value}
        onChange={onChange}
        onBlur={handleBlur}
      />
    );
  }

  if (type === DataType.NUMBER) {
    return (
      <NumberInput
        disabled={disabled}
        label={label}
        step={step}
        min={min}
        max={max}
        value={value}
        onChange={onChange}
        onBlur={handleBlur}
      />
    );
  }

  return <div>Unsupported type: {type}</div>;
}

import { styled } from '@mui/material/styles';

interface Props {
  value: number;
  min?: number;
  max?: number;
}

export const ProgressCell = styled('div')<Props>(
  ({ theme, min, max, value }) => ({
    position: 'relative',
    padding: `0 ${theme.spacing(1.25)}`,

    ...(min != null &&
      max != null && {
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0,
          width: `${((value - min!) / (max! - min!)) * 100}%`,
          backgroundColor: '#F2F7FD',
          zIndex: -1,
        },
      }),
  }),
);

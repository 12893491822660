import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Box, Tab as MUITab } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Suspense, lazy, useState } from 'react';
import { Analytics } from '@/shared/lib';
import type { DateRange } from '@/shared/types';
import { Loader, Button as SharedButton } from '@/shared/ui';
import { RelativePicker } from '../RelativeRangePicker/RelativeRangePicker';
import type { ReasonKeys } from './types';
import { Reason } from './types';

const RangePicker = lazy(() => import('../RangePicker/RangePicker'));

const Tab = styled(MUITab)(({ theme }) => ({
  minHeight: 0,
  padding: `${theme.spacing(1.25)} ${theme.spacing(2)}`,
  textTransform: 'none',
}));
const Button = styled(SharedButton)(({ theme }) => ({
  width: 80,
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
  lineHeight: '22px',
}));

const PickerTab = {
  fixed: 'fixed',
  relative: 'relative',
} as const;
type PickerTabKeys = (typeof PickerTab)[keyof typeof PickerTab];

interface Props {
  withFuture?: boolean;
  value: DateRange;
  onChange: (v: DateRange, r: ReasonKeys) => void;
}

export function CustomDateRangeSelect({ withFuture, value, onChange }: Props) {
  const [internalValue, setInternalValue] = useState(value);
  const [tab, setTab] = useState<PickerTabKeys>(PickerTab.fixed);

  const handleTabChange = (
    _event: React.SyntheticEvent,
    newTab: PickerTabKeys,
  ) => {
    setTab(newTab);
  };
  const handleFixedCalendarChange = (newValue: DateRange) => {
    setInternalValue(newValue);
    Analytics.sendFeatureUsage('date_range_filter', 'change', {
      type: 'preset-absolute',
      page: window.location.pathname,
    });
  };
  const handleRelativeCalendarChange = (newValue: DateRange) => {
    setInternalValue(newValue);
    Analytics.sendFeatureUsage('date_range_filter', 'change', {
      type: 'preset-relative',
      page: window.location.pathname,
    });
  };
  const handleApply = () => {
    onChange(internalValue, Reason.apply);
  };
  const handleCancel = () => {
    onChange(internalValue, Reason.cancel);
  };

  return (
    <Box display="flex" flexDirection="column">
      <Box flexGrow={1} display="flex" flexDirection="column" gap={2} p={3}>
        <TabContext value={tab}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList sx={{ minHeight: 0 }} onChange={handleTabChange}>
              <Tab label="Calendar" value={PickerTab.fixed} />
              <Tab label="Relative" value={PickerTab.relative} />
            </TabList>
          </Box>
          <TabPanel sx={{ p: 0 }} value={PickerTab.fixed}>
            <Suspense
              fallback={
                <Box sx={{ position: 'relative', width: 625, height: 400 }}>
                  <Loader active />
                </Box>
              }
            >
              <RangePicker
                withFuture={withFuture}
                value={value}
                onChange={handleFixedCalendarChange}
              />
            </Suspense>
          </TabPanel>
          <TabPanel sx={{ p: 0 }} value={PickerTab.relative}>
            <RelativePicker onChange={handleRelativeCalendarChange} />
          </TabPanel>
        </TabContext>
      </Box>
      <Box px={3} pb={2.25} display="flex" justifyContent="flex-end" gap={2}>
        <Button variant="outlined" color="secondary" onClick={handleCancel}>
          Cancel
        </Button>
        <Button onClick={handleApply}>Apply</Button>
      </Box>
    </Box>
  );
}

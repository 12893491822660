import ShrinkIcon from '@mui/icons-material/KeyboardDoubleArrowLeftRounded';
import ExpandIcon from '@mui/icons-material/KeyboardDoubleArrowRightRounded';
import {
  Box,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography,
} from '@mui/material';
import type { ChangeEventHandler } from 'react';
import { useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import { SearchIcon } from '@/shared/ui';
import {
  selectChartExcludedSplitOptions,
  toggleExcludedSplitOption,
} from '../../model/slice';
import { SplitLegendList, SplitLegendMediaList } from '../SplitLegendList';

interface Props {
  name: string;
  options: string[];
  isMediaContent: boolean;
}

export function SplitLegend({ name, options, isMediaContent }: Props) {
  const dispatch = useAppDispatch();
  const excludedSplitOptions = useAppSelector(selectChartExcludedSplitOptions);
  const [filterQuery, setFilterQuery] = useState('');
  const [expanded, setExpanded] = useState(true);
  const excludedOptionsSet = useMemo(() => {
    return new Set(excludedSplitOptions);
  }, [excludedSplitOptions]);

  const handleFilterQueryChange: ChangeEventHandler<HTMLInputElement> = (
    event,
  ) => {
    setFilterQuery(event.target.value);
  };
  const handleOptionToggle = (option: string) => {
    dispatch(toggleExcludedSplitOption(option));
  };
  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  let ListComponent = SplitLegendList;

  if (isMediaContent) {
    ListComponent = SplitLegendMediaList;
  }

  return (
    <Box
      sx={{
        flexShrink: 0,
        width: expanded ? 300 : 50,
        py: 3,
        px: expanded ? 3 : 1,
        border: '1px solid',
        borderColor: (theme) => theme.palette.grey[300],
        borderRadius: 1,
        backgroundColor: '#F7F9FC',
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: 2,
        }}
      >
        {expanded && <Typography variant="h6">{name}</Typography>}
        <IconButton
          sx={{
            p: expanded ? 1 : 0.5,
            borderRadius: 1,
            backgroundColor: 'white',
          }}
          onClick={toggleExpanded}
        >
          {expanded ? <ShrinkIcon /> : <ExpandIcon />}
        </IconButton>
      </Box>
      {expanded && (
        <OutlinedInput
          sx={{
            fontSize: '0.75rem',
            backgroundColor: 'white',
          }}
          type="search"
          size="small"
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
          placeholder="Search"
          fullWidth
          value={filterQuery}
          onChange={handleFilterQueryChange}
        />
      )}
      <Box sx={{ flexGrow: 1, position: 'relative' }}>
        <ListComponent
          shrinked={!expanded}
          filterQuery={filterQuery}
          options={options}
          inactiveOptionsSet={excludedOptionsSet}
          onOptionToggle={handleOptionToggle}
        />
      </Box>
    </Box>
  );
}

import { useEffect, useState } from 'react';
import type { Column } from '../../model/types';
import { ActionsPanel } from '../ActionsPanel/ActionsPanel';
import { ColumnsList } from '../ColumnsList/ColumnsList';
import { SearchPanel } from '../SearchPanel/SearchPanel';

interface Props {
  columns: Column[];
  selectedColumns: string[];
  onApply: () => void;
  onSelectedColumnsChange: (v: string[]) => void;
}

export function ColumnsPanel({
  columns,
  selectedColumns,
  onApply,
  onSelectedColumnsChange,
}: Props) {
  const [filter, setFilter] = useState('');
  const [filteredColumns, setFilteredColumns] = useState(columns);

  useEffect(() => {
    const preparedFilter = filter.toLowerCase();

    setFilteredColumns(
      columns.filter((column) => {
        return column.name.toLowerCase().includes(preparedFilter);
      }),
    );
  }, [filter, columns]);

  const handleClearAll = () => {
    onSelectedColumnsChange([]);
  };

  return (
    <div>
      <SearchPanel value={filter} onChange={setFilter} />
      <ColumnsList
        columns={filteredColumns}
        selectedColumns={selectedColumns}
        onSelectedColumnsChange={onSelectedColumnsChange}
      />
      <ActionsPanel
        clearAllDisabled={selectedColumns.length === 0}
        onApply={onApply}
        onClearAll={handleClearAll}
      />
    </div>
  );
}

import SearchIcon from '@mui/icons-material/Search';
import type { ListItemButtonProps } from '@mui/material';
import {
  Box,
  InputAdornment,
  ListItem,
  ListItemButton as MUIListItemButton,
  OutlinedInput,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import type { ChangeEventHandler, MouseEvent, ReactNode } from 'react';
import { useEffect } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List } from 'react-window';
import { isFinished } from '@/shared/lib/dataFetching';
import { Checkbox } from '@/shared/ui';
import type {
  MediaFilterOption,
  MediaFilterParams,
  MediaFilterValue,
} from '../../model/types';
import { useFilterSelectionControlsContext } from '../FilterSelectionControlsContext/FilterSelectionControlsContext';
import { useFiltersParamsContext } from '../FiltersParamsContext/FiltersParamsContext';
import { useShiftSelected } from '../useShiftSelect/useShiftSelect';

const ListItemButton = styled((props: ListItemButtonProps) => {
  return (
    <MUIListItemButton component="label" {...props}>
      <Checkbox
        sx={{ '&.Mui-disabled': { color: 'var(--neutral-600)' } }}
        disabled
        checked={props.selected}
        tabIndex={-1}
        disableRipple
      />
      {props.children}
    </MUIListItemButton>
  );
})(({ theme }) => ({
  height: 40,
  display: 'flex',
  gap: theme.spacing(1.25),
  lineHeight: '22px',
  fontSize: '14px',
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  color: 'var(--neutral-700)',
  marginBottom: theme.spacing(0.75),

  '&.Mui-selected': {
    backgroundColor: 'var(--accent-100)',
    fontWeight: 500,
    color: theme.palette.text.primary,
  },

  '&:hover, &:focus-visible': {
    backgroundColor: 'var(--accent-200)',

    '& .MuiCheckbox-root': {
      color: theme.palette.primary.light,
    },
  },
}));
const Image = styled('img')(() => ({
  height: '100%',
  aspectRatio: '1 / 1',
  objectFit: 'contain',
}));

interface Props {
  id: string;
  value?: MediaFilterValue;
  onChange: (v: MediaFilterValue) => void;
}

export function MediaFilter({ id, value, onChange }: Props) {
  const { filteredOptions, filterQuery, setFilterQuery } =
    useFilterSelectionControlsContext();
  const { paramsData, requestMediaFilterParams } = useFiltersParamsContext();
  const onShiftChange = useShiftSelected({
    options: filteredOptions as MediaFilterOption[],
    changeHandler: (addOrRemove, options) => {
      if (addOrRemove) {
        onChange([...new Set([...(value || []), ...options])]);
      } else {
        onChange([...new Set(value || []).difference(new Set(options))]);
      }
    },
    getOptionValue: (v) => v.value,
  });

  useEffect(() => {
    requestMediaFilterParams(id);
  }, []);

  useEffect(() => {
    if (isFinished(paramsData[id]?.status) && (!value || value.length === 0)) {
      onChange(
        (paramsData[id].params as MediaFilterParams).options.map((option) => {
          return option.value;
        }),
      );
    }
  }, [paramsData[id]]);

  const handleFilterQueryChange: ChangeEventHandler<HTMLInputElement> = (
    event,
  ) => {
    setFilterQuery(event.target.value);
  };
  const handleOptionToggle =
    (option: string, checked: boolean) => (event: MouseEvent) => {
      onShiftChange(option, checked, event.shiftKey);
    };

  let content: ReactNode = (
    <Typography
      sx={{
        py: 2,
      }}
      color="secondary"
      textAlign="center"
      variant="modalContent"
    >
      No values found
    </Typography>
  );

  if (filteredOptions.length) {
    const ITEM_HEIGHT = 46;

    content = (
      <Box
        sx={{
          maxHeight: 'min(500px, 40vh)',
          height: ITEM_HEIGHT * filteredOptions.length,
        }}
      >
        <AutoSizer>
          {({ height, width }) => (
            <List
              height={height}
              width={width}
              itemCount={filteredOptions.length}
              itemSize={ITEM_HEIGHT}
            >
              {({ style, index }) => {
                const option = filteredOptions[index] as MediaFilterOption;
                const checked = (value || []).includes(option.value);

                return (
                  <ListItem key={option.value} disablePadding style={style}>
                    <ListItemButton
                      selected={checked}
                      onClick={handleOptionToggle(option.value, !checked)}
                    >
                      {option.url && <Image src={option.url} />} {option.value}
                    </ListItemButton>
                  </ListItem>
                );
              }}
            </List>
          )}
        </AutoSizer>
      </Box>
    );
  }

  return (
    <div>
      <Box mb={2}>
        <OutlinedInput
          sx={{
            width: '100%',
            backgroundColor: 'white',
          }}
          type="search"
          size="small"
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
          placeholder="Search"
          value={filterQuery}
          onChange={handleFilterQueryChange}
        />
      </Box>
      {content}
    </div>
  );
}

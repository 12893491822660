import type { CardProps } from '@mui/material';
import { Box, Card, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';

const Wrapper = styled((props: CardProps) => {
  return <Card component="li" elevation={3} {...props} />;
})(({ theme }) => ({
  backgroundColor: 'white',
  borderRadius: 12,
  height: 200,
  width: 250,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
  cursor: 'pointer',
}));
const Title = styled('p')(() => ({
  fontSize: '1.25rem',
  fontWeight: 500,
  lineHeight: 1.4,
  margin: 0,
}));
const Icon = styled('img')(({ theme }) => ({
  height: 70,
  width: 160,
  padding: theme.spacing(1),
  backgroundColor: '#3B3B3C',
  borderRadius: theme.shape.borderRadius,
  objectFit: 'contain',
  objectPosition: 'middle top',
}));
const Description = styled('p')(() => ({
  margin: 0,
  fontSize: '0.75rem',
  lineHeight: 1.67,
  overflow: 'hidden',
  display: '-webkit-box',
  WebkitLineClamp: 3,
  WebkitBoxOrient: 'vertical',
}));

interface Props {
  title: string;
  iconUrl: string;
  description: string;
  onClick: () => void;
}

export function ChartTypeItem({ title, iconUrl, description, onClick }: Props) {
  return (
    <Wrapper onClick={onClick}>
      <Title>{title}</Title>
      <Icon src={iconUrl} alt={`${title} icon`} />
      <Box
        sx={{
          display: 'flex',
          gap: 1.5,
        }}
      >
        <Description sx={{ flexShrink: 0 }}>Description:</Description>
        <Tooltip title={description}>
          <Description>{description}</Description>
        </Tooltip>
      </Box>
    </Wrapper>
  );
}

import type { Filters } from './types';

export const getApplicableFilters = (
  filters: Filters,
  requestedFilterId: string,
): Filters => {
  const requestedFilterIndex = filters.findIndex((filter) => {
    return filter.id === requestedFilterId;
  });

  return requestedFilterIndex === -1
    ? filters
    : filters.slice(requestedFilterIndex + 1);
};

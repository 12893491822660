export enum DatasourceType {
  marketingSpend = 'marketingSpend',
  attribution = 'attribution',
  monetization = 'monetization',
}

export enum DatasourceStatus {
  inactive = 'inactive',
  active = 'active',
  error = 'error',
}

export interface Datasource {
  id: string;
  name: string;
  type: DatasourceType;
  iconUrl: string;
  status: DatasourceStatus;
}

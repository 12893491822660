import { Box } from '@mui/material';
import type { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

export function LabeledSwitcherWrapper({ children }: Props) {
  return (
    <Box sx={{ height: 40, display: 'flex', alignItems: 'center' }}>
      {children}
    </Box>
  );
}

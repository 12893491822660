import { isMediaFilter, isNumberFilter, isStringFilter } from './predicates';
import type {
  FilterParams,
  FilterType,
  FilterValue,
  MediaFilterParams,
  NumberFilterParams,
  StringFilterParams,
} from './types';

export const isAllValuesSelected = (
  type: FilterType,
  value?: FilterValue,
  params?: FilterParams,
): boolean => {
  if (!params || !value) {
    return false;
  }

  if (isStringFilter(type)) {
    return value.length === (params as StringFilterParams).options.length;
  } else if (isNumberFilter(type)) {
    const { max, min } = params as NumberFilterParams;

    return value[0] === min && value[1] === max;
  } else if (isMediaFilter(type)) {
    return value.length === (params as MediaFilterParams).options.length;
  }

  return false;
};

export interface State {
  unit: TimeUnitKeys;
  range: RangeTypeKeys;
  lastNCount: number;
}

export const TimeUnit = {
  day: 'day',
  week: 'week',
  month: 'month',
  quater: 'quater',
  year: 'year',
} as const;
export type TimeUnitKeys = (typeof TimeUnit)[keyof typeof TimeUnit];

export const RangeType = {
  previous: 'previous',
  current: 'current',
  lastN: 'lastN',
} as const;
export type RangeTypeKeys = (typeof RangeType)[keyof typeof RangeType];

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import type { ButtonProps, PaperProps, PopperProps } from '@mui/material';
import {
  Button as MUIButton,
  ClickAwayListener,
  Grow,
  Paper,
  Popper as MUIPopper,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import type { MouseEvent, ReactNode } from 'react';
import { useEffect, useRef, useState } from 'react';

import { Loader } from '@/shared/ui';

const Popper = styled(MUIPopper)(({ theme }) => ({
  zIndex: theme.zIndex.modal,
}));
const Button = styled(MUIButton)(() => ({
  padding: '9px 15px',
  lineHeight: '20px',
  textTransform: 'none',
  whiteSpace: 'nowrap',
}));

export interface DropdownProps {
  open?: boolean;
  onOpenChange?: (v: boolean) => void;
  active?: boolean;
  loading?: boolean;
  label: string;
  labelProps?: ButtonProps;
  popperProps?: Omit<PopperProps, 'open'>;
  paperProps?: PaperProps;
  children: ReactNode;
}

export function Dropdown({
  open,
  active,
  loading,
  label,
  labelProps,
  paperProps,
  popperProps,
  children,
  onOpenChange,
}: DropdownProps) {
  const [internalOpen, setInternalOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (open != null) {
      setInternalOpen(open);
    }
  }, [open]);

  const handleOpen = (event: MouseEvent<HTMLButtonElement>) => {
    labelProps?.onClick?.(event);
    onOpenChange?.(true);
    setInternalOpen(true);
  };
  const handleClose = () => {
    onOpenChange?.(false);
    setInternalOpen(false);
  };

  return (
    <>
      <Button
        ref={anchorRef}
        color={active ? 'primary' : 'secondary'}
        variant="outlined"
        endIcon={
          internalOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
        }
        {...(labelProps || {})}
        onClick={handleOpen}
      >
        {label}
      </Button>
      {internalOpen && (
        <Popper
          open
          disablePortal
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-end"
          transition
          {...popperProps}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: 'right top',
              }}
            >
              <Paper sx={{ mt: 0.5 }} {...paperProps}>
                <ClickAwayListener onClickAway={handleClose}>
                  <div>
                    {children}
                    {loading && <Loader active />}
                  </div>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      )}
    </>
  );
}

import { styled } from '@mui/material/styles';
import { Header } from '@/widgets/Header';
import { ProfileMenu } from '@/widgets/ProfileMenu';
import { Layout } from '@/shared/ui';
import { Marketplace } from '../Marketplace/Marketplace';

const Wrapper = styled('section')(() => ({
  position: 'relative',
  height: '100%',
  overflow: 'auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
const Background = styled('figure')(() => ({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  zIndex: -1,
  margin: 0,
  backgroundImage: 'url("/images/gridTemplate.png")',
  backgroundPosition: '96px 0, 0 0',
  backgroundSize: '192px, 192px',
}));

export function HomePage() {
  return (
    <Layout headerSlot={<Header rightContentSlot={<ProfileMenu />} />}>
      <Wrapper>
        <Background />
        <Marketplace />
      </Wrapper>
    </Layout>
  );
}

import SearchIcon from '@mui/icons-material/Search';
import { Box, OutlinedInput, InputAdornment } from '@mui/material';
import type { ChangeEventHandler } from 'react';
import { useState } from 'react';
import { Filters } from '../Filters/Filters';
import { useFiltersParamsContext } from '../FiltersParamsContext/FiltersParamsContext';

export function FiltersPanel() {
  const { configs } = useFiltersParamsContext();
  const [filterQuery, setFilterQuery] = useState('');

  const handleFilterQueryChange: ChangeEventHandler<HTMLInputElement> = (
    event,
  ) => {
    setFilterQuery(event.target.value);
  };

  const filteredConfigs = configs.filter((filter) => {
    return filter.name.toLowerCase().includes(filterQuery.toLowerCase());
  });

  return (
    <Box display="flex" flexDirection="column" gap={5}>
      <OutlinedInput
        type="search"
        size="small"
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        }
        placeholder="Search"
        value={filterQuery}
        onChange={handleFilterQueryChange}
      />
      <Filters filterConfigs={filteredConfigs} />
    </Box>
  );
}

export enum DataStatus {
  idle = 'idle',
  loading = 'loading',
  finished = 'finished',
  error = 'error',
}

export const isIdle = (status: DataStatus) => {
  return status === DataStatus.idle;
};

export const isLoading = (status: DataStatus) => {
  return status === DataStatus.loading;
};

export const isFinished = (status: DataStatus) => {
  return status === DataStatus.finished;
};

export const isError = (status: DataStatus) => {
  return status === DataStatus.error;
};

import { ThemeProvider } from '@emotion/react';
import { CssBaseline } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import { Outlet } from 'react-router-dom';
import { theme } from '@/app/theme';
import '@fontsource/roboto/400.css';

export function Baseline() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SnackbarProvider>
        <Outlet />
      </SnackbarProvider>
    </ThemeProvider>
  );
}

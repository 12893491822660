import { isAllValuesSelected } from './isAllValuesSelected';
import type { FilterConfig, Filters, FiltersParamsData } from './types';

interface Params {
  filters: Filters;
  paramsData: FiltersParamsData;
  configsMap: Record<string, FilterConfig>;
}

export const prepareUIFilters = ({
  filters,
  paramsData,
  configsMap,
}: Params): Filters => {
  const result = [];

  for (let filter of filters) {
    const { type } = configsMap[filter.id];

    if (
      !!paramsData[filter.id]?.params &&
      isAllValuesSelected(type, filter.value, paramsData[filter.id].params)
    ) {
      continue;
    }

    result.push(filter);
  }

  return result;
};

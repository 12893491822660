export const PRESET_COLORS = [
  'white',
  '#f4cccb',
  '#f7d6cc',
  '#f9dfcc',
  '#fef2cc',
  '#eceed0',
  '#e3ecd2',
  '#d9ead3',
];

export const validatePassword = (password: string): string => {
  let error = '';

  if (password.length < 8) {
    error = 'Password must be at least 8 characters long';
  }

  return error;
};

export const validateConfirmation = (
  password: string,
  confirmation: string,
): string => {
  let error = '';

  if (password !== confirmation) {
    error = 'Passwords do not match';
  }

  return error;
};

import { Box, Popover } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { Button } from '@/shared/ui';
import { PRESET_COLORS } from './presetColors';

const ColorPlate = styled(Box)(({ theme }) => ({
  width: 16,
  height: 16,
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: 2,
}));

interface Props {
  value: string;
  onChange: (v: string) => void;
}

export function ColorPicker({ value, onChange }: Props) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChange = (color: string) => () => {
    onChange(color);
  };

  return (
    <div>
      <Button color="secondary" variant="outlined" onClick={handleClick}>
        <ColorPlate sx={{ backgroundColor: value }} />
      </Button>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box
          sx={{
            p: 2,
            display: 'grid',
            gridTemplateColumns: 'repeat(4, 1fr)',
            gap: 2,
          }}
        >
          {PRESET_COLORS.map((color) => {
            return (
              <Button
                key={color}
                sx={{ p: 0, minWidth: 0 }}
                onClick={handleChange(color)}
              >
                <ColorPlate sx={{ backgroundColor: color }} />
              </Button>
            );
          })}
        </Box>
      </Popover>
    </div>
  );
}

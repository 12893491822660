import { DimensionColumnDataType } from '@/entities/columnsConfig';
import type { DownloadData } from './types';

interface Column {
  id: string;
  title: string;
}

interface DimensionColumn extends Column {
  type: DimensionColumnDataType;
}

interface Params {
  dimensions: DimensionColumn[];
  metrics: Column[];
  downloadData: DownloadData;
}

export const generateCSVContent = ({
  dimensions,
  metrics,
  downloadData,
}: Params): string => {
  const result = [
    getHeaderRow(dimensions, metrics),
    getTotalRow(dimensions, metrics, downloadData.total),
    ...getDataRows(dimensions, metrics, downloadData.rows),
  ];

  return result.join('\n');
};

const getHeaderRow = (dimensions: Column[], metrics: Column[]): string => {
  return [
    ...dimensions.map((dimension) => {
      return dimension.title;
    }),
    ...metrics.map((metric) => {
      return metric.title;
    }),
  ].join(',');
};

const getTotalRow = (
  dimensions: Column[],
  metrics: Column[],
  totalRow: DownloadData['total'],
): string => {
  const result = Array(dimensions.length).fill('TOTAL');

  metrics.forEach((metric) => {
    result.push(totalRow[metric.id]);
  });

  return result.join(',');
};

const getDataRows = (
  dimensions: DimensionColumn[],
  metrics: Column[],
  rows: DownloadData['rows'],
): string[] => {
  return rows.map((row) => {
    return [
      ...dimensions.map((dimension) => {
        if (dimension.type === DimensionColumnDataType.MEDIA) {
          const { value } = JSON.parse(row[dimension.id] as string);

          return value;
        }

        return row[dimension.id];
      }),
      ...metrics.map((metric) => {
        return row[metric.id];
      }),
    ].join(',');
  });
};

import type { DataConfig } from '@/widgets/calendarInsights';
import {
  dataConfigValidator,
  selectMetaIsLoaded,
  updateConfig,
} from '@/widgets/calendarInsights';
import { ImportSharedView } from '@/features/ShareView';
import { ROUTES } from '@/shared/constants';
import { Analytics } from '@/shared/lib';
import { useAppDispatch, useAppSelector } from '@/shared/model';

export function ImportSharedViewDialog() {
  const dispatch = useAppDispatch();
  const isColumnsMetaLoaded = useAppSelector(selectMetaIsLoaded);

  const handleApplyView = (view: unknown) => {
    dispatch(updateConfig(view as DataConfig));
    Analytics.sendFeatureUsage('share_view', 'apply', {
      page: ROUTES.calendarInsights,
    });
  };
  const isViewValid = (view: unknown) => {
    const parseResult = dataConfigValidator.safeParse(view);

    return parseResult.success;
  };

  if (!isColumnsMetaLoaded) {
    return null;
  }

  return (
    <ImportSharedView isViewValid={isViewValid} applyView={handleApplyView} />
  );
}

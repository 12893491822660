import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { ChangeEvent } from 'react';
import { Switch } from '@/shared/ui';

const Wrapper = styled('label')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  cursor: 'pointer',
  userSelect: 'none',
}));

interface Props {
  disabled?: boolean;
  label: string;
  value: boolean;
  onChange: (newValue: boolean) => void;
}

export function LabeledSwitcher({ disabled, label, value, onChange }: Props) {
  const handleChange = (
    _event: ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    onChange(checked);
  };

  return (
    <Wrapper>
      <Switch disabled={disabled} checked={value} onChange={handleChange} />
      <Typography
        variant="modalContent"
        component="span"
        sx={{ color: value ? 'text.primary' : 'var(--neutral-700)' }}
      >
        {label}
      </Typography>
    </Wrapper>
  );
}

import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useAppDispatch } from '@/shared/model';
import { updateChartType } from '../../model/slice';
import { ChartType } from '../../model/types';
import { ChartTypeItem } from '../ChartTypeItem/ChartTypeItem';
import CalendarChartIcon from './calendarChart.png';
import CohortChartIcon from './cohortChart.png';

const Wrapper = styled('ul')(({ theme }) => ({
  margin: 0,
  padding: 0,
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(5),
}));

export function NoType() {
  const dispatch = useAppDispatch();

  const handleTypeChange = (type: ChartType) => () => {
    dispatch(updateChartType(type));
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
      }}
    >
      <Typography>Please, select a chart type below</Typography>
      <Wrapper>
        <ChartTypeItem
          title="Cohort chart"
          description="Analyzes customer behavior and cohort performance over time"
          iconUrl={CohortChartIcon}
          onClick={handleTypeChange(ChartType.cohort)}
        />
        <ChartTypeItem
          title="Calendar chart"
          description="Visualizes data over calendar dates, useful for spotting trends and patterns over time"
          iconUrl={CalendarChartIcon}
          onClick={handleTypeChange(ChartType.calendar)}
        />
      </Wrapper>
    </Box>
  );
}

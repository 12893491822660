import { z } from 'zod';

export interface DateRange {
  from: Date;
  to: Date;
}

export type FilterDateRangeDTO = [string, string];

export type SerializedDateRange = Record<keyof DateRange, string>;

export enum DateRangePresets {
  ALL_TIME = 'allTime',
  TODAY = 'today',
  YESTERDAY = 'yesterday',
  LAST_7_DAYS = 'last7days',
  LAST_14_DAYS = 'last14days',
  LAST_30_DAYS = 'last30days',
  LAST_60_DAYS = 'last60days',
  LAST_90_DAYS = 'last90days',
}

export const CustomDateRangePreset = 'custom' as const;
export type CustomDateRangePresetKey = typeof CustomDateRangePreset;

export const serializedDateRangeValueObjectValidator = z
  .object({
    preset: z.nativeEnum(DateRangePresets),
  })
  .or(
    z.object({
      preset: z.literal(CustomDateRangePreset),
      dateRange: z.object({
        from: z.string().date(),
        to: z.string().date(),
      }),
    }),
  );

export type SerializedDateRangeValueObject = z.infer<
  typeof serializedDateRangeValueObjectValidator
>;

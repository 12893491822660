import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { EmailInput, Page, Title } from '@/features/auth';
import { login, selectIsSessionLoading } from '@/entities/session';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import { Button, ErrorAlert, Loader } from '@/shared/ui';
import { PasswordInput } from '../PasswordInput/PasswordInput';
import { RememberLogin } from '../RememberLogin/RememberLogin';

const Form = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

export function SignInPage() {
  const dispatch = useAppDispatch();
  const isSessionLoading = useAppSelector(selectIsSessionLoading);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formElement = event.currentTarget;

    if (!formElement.checkValidity()) {
      return;
    }

    const formData = new FormData(formElement);

    setErrorMessage('');
    dispatch(
      login({
        credentials: {
          email: formData.get('email') as string,
          password: formData.get('password') as string,
        },
        shouldRemember: !!formData.get('rememberLogin'),
      }),
    )
      .unwrap()
      .catch((error: Error) => {
        setErrorMessage(error.message);
      });
  };
  const handleErrorClose = () => {
    setErrorMessage('');
  };

  return (
    <Page>
      <Title>Sign In</Title>
      <ErrorAlert onClose={handleErrorClose}>{errorMessage}</ErrorAlert>
      <Form onSubmit={handleSubmit}>
        <EmailInput />
        <PasswordInput />
        <RememberLogin />
        <Button sx={{ mt: 1 }} type="submit">
          Sign In
        </Button>
      </Form>
      <Loader active={isSessionLoading} />
    </Page>
  );
}

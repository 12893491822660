import { styled } from '@mui/material/styles';
import { Button as SharedButton } from '@/shared/ui';

const Button = styled(SharedButton)(({ theme }) => ({
  padding: theme.spacing(1),
  fontWeight: 400,
  whiteSpace: 'nowrap',

  '&:hover': {
    textDecoration: 'underline',
  },
}));

interface Props {
  hasFilters: boolean;
  onClick: () => void;
}

export function ResetAllButton({ hasFilters, onClick }: Props) {
  if (!hasFilters) {
    return null;
  }

  return (
    <Button variant="text" onClick={onClick}>
      Reset all
    </Button>
  );
}

import { Box, styled } from '@mui/material';
import type { ReactNode } from 'react';
import { Loader } from '@/shared/ui';
import { ErrorIcon } from '../Icons/ErrorIcon';
import { InfoIcon } from '../Icons/InfoIcon';

const Panel = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: `${theme.spacing(5)} ${theme.spacing(3)}`,
  borderRadius: theme.spacing(1),
  backgroundColor: '#404040',
  color: 'white',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1.5),
  alignItems: 'center',
  textAlign: 'center',
  width: 300,
}));

interface Props {
  noData: boolean;
  loading: boolean;
  error: boolean;
  children: ReactNode;
}

export function LineChartWrapper({ loading, noData, error, children }: Props) {
  if (loading) {
    return <Loader active />;
  }

  if (error) {
    return (
      <Panel>
        <ErrorIcon />
        Something happened and we could not retrieve any data. Please, try again
        later
      </Panel>
    );
  }

  if (noData) {
    return (
      <Panel>
        <InfoIcon />
        There is no data for selected axes and filters
      </Panel>
    );
  }

  return children;
}

import { FormatType } from '@/shared/lib';
import { MetricDataFormatType } from './types';

export const toFormatType = (type: MetricDataFormatType): FormatType => {
  switch (type) {
    case MetricDataFormatType.INTEGER:
      return FormatType.INTEGER;
    case MetricDataFormatType.NUMERIC:
      return FormatType.FLOAT;
    default:
      throw new Error(`Unexpected type: "${type}".`);
  }
};

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  styled,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { Button } from '@/shared/ui';
import { MetaIcon } from '../MetaIcon/MetaIcon';

const MetaButton = styled(Button)(() => ({
  backgroundColor: '#4267b2',

  '&:hover': {
    backgroundColor: '#36538f',
  },
})) as typeof Button;

export function MetaAdsDatasource() {
  return (
    <Box
      sx={{
        py: 3,
        px: 5,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: 2,
      }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Account</TableCell>
            <TableCell>Last sync time</TableCell>
            <TableCell>Status</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell colSpan={4} align="center">
              No accounts added
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <MetaButton
        startIcon={<MetaIcon />}
        component={Link}
        target="_blank"
        to="https://www.facebook.com/dialog/oauth?app_id=123456789012345&cbt=1625200000000&channel_url=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Df30dc421dc79b081d%26domain%3Dyourappdomain.com%26is_canvas%3Dfalse%26origin%3Dhttps%253A%252F%252Fyourappdomain.com%252Funiqueid%26relation%3Dopener&client_id=123456789012345&display=popup&domain=yourappdomain.com&e2e=%7B%7D&fallback_redirect_uri=https%3A%2F%2Fcampaignswell.com%2Foauth%2Ffallback&locale=en_US&logger_id=uniqueLoggerId12345&origin=1&redirect_uri=https%3A%2F%2Fyourappdomain.com%2Foauth%2Fcallback&response_type=token%2Csigned_request%2Cgraph_domain&scope=ads_read&sdk=joey"
      >
        Add account
      </MetaButton>
    </Box>
  );
}

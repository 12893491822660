import { styled } from '@mui/material/styles';
import '@fontsource/roboto/500.css';
import { ROUTES } from '@/shared/constants';
import type { Product } from '../ProductCard/ProductCard';
import { ProductCard } from '../ProductCard/ProductCard';
import CalendarInsightsIcon from './calendarInsights.svg';
import ChartsViewIcon from './chartsView.svg';
import CohortMarketingPerformanceIcon from './cohortMarketingPerformance.svg';
import CohortRetentionIcon from './cohortRetention.svg';
import CohortScannerIcon from './cohortScanner.svg';
import ROIBreakdownIcon from './roiBreakdown.png';

const Wrapper = styled('ul')(({ theme }) => ({
  padding: 0,
  margin: `0 auto ${theme.spacing(4)}`,
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gap: `${theme.spacing(5)} ${theme.spacing(10)}`,
  maxWidth: 1060,
  listStyleType: 'none',
}));

const getProducs = (): Product[] => {
  return [
    {
      name: 'Cohort Marketing Performance',
      description:
        'Unlock the power of your marketing strategies with our go-to visual tool for tracking and optimising campaigns of various media channels!',
      image: CohortMarketingPerformanceIcon,
      url: ROUTES.cohortMarketingPerformance,
    },
    {
      name: 'Calendar Insights',
      description:
        'Monitor key metrics with precision across calendar periods to streamline operations and boost efficiency!',
      image: CalendarInsightsIcon,
      url: ROUTES.calendarInsights,
    },
    {
      name: 'Charts',
      description:
        'Dive deep into metrics with our advanced charting tool for effective cohort analysis.',
      image: ChartsViewIcon,
      url: ROUTES.chartsView,
    },
    {
      name: 'Cohort Retention',
      description:
        'Visualize retention trends with a triangle chart for detailed cohort analysis.',
      image: CohortRetentionIcon,
    },
    {
      name: 'Cohort Scanner',
      description:
        'Discover patterns, trends, and anomalies in user acquisition with our heatmap visualisation of key metrics across multiple cohorts!',
      image: CohortScannerIcon,
    },
    {
      name: 'ROI Breakdown',
      description:
        'Unveil segment contributions to revenue, marketing spend and other core metrics using insightful area charts!',
      image: ROIBreakdownIcon,
    },
  ];
};

export function Marketplace() {
  return (
    <Wrapper>
      {getProducs().map((product) => {
        return <ProductCard key={product.name} product={product} />;
      })}
    </Wrapper>
  );
}

import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { changePassword } from '@/entities/session';
import { Button, ErrorAlert, Loader } from '@/shared/ui';
import { validateConfirmation, validatePassword } from '../../model/validation';
import { PasswordInput } from '../PasswordInput/PasswordInput';

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

interface Props {
  open: boolean;
  onClose: () => void;
}

export function ChangePasswordDialog({ open, onClose }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmation, setConfirmation] = useState('');
  const [confirmationError, setConfirmationError] = useState('');
  const [loading, setLoading] = useState(false);
  const [requestError, setRequestError] = useState('');

  const handlePasswordChange = (value: string) => {
    setPasswordError('');
    setPassword(value);
  };
  const handleConfirmationChange = (value: string) => {
    setConfirmationError('');
    setConfirmation(value);
  };
  const validatePasswordInput = (): boolean => {
    const error = validatePassword(password);

    if (error) {
      setPasswordError(error);
    }

    return !!error;
  };
  const validateConfirmationInput = (): boolean => {
    const error = validateConfirmation(password, confirmation);

    if (error) {
      setConfirmationError(error);
    }

    return !!error;
  };
  const handleErrorClose = () => {
    setRequestError('');
  };
  const handleChangePassword = () => {
    const passwordError = validatePasswordInput();
    const confirmationError = validateConfirmationInput();

    if (passwordError || confirmationError) {
      return;
    }

    setRequestError('');
    setLoading(true);
    changePassword(password)
      .then(() => {
        setPassword('');
        setConfirmation('');
        enqueueSnackbar('Password successfully changed.', {
          variant: 'success',
          autoHideDuration: 4000,
        });
        onClose();
      })
      .catch((error: Error) => {
        setRequestError(
          error.message || 'Something went wrong. Please, try again',
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Dialog
      PaperProps={{ sx: { maxWidth: '392px' } }}
      open={open}
      onClose={onClose}
    >
      <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
        <span>Change password</span>
        <IconButton sx={{ marginLeft: 'auto' }} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <ErrorAlert onClose={handleErrorClose}>{requestError}</ErrorAlert>
        <Typography sx={{ mb: 3 }} variant="modalContent">
          Enter and confirm new password. The password should be at least 8
          characters long
        </Typography>
        <Container>
          <PasswordInput
            label="New password"
            error={passwordError}
            value={password}
            onBlur={validatePasswordInput}
            onChange={handlePasswordChange}
          />
          <PasswordInput
            label="Confirm password"
            error={confirmationError}
            value={confirmation}
            onBlur={validateConfirmationInput}
            onChange={handleConfirmationChange}
          />
          <Button sx={{ mt: 3 }} onClick={handleChangePassword}>
            Change password
          </Button>
        </Container>
      </DialogContent>
      <Loader active={loading} />
    </Dialog>
  );
}

import { DataStatus } from '@/shared/lib/dataFetching';
import { createAppSlice } from '@/shared/model';
import * as SavedViewsAPI from '../api/savedViewsAPI';
import type { SavedViewStorage } from '../api/types';
import type { SavedViewRecord } from './types';

interface State {
  views: SavedViewRecord[];
  status: DataStatus;
}

const initialState: State = {
  views: [],
  status: DataStatus.idle,
};

export const savedViewsSlice = createAppSlice({
  name: 'savedViews',
  initialState,
  reducers: (create) => ({
    setupViews: create.asyncThunk(
      (_: void) => {
        return SavedViewsAPI.getSavedViews();
      },
      {
        pending: (state) => {
          state.status = DataStatus.loading;
        },
        fulfilled: (state, action) => {
          state.views = action.payload;
          state.status = DataStatus.finished;
        },
        rejected: (state) => {
          state.status = DataStatus.error;
        },
      },
    ),
    createView: create.asyncThunk<
      {
        storage: SavedViewStorage;
        name: string;
        snapshot: unknown;
      },
      SavedViewRecord
    >(
      ({ storage, name, snapshot }) => {
        return SavedViewsAPI.createSavedView(storage, name, snapshot);
      },
      {
        fulfilled: (state, action) => {
          state.views.unshift(action.payload);
        },
      },
    ),
    deleteView: create.asyncThunk(
      (uuid: string) => {
        return SavedViewsAPI.deleteSavedView(uuid);
      },
      {
        fulfilled: (state, action) => {
          state.views = state.views.filter((view) => {
            return view.uuid !== action.meta.arg;
          });
        },
      },
    ),
  }),
  selectors: {
    selectViews: (state) => {
      return state.views;
    },
    selectIsLoading: (state) => {
      return state.status === DataStatus.loading;
    },
    selectIsFailed: (state) => {
      return state.status === DataStatus.error;
    },
  },
});

export const { setupViews, createView, deleteView } = savedViewsSlice.actions;
export const { selectIsFailed, selectIsLoading, selectViews } =
  savedViewsSlice.selectors;

import { styled } from '@mui/material/styles';

const Container = styled('span')(({ theme }) => ({
  fontSize: '10px',
  color: theme.palette.error.main,
}));

export function RequiredAsterisk() {
  return <Container>*</Container>;
}

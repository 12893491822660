export enum SharedViewType {
  cohortMarketingPerformance = 'cohortMarketingPerformance',
  calendarInsights = 'calendarInsights',
  chartsView = 'chartsView',
}

export interface SharedView {
  type: SharedViewType;
  snapshot: unknown;
}

import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import { TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { RequiredAsterisk } from '@/shared/ui';
import '@fontsource/roboto/500.css';

const Wrapper = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(0.5),
}));
const LabelWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(0.25),
}));
const Input = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    marginBottom: 24,

    '&.Mui-error': {
      marginBottom: 0,
    },
  },

  '& .MuiInputBase-input': {
    padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
    fontSize: '14px',
  },

  '& .MuiFormHelperText-root': {
    margin: `${theme.spacing(0.5)} 0 0`,
  },
}));

export function EmailInput() {
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = () => {
    setErrorMessage('');
  };
  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (!event.target.validity.valid) {
      setErrorMessage('Please, enter a valid email.');
    }
  };
  const hasError = !!errorMessage;

  return (
    <Wrapper>
      <LabelWrapper>
        <RequiredAsterisk />
        <Typography variant="label" component="label" htmlFor="emailInput">
          Email
        </Typography>
      </LabelWrapper>
      <Input
        id="emailInput"
        required
        type="email"
        name="email"
        autoComplete="email"
        InputProps={{
          endAdornment: hasError ? (
            <ErrorOutlineRoundedIcon color="error" />
          ) : null,
        }}
        error={hasError}
        helperText={errorMessage}
        onChange={handleChange}
        onBlur={handleBlur}
      />
    </Wrapper>
  );
}

import { Box, Typography } from '@mui/material';
import { useEffect } from 'react';
import type { DataType, FormatType } from '@/shared/lib';
import { formattersMap } from '@/shared/lib';
import { isFinished } from '@/shared/lib/dataFetching';
import { getStep, TypedNumberInput } from '@/shared/ui';
import type { NumberFilterParams, NumberFilterValue } from '../../model/types';
import { useFiltersParamsContext } from '../FiltersParamsContext/FiltersParamsContext';
import { Slider } from '../Slider/Slider';

export const DEFAULT_PARAMS = {
  min: 0,
  max: 1,
};

interface Props {
  id: string;
  type: DataType;
  formatType: FormatType;
  value?: NumberFilterValue;
  onChange: (v: NumberFilterValue) => void;
}

export function NumberFilter({ id, type, formatType, value, onChange }: Props) {
  const { paramsData, requestNumberFilterParams } = useFiltersParamsContext();
  const filterParamsLoadable = paramsData[id];
  const params = isFinished(filterParamsLoadable?.status)
    ? (filterParamsLoadable.params as NumberFilterParams)
    : DEFAULT_PARAMS;
  const DEFAULT_VALUE: NumberFilterValue = [
    params.min ?? DEFAULT_PARAMS.min,
    params.max ?? DEFAULT_PARAMS.max,
  ];
  const valueOrDefault = value || DEFAULT_VALUE;

  useEffect(() => {
    requestNumberFilterParams(id);
  }, []);

  if (!filterParamsLoadable) {
    return null;
  }

  const handleFromChange = (newFrom: number) => {
    if (newFrom === valueOrDefault[0]) {
      return;
    }

    onChange([newFrom, valueOrDefault[1]]);
  };
  const handleToChange = (newTo: number) => {
    if (newTo === valueOrDefault[1]) {
      return;
    }

    onChange([valueOrDefault[0], newTo]);
  };
  const handleRangeChange = (newValue: [number, number]) => {
    onChange(newValue);
  };

  const formatter = formattersMap[type][formatType];
  const step = getStep(formatType);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 0.5,
        position: 'relative',
      }}
    >
      <Box display="flex" gap={5}>
        <Box
          sx={{ display: 'flex', gap: 2, alignItems: 'center', width: '50%' }}
        >
          <Typography color="secondary" variant="subtitle2">
            From
          </Typography>
          <TypedNumberInput
            type={type}
            formatType={formatType}
            min={params.min}
            max={params.max}
            value={valueOrDefault[0]}
            onChange={handleFromChange}
          />
        </Box>
        <Box
          sx={{ display: 'flex', gap: 2, alignItems: 'center', width: '50%' }}
        >
          <Typography color="secondary" variant="subtitle2">
            To
          </Typography>
          <TypedNumberInput
            type={type}
            formatType={formatType}
            min={params.min}
            max={params.max}
            value={valueOrDefault[1]}
            onChange={handleToChange}
          />
        </Box>
      </Box>
      <Slider
        min={params.min}
        max={params.max}
        step={step}
        formatter={formatter}
        value={valueOrDefault}
        onChange={handleRangeChange}
      />
    </Box>
  );
}

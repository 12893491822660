import type { SignInWithPasswordCredentials } from '@supabase/supabase-js';
import { baseApi } from '@/shared/api';

export const getUserData = async () => {
  const { data: sessionData, error: sessionError } =
    await baseApi.auth.getSession();

  if (sessionError) {
    throw sessionError;
  }

  if (
    sessionData.session &&
    getRememberMe(sessionData.session.user.email || '')
  ) {
    return sessionData.session.user;
  }

  const { data: userData, error: userError } = await baseApi.auth.getUser();

  if (userError) {
    throw userError;
  }

  return userData.user;
};

export const login = async (credentials: SignInWithPasswordCredentials) => {
  const { data, error } = await baseApi.auth.signInWithPassword(credentials);

  if (error) {
    throw new Error(error.message);
  }

  return data.user;
};

export const logout = async () => {
  const { error } = await baseApi.auth.signOut();

  if (error) {
    throw error;
  }
};

export const changePassword = async (newPassword: string) => {
  const { error } = await baseApi.auth.updateUser({
    password: newPassword,
  });

  if (error) {
    throw error;
  }
};

export const requestResetPassword = async (email: string) => {
  const { error } = await baseApi.auth.resetPasswordForEmail(email);

  if (error) {
    throw error;
  }
};

const REMEMBER_ME_KEY = 'cw-remember-me';

export const getRememberMe = (email: string) => {
  const item = localStorage.getItem(REMEMBER_ME_KEY);

  return item === email;
};

export const updateRememberMe = (email: string) => {
  localStorage.setItem(REMEMBER_ME_KEY, email);
};

export const deleteRememberMe = () => {
  localStorage.removeItem(REMEMBER_ME_KEY);
};

import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export function MetaIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 32 32">
        <path d="M16,0C7.2,0,0,7.2,0,16.1c0,8,5.9,14.7,13.5,15.9V20.7H9.4v-4.6h4.1l0-3.5c0-4,2.4-6.3,6-6.3  c1.8,0,3.6,0.3,3.6,0.3v3.9h-2c-2,0-2.6,1.3-2.6,2.5l0,3h4.4l-0.7,4.6h-3.7V32C26.1,30.8,32,24.1,32,16.1C32,7.2,24.8,0,16,0z" />
      </svg>
    </SvgIcon>
  );
}

import {
  endOfToday,
  startOfToday,
  subDays,
  startOfWeek,
  endOfWeek,
  subWeeks,
  startOfMonth,
  subMonths,
  startOfDay,
  endOfDay,
  startOfQuarter,
  endOfQuarter,
  endOfMonth,
  subQuarters,
  startOfYear,
  endOfYear,
  subYears,
} from 'date-fns';
import type { DateRange } from '@/shared/types';
import type { RangeTypeKeys, State } from './types';
import { TimeUnit, RangeType } from './types';

export function getRange(state: State): DateRange {
  switch (state.unit) {
    case TimeUnit.day:
      return getRangeValue(state.range, state.lastNCount, {
        currentFrom: (v) => v,
        currentTo: (v) => v,
        previousFrom: (v) => {
          return startOfDay(subDays(v, 1));
        },
        previousTo: (v) => {
          return endOfDay(subDays(v, 1));
        },
        lastNFrom: (v, n) => {
          return subDays(v, n);
        },
      });
    case TimeUnit.week:
      return getRangeValue(state.range, state.lastNCount, {
        currentFrom: (v) => {
          return startOfWeek(v);
        },
        currentTo: (v) => {
          return endOfWeek(v);
        },
        previousFrom: (v) => {
          return startOfWeek(subWeeks(v, 1));
        },
        previousTo: (v) => {
          return endOfWeek(subWeeks(v, 1));
        },
        lastNFrom: (v, n) => {
          return startOfWeek(subWeeks(v, n));
        },
      });
    case TimeUnit.month:
      return getRangeValue(state.range, state.lastNCount, {
        currentFrom: (v) => {
          return startOfMonth(v);
        },
        currentTo: (v) => {
          return endOfMonth(v);
        },
        previousFrom: (v) => {
          return startOfMonth(subMonths(v, 1));
        },
        previousTo: (v) => {
          return endOfMonth(subMonths(v, 1));
        },
        lastNFrom: (v, n) => {
          return startOfMonth(subMonths(v, n));
        },
      });
    case TimeUnit.quater:
      return getRangeValue(state.range, state.lastNCount, {
        currentFrom: (v) => {
          return startOfQuarter(v);
        },
        currentTo: (v) => {
          return endOfQuarter(v);
        },
        previousFrom: (v) => {
          return startOfQuarter(subQuarters(v, 1));
        },
        previousTo: (v) => {
          return endOfQuarter(subQuarters(v, 1));
        },
        lastNFrom: (v, n) => {
          return startOfQuarter(subQuarters(v, n));
        },
      });
    case TimeUnit.year:
      return getRangeValue(state.range, state.lastNCount, {
        currentFrom: (v) => {
          return startOfYear(v);
        },
        currentTo: (v) => {
          return endOfYear(v);
        },
        previousFrom: (v) => {
          return startOfYear(subYears(v, 1));
        },
        previousTo: (v) => {
          return endOfYear(subYears(v, 1));
        },
        lastNFrom: (v, n) => {
          return startOfYear(subYears(v, n));
        },
      });
    default:
      throw new Error(`Unexpected time unit: "${state.unit}".`);
  }
}

interface Processors {
  currentFrom: (v: Date) => Date;
  currentTo: (v: Date) => Date;
  previousFrom: (v: Date) => Date;
  previousTo: (v: Date) => Date;
  lastNFrom: (v: Date, n: number) => Date;
}

function getRangeValue(
  range: RangeTypeKeys,
  lastNCount: number,
  processors: Processors,
): DateRange {
  switch (range) {
    case RangeType.current:
      return {
        from: processors.currentFrom(startOfToday()),
        to: processors.currentTo(endOfToday()),
      };
    case RangeType.previous:
      return {
        from: processors.previousFrom(startOfToday()),
        to: processors.previousTo(endOfToday()),
      };
    case RangeType.lastN:
      return {
        from: processors.lastNFrom(startOfToday(), lastNCount),
        to: endOfToday(),
      };
    default:
      throw new Error(`Unexpected range: "${range}"`);
  }
}

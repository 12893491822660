import { Box, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import type { DataType, FormatType } from '@/shared/lib';
import { formattersMap } from '@/shared/lib';
import { TypedNumberInput } from '@/shared/ui';
import type { ColorLevel } from '../../model/types';
import { ColorPicker } from '../ColorPicker/ColorPicker';

interface Props {
  type: DataType;
  formatType: FormatType;
  previousLevelValue: ColorLevel['value'];
  disabled?: boolean;
  value: ColorLevel;
  onChange: (v: ColorLevel) => void;
}

export function ColorLevelEditor({
  type,
  formatType,
  previousLevelValue,
  disabled,
  value,
  onChange,
}: Props) {
  const [internalValue, setInternalValue] = useState(value);

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  const handleValueChange = (newValue: number) => {
    setInternalValue({
      color: value.color,
      value: newValue,
    });
  };
  const handleColorChange = (newColor: string) => {
    onChange({
      ...internalValue,
      color: newColor,
    });
  };
  const handleSubmit = () => {
    onChange(internalValue);
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <ColorPicker value={value.color} onChange={handleColorChange} />
      <TextField
        disabled
        size="small"
        label="From"
        value={formattersMap[type][formatType](previousLevelValue)}
      />
      {disabled ? (
        <TextField
          disabled
          size="small"
          label="To"
          value={formattersMap[type][formatType](internalValue.value)}
        />
      ) : (
        <TypedNumberInput
          label="To"
          disabled={disabled}
          type={type}
          formatType={formatType}
          min={previousLevelValue}
          value={internalValue.value}
          onChange={handleValueChange}
          onBlur={handleSubmit}
        />
      )}
    </Box>
  );
}

import type { OutlinedInputProps } from '@mui/material';
import {
  InputAdornment,
  OutlinedInput as MUIOutlinedInput,
  outlinedInputClasses,
  styled,
} from '@mui/material';
import type { ChangeEvent } from 'react';
import { useState } from 'react';
import { SearchIcon } from '@/shared/ui';

type InputProps = OutlinedInputProps & { active: boolean };

const OutlinedInput = styled(({ active, ...props }: InputProps) => {
  return <MUIOutlinedInput {...props} />;
})(({ active }) => ({
  width: 500,
  paddingLeft: 12,
  transition: 'width 0.25s',

  ...(!active && {
    width: 40,
    paddingLeft: 8,

    [`& .${outlinedInputClasses.notchedOutline}`]: {
      borderColor: 'transparent',
    },
  }),
}));

interface Props {
  value: string;
  onChange: (v: string) => void;
}

export function SearchInput({ value, onChange }: Props) {
  const [active, setActive] = useState(!!value);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };
  const activateElement = () => {
    setActive(true);
  };
  const tryDeactivateElement = () => {
    setActive(!!value);
  };

  return (
    <OutlinedInput
      active={active}
      type="search"
      size="small"
      startAdornment={
        <InputAdornment disablePointerEvents position="start">
          <SearchIcon />
        </InputAdornment>
      }
      value={value}
      onChange={handleChange}
      onFocus={activateElement}
      onBlur={tryDeactivateElement}
    />
  );
}

import { createSelector, nanoid } from '@reduxjs/toolkit';
import isEqual from 'lodash.isequal';
import type {
  Filters,
  MediaFilterOption,
  NumberFilterParams,
  StringFilterParams,
} from '@/features/filters';
import { getApplicableFilters, prepareFilters } from '@/features/filters';
import { DimensionColumnDataType } from '@/entities/columnsConfig';
import { prepareDateRangeValueObject } from '@/shared/api';
import { DataStatus, isFinished, isLoading } from '@/shared/lib';
import type { AppThunk } from '@/shared/model';
import { createAppSlice } from '@/shared/model';
import type {
  SerializedDateRangeValueObject,
  DateScale,
  Lifetime,
} from '@/shared/types';
import * as ChartsViewAPI from '../api/chartsViewAPI';
import type { DataResponse } from '../api/types';
import { getDataRequestedConfig } from './getDataRequestedConfig';
import type {
  CalendarChartState,
  Chart,
  ChartData,
  ChartMeta,
  CohortChartState,
  MetaRegistry,
} from './types';
import { ChartType } from './types';
import {
  getCalendarChartDefaultProps,
  getChartMeta,
  getCohortChartDefaultProps,
} from './utils';

interface State {
  meta:
    | {
        data: MetaRegistry;
        status: DataStatus.finished;
      }
    | {
        data: null;
        status: Exclude<DataStatus, DataStatus.finished>;
      };
  chart: Chart | null;
  chartsData: Record<string, ChartData>;
}

const initialState: State = {
  meta: {
    data: null,
    status: DataStatus.idle,
  },
  chart: null,
  chartsData: {},
};

export const chartsViewSlice = createAppSlice({
  name: 'chartsView',
  initialState,
  reducers: (create) => ({
    initMeta: create.asyncThunk(
      (_: void) => {
        return ChartsViewAPI.getMeta();
      },
      {
        pending: (state) => {
          state.meta.status = DataStatus.loading;
        },
        fulfilled: (state, action) => {
          state.meta.data = action.payload;
          state.meta.status = DataStatus.finished;
        },
        rejected: (state) => {
          state.meta.status = DataStatus.error;
        },
        options: {
          condition: (_, { getState }) => {
            const state = getState() as RootState;

            if (
              isLoading(state.chartsView.meta.status) ||
              isFinished(state.chartsView.meta.status)
            ) {
              return false;
            }
          },
        },
      },
    ),
    updateChartType: create.reducer<ChartType>((state, action) => {
      if (action.payload === ChartType.calendar) {
        state.chart = {
          id: nanoid(),
          ...getCalendarChartDefaultProps(
            state.meta.data?.calendarInsightsMeta.defaultAxis!,
          ),
        };
      } else if (action.payload === ChartType.cohort) {
        state.chart = {
          id: nanoid(),
          ...getCohortChartDefaultProps(
            state.meta.data?.cohortMarketingPerformanceMeta.defaultAxis!,
          ),
        };
      }
    }),
    updateCohortChartDateScale: create.reducer<{
      id: string;
      dateScale: DateScale;
    }>((state, action) => {
      const chart = state.chart as CohortChartState;

      chart.dateScale = action.payload.dateScale;
    }),
    updateCohortChartDateRange: create.reducer<{
      id: string;
      dateRange: SerializedDateRangeValueObject;
    }>((state, action) => {
      const chart = state.chart as CohortChartState;

      chart.dateRange = action.payload.dateRange;
    }),
    updateCalendarChartCohortDateScale: create.reducer<{
      id: string;
      dateScale: DateScale;
    }>((state, action) => {
      const chart = state.chart as CalendarChartState;

      chart.cohortDateScale = action.payload.dateScale;
    }),
    updateCalendarChartCohortDateRange: create.reducer<{
      id: string;
      dateRange: SerializedDateRangeValueObject;
    }>((state, action) => {
      const chart = state.chart as CalendarChartState;

      chart.cohortDateRange = action.payload.dateRange;
    }),
    updateCalendarChartRevenueDateScale: create.reducer<{
      id: string;
      dateScale: DateScale;
    }>((state, action) => {
      const chart = state.chart as CalendarChartState;

      chart.revenueDateScale = action.payload.dateScale;
    }),
    updateCalendarChartRevenueDateRange: create.reducer<{
      id: string;
      dateRange: SerializedDateRangeValueObject;
    }>((state, action) => {
      const chart = state.chart as CalendarChartState;

      chart.revenueDateRange = action.payload.dateRange;
    }),
    updateCohortChartLifetime: create.reducer<{
      id: string;
      lifetime: Lifetime;
    }>((state, action) => {
      const chart = state.chart as CohortChartState;

      chart.lifetime = action.payload.lifetime;
    }),
    updateChartProbabilisticAttribution: create.reducer<{
      id: string;
      probabilisticAttribution: boolean;
    }>((state, action) => {
      const chart = state.chart as Chart;

      chart.probabilisticAttribution = action.payload.probabilisticAttribution;
    }),
    updateChartSyncedYAxes: create.reducer<boolean>((state, action) => {
      const chart = state.chart as Chart;

      chart.syncedYAxes = action.payload;
    }),
    updateChartFilters: create.reducer<{ id: string; filters: Filters }>(
      (state, action) => {
        const chart = state.chart as Chart;

        chart.filters = action.payload.filters;
      },
    ),
    resetChartFilters: create.reducer((state) => {
      const chart = state.chart as Chart;

      chart.filters = [];
    }),
    toggleExcludedSplitOption: create.reducer<string>((state, action) => {
      if (!state.chart) {
        return;
      }

      const optionIndex =
        state.chart?.excludedSplitOptions.indexOf(action.payload) ?? -1;

      if (optionIndex !== -1) {
        state.chart.excludedSplitOptions =
          state.chart.excludedSplitOptions.toSpliced(optionIndex, 1);
      } else {
        state.chart.excludedSplitOptions.push(action.payload);
      }
    }),
    updateChartPrimaryMetric: create.reducer<{ id: string; value: string }>(
      (state, action) => {
        const chart = state.chart as Chart;

        chart.primaryMetric = action.payload.value;
      },
    ),
    updateChartSecondaryMetric: create.reducer<{ id: string; value: string }>(
      (state, action) => {
        const chart = state.chart as Chart;

        chart.secondaryMetric = action.payload.value;
      },
    ),
    updateChartSplitDimension: create.reducer<{ id: string; value: string }>(
      (state, action) => {
        const chart = state.chart as Chart;

        chart.splitDimension = action.payload.value;
      },
    ),
    getChartData: create.asyncThunk(
      (chart: Chart, { getState }): Promise<DataResponse> => {
        const state = getState() as RootState;

        if (chart.type === ChartType.cohort) {
          const meta = selectCohortMeta(state);
          const { dimensionsFilters, metricsFilters } = prepareFilters(
            chart.filters,
            meta?.metrics!,
            meta?.dimensions!,
          );

          return ChartsViewAPI.getCohortData({
            split: chart.splitDimension,
            metrics: [chart.primaryMetric, chart.secondaryMetric].filter(
              Boolean,
            ),
            dateScale: chart.dateScale,
            dateRange: prepareDateRangeValueObject(chart.dateRange),
            probabilisticAttribution: chart.probabilisticAttribution,
            lifetime: chart.lifetime,
            dimensionsFilters,
            metricsFilters,
          });
        } else if (chart.type === ChartType.calendar) {
          const meta = selectCalendarMeta(state);
          const { dimensionsFilters, metricsFilters } = prepareFilters(
            chart.filters,
            meta?.metrics!,
            meta?.dimensions!,
          );

          return ChartsViewAPI.getCalendarData({
            split: chart.splitDimension,
            metrics: [chart.primaryMetric, chart.secondaryMetric].filter(
              Boolean,
            ),
            cohortDateScale: chart.cohortDateScale,
            cohortDateRange: prepareDateRangeValueObject(chart.cohortDateRange),
            revenueDateScale: chart.revenueDateScale,
            revenueDateRange: prepareDateRangeValueObject(
              chart.revenueDateRange,
            ),
            probabilisticAttribution: chart.probabilisticAttribution,
            dimensionsFilters,
            metricsFilters,
          });
        } else {
          throw new Error('Unexpected chart type. ', chart);
        }
      },
      {
        pending: (state, action) => {
          state.chartsData[action.meta.arg.id] = {
            status: DataStatus.loading,
            requestedConfig: getDataRequestedConfig(action.meta.arg),
            data: {
              data: [],
              splitOptions: {},
            },
          };
        },
        fulfilled: (state, action) => {
          if (
            !isEqual(
              state.chartsData[action.meta.arg.id].requestedConfig,
              getDataRequestedConfig(action.meta.arg),
            )
          ) {
            return;
          }

          state.chartsData[action.meta.arg.id].status = DataStatus.finished;
          state.chartsData[action.meta.arg.id].data = action.payload;
        },
        rejected: (state, action) => {
          if (
            !isEqual(
              state.chartsData[action.meta.arg.id].requestedConfig,
              getDataRequestedConfig(action.meta.arg),
            )
          ) {
            return;
          }

          state.chartsData[action.meta.arg.id].status = DataStatus.error;
        },
      },
    ),
    setupDefaultExcludedOptions: create.reducer((state) => {
      if (state.chart && state.meta.data) {
        const { splitDimension } = state.chart;
        const optionsToExclude =
          state.chartsData[state.chart.id].data.splitOptions[
            splitDimension
          ].slice(3);
        const meta: ChartMeta = getChartMeta(state.meta.data, state.chart.type);
        const isMediaContent =
          meta.config[splitDimension].type === DimensionColumnDataType.MEDIA;

        state.chart.excludedSplitOptions = isMediaContent
          ? optionsToExclude.map((option) => {
              const parsedOption = JSON.parse(option) as MediaFilterOption;

              return parsedOption.value;
            })
          : optionsToExclude;
      }
    }),
    reset: create.reducer(() => {
      return { ...initialState };
    }),
    resetChartData: create.reducer((state) => {
      state.chartsData = {};
    }),
    updateChart: create.reducer<Chart>((state, action) => {
      state.chart = action.payload;
    }),
    resetChart: create.reducer((state) => {
      if (state.chart?.type === ChartType.calendar) {
        state.chart = {
          id: nanoid(),
          ...getCalendarChartDefaultProps(
            state.meta.data?.calendarInsightsMeta.defaultAxis!,
          ),
        };
      } else if (state.chart?.type === ChartType.cohort) {
        state.chart = {
          id: nanoid(),
          ...getCohortChartDefaultProps(
            state.meta.data?.cohortMarketingPerformanceMeta.defaultAxis!,
          ),
        };
      }
    }),
  }),
  selectors: {
    selectChartData: createSelector(
      (sliceState: State) => sliceState.chartsData,
      (_: State, id: string) => id,
      (chartsData, id) => {
        return chartsData[id];
      },
    ),
    selectChart: (state) => {
      return state.chart;
    },
    selectChartSyncedYAxes: (state) => {
      return !!state.chart?.syncedYAxes;
    },
    selectChartExcludedSplitOptions: (state) => {
      return state.chart?.excludedSplitOptions || [];
    },
    selectMetaRegistry: (state) => {
      return state.meta.data;
    },
    selectCohortMeta: (state) => {
      return state.meta.data?.cohortMarketingPerformanceMeta;
    },
    selectCalendarMeta: (state) => {
      return state.meta.data?.calendarInsightsMeta;
    },
    selectMetaIsLoading: (state) => {
      return state.meta.status === DataStatus.loading;
    },
    selectMetaIsLoaded: (state) => {
      return state.meta.status === DataStatus.finished;
    },
  },
});

interface GetCohortChartNumberFilterParams {
  filterName: string;
  selectedDimensions: string[];
  filters: Filters;
  allMetrics: string[];
  allDimensions: string[];
  dateRange: SerializedDateRangeValueObject;
  dateScale: DateScale;
  probabilisticAttribution: boolean;
  lifetime: number;
}

export const getCohortChartNumberFilterParams =
  (
    params: GetCohortChartNumberFilterParams,
  ): AppThunk<Promise<NumberFilterParams>> =>
  () => {
    const { dimensionsFilters, metricsFilters } = prepareFilters(
      getApplicableFilters(params.filters, params.filterName),
      params.allMetrics,
      params.allDimensions,
    );

    return ChartsViewAPI.getCohortChartNumberFilterParams({
      filterName: params.filterName,
      dimensionsFilters,
      metricsFilters,
      dateRange: prepareDateRangeValueObject(params.dateRange),
      dateScale: params.dateScale,
      selectedDimensions: params.selectedDimensions,
      probabilisticAttribution: params.probabilisticAttribution,
      lifetime: params.lifetime,
    });
  };

interface GetCohortChartStringFilterParams {
  filterName: string;
  filters: Filters;
  allMetrics: string[];
  allDimensions: string[];
  dateRange: SerializedDateRangeValueObject;
}

export const getCohortChartStringFilterParams =
  (
    params: GetCohortChartStringFilterParams,
  ): AppThunk<Promise<StringFilterParams>> =>
  () => {
    const { dimensionsFilters } = prepareFilters(
      getApplicableFilters(params.filters, params.filterName),
      params.allMetrics,
      params.allDimensions,
    );

    return ChartsViewAPI.getCohortChartStringFilterParams({
      filterName: params.filterName,
      dimensionsFilters,
      dateRange: prepareDateRangeValueObject(params.dateRange),
    });
  };

interface GetCalendarChartNumberFilterParams {
  filterName: string;
  selectedDimensions: string[];
  filters: Filters;
  allMetrics: string[];
  allDimensions: string[];
  cohortDateRange: SerializedDateRangeValueObject;
  cohortDateScale: DateScale;
  revenueDateRange: SerializedDateRangeValueObject;
  revenueDateScale: DateScale;
  probabilisticAttribution: boolean;
}

export const getCalendarChartNumberFilterParams =
  (
    params: GetCalendarChartNumberFilterParams,
  ): AppThunk<Promise<NumberFilterParams>> =>
  () => {
    const { dimensionsFilters, metricsFilters } = prepareFilters(
      getApplicableFilters(params.filters, params.filterName),
      params.allMetrics,
      params.allDimensions,
    );

    return ChartsViewAPI.getCalendarChartNumberFilterParams({
      filterName: params.filterName,
      dimensionsFilters,
      metricsFilters,
      cohortDateRange: prepareDateRangeValueObject(params.cohortDateRange),
      cohortDateScale: params.cohortDateScale,
      revenueDateRange: prepareDateRangeValueObject(params.revenueDateRange),
      revenueDateScale: params.revenueDateScale,
      selectedDimensions: params.selectedDimensions,
      probabilisticAttribution: params.probabilisticAttribution,
    });
  };

interface GetStringFilterParams {
  filterName: string;
  filters: Filters;
  allMetrics: string[];
  allDimensions: string[];
  cohortDateRange: SerializedDateRangeValueObject;
  revenueDateRange: SerializedDateRangeValueObject;
}

export const getCalendarChartStringFilterParams =
  (params: GetStringFilterParams): AppThunk<Promise<StringFilterParams>> =>
  () => {
    const { dimensionsFilters } = prepareFilters(
      getApplicableFilters(params.filters, params.filterName),
      params.allMetrics,
      params.allDimensions,
    );

    return ChartsViewAPI.getCalendarChartStringFilterParams({
      filterName: params.filterName,
      dimensionsFilters,
      cohortDateRange: prepareDateRangeValueObject(params.cohortDateRange),
      revenueDateRange: prepareDateRangeValueObject(params.revenueDateRange),
    });
  };
export const {
  initMeta,
  getChartData,
  reset,
  resetChartFilters,
  updateCalendarChartCohortDateScale,
  updateCalendarChartCohortDateRange,
  updateCalendarChartRevenueDateScale,
  updateCalendarChartRevenueDateRange,
  updateChartType,
  updateChartPrimaryMetric,
  updateChartSecondaryMetric,
  updateChartSplitDimension,
  updateCohortChartDateRange,
  updateCohortChartDateScale,
  updateCohortChartLifetime,
  updateChartFilters,
  updateChartProbabilisticAttribution,
  updateChartSyncedYAxes,
  toggleExcludedSplitOption,
  resetChartData,
  updateChart,
  resetChart,
  setupDefaultExcludedOptions,
} = chartsViewSlice.actions;
export const {
  selectChart,
  selectCalendarMeta,
  selectCohortMeta,
  selectChartData,
  selectMetaIsLoading,
  selectMetaIsLoaded,
  selectChartExcludedSplitOptions,
  selectChartSyncedYAxes,
  selectMetaRegistry,
} = chartsViewSlice.selectors;

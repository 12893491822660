import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { createTheme } from '@mui/material';
import { grey } from '@mui/material/colors';

export const theme = createTheme({
  typography: {
    h1: {
      fontSize: '36px',
      fontWeight: 300,
      lineHeight: '44px',
    },
    h4: {
      fontWeight: 500,
      fontSize: '24px',
      lineHeight: '32px',
    },
    h5: {
      fontSize: '20px',
      fontWeight: 500,
      lineHeight: '28px',
      letterSpacing: 0,
      textAlign: 'left',
    },
    h6: {
      fontSize: '1.125rem',
      lineHeight: 1.44,
      fontWeight: 500,
    },
    label: {
      fontWeight: 500,
      fontSize: '12px',
      lineHeight: '20px',
    },
    modalContent: {
      fontSize: '14px',
      lineHeight: '22px',
      color: grey[700],
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          modalContent: 'p',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: ({ theme }) => ({
          fontSize: '14px',
          lineHeight: '22px',

          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderWidth: 1,
            borderColor: theme.palette.primary.light,
          },
        }),
        select: ({ theme }) => ({
          padding: `${theme.spacing(1.125)} ${theme.spacing(4)} ${theme.spacing(1.125)} ${theme.spacing(1.5)}`,
        }),
      },
      defaultProps: {
        size: 'small',
        IconComponent: KeyboardArrowDownRoundedIcon,
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.text.secondary,
        }),
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: `${theme.spacing(1.25)} ${theme.spacing(2)}`,
          fontSize: '14px',
          lineHeight: '22px',
          color: 'var(--neutral-700)',

          '&.Mui-selected': {
            color: 'inherit',
            fontWeight: 500,
          },

          '&:hover, &.Mui-focusVisible': {
            color: 'inherit',
            backgroundColor: 'var(--accent-100)',
          },
        }),
      },
    },
  },
  palette: {
    primary: {
      light: '#717fff',
      main: '#063FFE',
      dark: '#0029e5',
      darker: '#0000cc',
    },
    secondary: {
      main: '#666666',
      dark: '#f5f5f5',
    },
    text: {
      primary: '#444444',
      secondary: '#817E7E',
    },
    error: {
      main: '#DA1414',
    },
  },
});

import { LabeledSwitcher } from '@/shared/ui';

interface Props {
  value: boolean;
  onChange: (newValue: boolean) => void;
}

export function ProbabilisticAttributionSwitcher({ value, onChange }: Props) {
  return (
    <LabeledSwitcher
      label="Probabilistic Attribution"
      value={value}
      onChange={onChange}
    />
  );
}

import type { TypographyProps } from '@mui/material';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { EmailInput, Page, Title } from '@/features/auth';
import { requestResetPassword } from '@/entities/session';
import { ROUTES } from '@/shared/constants';
import { Button, ErrorAlert, Loader } from '@/shared/ui';

const Form = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));
const NavigationText = styled('p')(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: 0,
  textAlign: 'center',
  color: theme.palette.text.secondary,
  fontSize: '14px',
}));
const Link = styled(RouterLink)(({ theme }) => ({
  fontWeight: 500,
  color: theme.palette.primary.main,
  textDecoration: 'none',
}));
const EmailSentText = styled((props: TypographyProps) => {
  return <Typography variant="body2" color="text.secondary" {...props} />;
})(({ theme }) => ({
  margin: `${theme.spacing(4)} 0`,
}));

export function PasswordResetPage() {
  const [recipient, setRecipient] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formElement = event.currentTarget;

    if (!formElement.checkValidity()) {
      return;
    }

    const formData = new FormData(formElement);
    const email = formData.get('email') as string;

    setErrorMessage('');
    setLoading(true);
    requestResetPassword(email)
      .then(() => {
        setRecipient(email);
      })
      .catch((error: Error) => {
        setErrorMessage(
          error.message || 'Something went wrong. Please, try again',
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleErrorClose = () => {
    setErrorMessage('');
  };

  let content = recipient ? (
    <div>
      <EmailSentText>
        Email successfully sent to <b>{recipient}</b>
      </EmailSentText>
      <Button fullWidth component={RouterLink} to={ROUTES.signin}>
        Go to Sign In
      </Button>
    </div>
  ) : (
    <Form onSubmit={handleSubmit}>
      <EmailInput />
      <Button sx={{ mt: 1 }} type="submit">
        Reset
      </Button>
      <NavigationText>
        Recall your password <Link to={ROUTES.signin}>Sign In</Link>
      </NavigationText>
    </Form>
  );

  return (
    <Page>
      <Title>Reset password</Title>
      <ErrorAlert onClose={handleErrorClose}>{errorMessage}</ErrorAlert>
      {content}
      <Loader active={loading} />
    </Page>
  );
}

import { useEffect, useState } from 'react';
import { Direction } from './types';

interface Params {
  snapshot: unknown;
  onTravel: (snapshot: unknown) => void;
}

interface TimeMachineAPI {
  hasNext: boolean;
  hasPrevious: boolean;
  travel: (direction: Direction) => void;
}

export function useTimeMachine({ snapshot, onTravel }: Params): TimeMachineAPI {
  const [history, setHistory] = useState<unknown[]>([]);
  const [historyIndex, setHistoryIndex] = useState(-1);

  useEffect(() => {
    if (JSON.stringify(history[historyIndex]) === JSON.stringify(snapshot)) {
      return;
    }

    setHistory([...history.slice(0, historyIndex + 1), snapshot]);
    setHistoryIndex(historyIndex + 1);
  }, [snapshot, history, historyIndex]);

  return {
    hasNext: historyIndex < history.length - 1,
    hasPrevious: historyIndex > 0,
    travel: (direction) => {
      const step = getStep(direction);
      const newHistoryIndex = Math.min(
        Math.max(historyIndex + step, 0),
        history.length - 1,
      );

      if (newHistoryIndex === historyIndex) {
        return;
      }

      onTravel(history[newHistoryIndex]);
      setHistoryIndex(newHistoryIndex);
    },
  };
}

const getStep = (direction: Direction): number => {
  if (direction === Direction.next) {
    return 1;
  } else {
    return -1;
  }
};

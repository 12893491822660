import { IconButton as MUIIconButton, SvgIcon } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Analytics } from '@/shared/lib';
import RefreshIcon from './refresh.svg?react';

const IconButton = styled(MUIIconButton)(({ theme }) => ({
  color: 'currentColor',
  borderRadius: theme.shape.borderRadius,

  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },

  '&:disabled': {
    color: 'white',
    opacity: 0.3,
  },
}));

interface Props {
  disabled?: boolean;
  onRefresh: () => void;
}

export function RefreshData({ disabled, onRefresh }: Props) {
  const handleRefresh = () => {
    onRefresh();
    Analytics.sendFeatureUsage('refresh_view_data', 'refresh', {
      page: window.location.pathname,
    });
  };

  return (
    <IconButton disabled={disabled} onClick={handleRefresh}>
      <SvgIcon>
        <RefreshIcon />
      </SvgIcon>
    </IconButton>
  );
}

import { useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import {
  selectIsAuthorized,
  selectIsSessionRequested,
  setupUser,
} from '@/entities/session';
import { ROUTES } from '@/shared/constants';
import { useAppDispatch, useAppSelector } from '@/shared/model';

export function GuestGuard() {
  const dispath = useAppDispatch();
  const isSessionRequested = useAppSelector(selectIsSessionRequested);
  const isAuthorized = useAppSelector(selectIsAuthorized);

  useEffect(() => {
    if (isSessionRequested) {
      return;
    }

    dispath(setupUser());
  }, [isSessionRequested, dispath]);

  if (isAuthorized) {
    return <Navigate to={ROUTES.home} />;
  }

  return <Outlet />;
}

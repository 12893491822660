export enum FormatType {
  INTEGER = 'integer',
  FLOAT = 'float',
}

export enum DataType {
  NUMBER = 'number',
  PERCENTAGE = 'percentage',
  CURRENCY = 'currency',
}

const floatCurrencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});
const integerCurrencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0,
});
const floatPercentFormatter = new Intl.NumberFormat('en-US', {
  style: 'percent',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});
const integerPercentFormatter = new Intl.NumberFormat('en-US', {
  style: 'percent',
  maximumFractionDigits: 0,
});
const floatNumberFormatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});
const integerNumberFormatter = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 0,
});

export const formattersMap: Record<
  DataType,
  Record<FormatType, (v: number) => string>
> = {
  [DataType.CURRENCY]: {
    [FormatType.INTEGER]: integerCurrencyFormatter.format,
    [FormatType.FLOAT]: floatCurrencyFormatter.format,
  },
  [DataType.PERCENTAGE]: {
    [FormatType.INTEGER]: integerPercentFormatter.format,
    [FormatType.FLOAT]: floatPercentFormatter.format,
  },
  [DataType.NUMBER]: {
    [FormatType.INTEGER]: integerNumberFormatter.format,
    [FormatType.FLOAT]: floatNumberFormatter.format,
  },
} as const;

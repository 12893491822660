import type { SwitchProps } from '@mui/material';
import { Switch as MUISwitch } from '@mui/material';
import { styled } from '@mui/material/styles';

type Props = SwitchProps & { customColor?: string };

export const Switch = styled((props: Props) => {
  const { customColor, ...otherProps } = props;

  return (
    <MUISwitch
      focusVisibleClassName="Mui-focusVisible"
      disableRipple
      {...otherProps}
    />
  );
})(({ theme, customColor }) => ({
  width: 28,
  height: 16,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        border: 0,
        ...(customColor && { backgroundColor: customColor }),
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      border: '2px solid #fff',
      borderRadius: 2,
      transition: theme.transitions.create(['color'], {
        duration: 500,
      }),
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 12,
    height: 12,
    borderRadius: 2,
    boxShadow: 'none',
  },
  '& .MuiSwitch-track': {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: 'var(--neutral-500)',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

import { Checkbox as MUICheckbox, FormControlLabel } from '@mui/material';
import { styled } from '@mui/material/styles';

const ControlLabel = styled(FormControlLabel)(({ theme }) => ({
  color: theme.palette.text.secondary,
  marginLeft: '-2px',

  '& .MuiFormControlLabel-label': {
    fontSize: '14px',
    lineHeight: '22px',
    marginLeft: theme.spacing(0.75),
  },
}));
const Checkbox = styled(MUICheckbox)(() => ({
  padding: 0,
}));

export function RememberLogin() {
  return (
    <ControlLabel
      control={<Checkbox name="rememberLogin" size="small" />}
      label="Remember my login on this computer"
    />
  );
}
